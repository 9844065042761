import React, { useRef } from "react";
import { SnackbarProvider } from "notistack";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IconButton } from "@mui/material";

export default function StyledNotistack({ children }) {
  const notistackRef = useRef(null);
  const dismiss = (key) => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      ref={notistackRef}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      action={(key) => (
        <IconButton size="small" onClick={() => dismiss(key)}>
          <CloseOutlinedIcon sx={{ color: "white" }} />
        </IconButton>
      )}
      style={{ fontFamily: "Kanit" }}
    >
      {children}
    </SnackbarProvider>
  );
}
