import { createSlice } from "@reduxjs/toolkit";

const navisionSlice = createSlice({
  name: "navision",
  initialState: {
    isLoading: {
      allNavisions: false,
      sales: false,
      customer: {
        allCustomer: false,
        selectedCustomer: false,
      },
      ar: false,
      ap: false,
    },
    allNavisions: [],
    sales: [],
    dateFilterType: "day",
    customer: {
      allCustomer: [],
      selectedCustomer: {},
    },
    ar: [],
    ap: [],
    error: null,
  },
  reducers: {
    onLoading(state, action) {
      if (action.payload.length === 1)
        state.isLoading[action.payload[0]] = true;
      if (action.payload.length === 2)
        state.isLoading[action.payload[0]][action.payload[1]] = true;
    },
    loadedAllNavision(state, action) {
      const getDateFromState = (input, type) => {
        const findInput = action.payload.find((navision) => {
          return navision.report_type === input;
        });
        if (type === "updated_at") return findInput.updated_at.substring(0, 10);
        else if (type === "data_date")
          return findInput.data_date.substring(0, 10);
      };
      state.allNavisions = [
        {
          navision_name: "รายงานยอดขายตามผลิตภัณฑ์",
          navision_date: getDateFromState("Sales", "data_date"),
          navision_last_updated: getDateFromState("Sales", "updated_at"),
        },
        {
          navision_name: "รายงานยอดขายตามผู้แทนขาย",
          navision_date: getDateFromState("Sales", "data_date"),
          navision_last_updated: getDateFromState("Sales", "updated_at"),
        },
        {
          navision_name: "รายงานยอดขายตามภาค",
          navision_date: getDateFromState("Sales", "data_date"),
          navision_last_updated: getDateFromState("Sales", "updated_at"),
        },
        {
          navision_name: "รายงานลูกค้า",
          navision_date: null,
          navision_last_updated: null,
        },
        {
          navision_name: "ยอด AR และ Overdue ลูกค้า",
          navision_date: getDateFromState("AR", "data_date"),
          navision_last_updated: getDateFromState("AR", "updated_at"),
        },
        {
          navision_name: "ยอด AP และ Overdue Supplier",
          navision_date: getDateFromState("AP", "data_date"),
          navision_last_updated: getDateFromState("AP", "updated_at"),
        },
      ];
      state.isLoading.allNavisions = false;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedCustomers(state, action) {
      state.customer.allCustomer = action.payload;
      state.isLoading.customer.allCustomer = false;
    },
    loadedCustomerById(state, action) {
      state.customer.selectedCustomer = action.payload;
      state.isLoading.customer.selectedCustomer = false;
    },
    loadedSales(state, action) {
      let allPerson = [];
      let allDate = [];
      let allRegion = [];
      action.payload.sales.map((item) => {
        if (!allDate.includes(item.data_date)) allDate.push(item.data_date);
        if (!allPerson.includes(item.salesperson))
          allPerson.push(item.salesperson);
        if (!allRegion.includes(item.region)) allRegion.push(item.region);
        return null;
      });
      let newPersonValue = allPerson.map((person) => {
        const findPerson = action.payload.sales.filter(
          (item) => item.salesperson === person
        );
        return findPerson;
      });
      const result = [];
      newPersonValue.forEach((newPerson) => {
        const newData = allDate.map((date) => {
          const findDate = newPerson.filter((item) => item.data_date === date);
          return findDate;
        });
        newData.forEach((data) => {
          const newValue = allRegion.map((region) => {
            const findRegion = data.filter((item) => item.region === region);
            return findRegion;
          });
          newValue.forEach((value) => {
            if (value.length !== 0)
              result.push({
                region: value[0].region,
                salesperson: value[0].salesperson,
                product: "Total",
                data_date: value[0].data_date,
                qty_net: value.reduce(
                  (prev, curr) => prev + parseFloat(curr.qty_net),
                  0
                ),
              });
          });
        });
      });
      if (action.payload.type === "month") {
        const newValue = action.payload.sales.map((data) => {
          return {
            ...data,
            qty_shipped: parseFloat(data.qty_shipped) || null,
            qty_net: parseFloat(data.qty_net) || null,
            data_date: data.data_date.substring(0, 7),
            data_dateTime: data.data_date.substring(0, 10),
          };
        });
        result.forEach((data) => {
          return newValue.push({
            ...data,
            data_date: data.data_date.substring(0, 7),
          });
        });
        state.sales = newValue;
      } else if (action.payload.type === "year") {
        const newValue = action.payload.sales.map((data) => {
          return {
            ...data,
            qty_shipped: parseFloat(data.qty_shipped) || null,
            qty_net: parseFloat(data.qty_net) || null,
            data_date: data.data_date.substring(0, 4),
            data_dateTime: data.data_date.substring(0, 10),
          };
        });
        result.forEach((data) => {
          return newValue.push({
            ...data,
            data_date: data.data_date.substring(0, 4),
          });
        });
        state.sales = newValue;
      } else {
        const newValue = action.payload.sales.map((data) => {
          return {
            ...data,
            qty_shipped: parseFloat(data.qty_shipped) || null,
            qty_net: parseFloat(data.qty_net) || null,
            data_date: data.data_date.substring(0, 10),
            data_dateTime: data.data_date.substring(0, 10),
          };
        });
        result.forEach((data) => {
          return newValue.push({
            ...data,
            data_date: data.data_date.substring(0, 10),
          });
        });
        state.sales = newValue;
      }
      state.isLoading.sales = false;
    },
    loadedAllAR(state, action) {
      state.ar = action.payload.map((data) => {
        return {
          ...data,
          balance: parseFloat(data.balance) || 0,
          not_due: parseFloat(data.not_due) || 0,
          overdue_1_30_days: parseFloat(data.overdue_1_30_days) || 0,
          overdue_31_60_days: parseFloat(data.overdue_31_60_days) || 0,
          overdue_61_90_days: parseFloat(data.overdue_61_90_days) || 0,
          overdue_91_180_days: parseFloat(data.overdue_91_180_days) || 0,
          overdue_181_365_days: parseFloat(data.overdue_181_365_days) || 0,
          overdue_365_days: parseFloat(data.overdue_365_days) || 0,
          data_date: data.data_date.substring(0, 10),
        };
      });
      state.isLoading.ar = false;
    },
    loadedAllAP(state, action) {
      state.ap = action.payload.map((data) => {
        return {
          ...data,
          balance: parseFloat(data.balance) || 0,
          not_due: parseFloat(data.not_due) || 0,
          overdue_1_30_days: parseFloat(data.overdue_1_30_days) || 0,
          overdue_31_60_days: parseFloat(data.overdue_31_60_days) || 0,
          overdue_61_90_days: parseFloat(data.overdue_61_90_days) || 0,
          overdue_91_180_days: parseFloat(data.overdue_91_180_days) || 0,
          overdue_181_365_days: parseFloat(data.overdue_181_365_days) || 0,
          overdue_365_days: parseFloat(data.overdue_365_days) || 0,
          data_date: data.data_date.substring(0, 10),
        };
      });
      state.isLoading.ap = false;
    },
    setDateFilterType(state, action) {
      state.dateFilterType = action.payload;
    },
    resetDateFilterType(state, action) {
      state.dateFilterType = "day";
    },
    resetSelectedCustomer(state) {
      state.customer.selectedCustomer = {};
      state.error = null;
    },
    resetSales(state) {
      state.sales = [];
      state.error = null;
    },
    resetCustomers(state) {
      state.customer.allCustomer = [];
      state.error = null;
    },
    resetAR(state) {
      state.ar = [];
      state.error = null;
    },
    resetAP(state) {
      state.ar = [];
      state.error = null;
    },
  },
});

export const navisionActions = navisionSlice.actions;

export default navisionSlice.reducer;
