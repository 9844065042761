import { createSlice } from "@reduxjs/toolkit";
import { mapAgGridType } from "../../utils/data-transformer";
import { formatDate } from "../../utils/date-converter";
export const REDIRECT = "REDIRECT";

const templateSlice = createSlice({
  name: "template",
  initialState: {
    isLoading: {
      templates: false,
      creatingTemplate: false,
      creatingTemplateData: false,
      selectedTemplate: false,
    },
    templates: [],
    selectedTemplate: {
      name: "",
      x_axis_type: "",
      x_axis: "",
      y_axis_type: "",
      y_axis: "",
      chart_type: "stackedColumn",
      columns: [],
      rows: [],
      id: null,
      has_avg_footer: false,
      has_sum_footer: false,
      role_permission_list: [],
      min_datum: null,
      max_datum: null,
      created_by: null,
      last_updated_time: null,
    },
    sumSelectedTemplateData: [],
    form: {
      name: "",
      x_axis: "",
      x_axis_type: "",
      y_axis: "",
      y_axis_type: "",
      chart_type: "stackedColumn",
      columns: [],
      has_avg_footer: false,
      has_sum_footer: false,
    },
    error: null,
  },
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    successActions(state, action) {
      state.isLoading[action.payload] = false;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAlltemplate(state, action) {
      state.templates = action.payload;
      state.isLoading.templates = false;
    },
    loadedTemplateById(state, action) {
      state.selectedTemplate.chart_type = action.payload.chart_type;
      state.selectedTemplate.id = action.payload.id;
      state.selectedTemplate.name = action.payload.name;
      state.selectedTemplate.x_axis_type = action.payload.x_axis_type;
      state.selectedTemplate.x_axis = action.payload.x_axis;
      state.selectedTemplate.y_axis_type = action.payload.y_axis_type;
      state.selectedTemplate.y_axis = action.payload.y_axis;
      state.selectedTemplate.has_avg_footer = action.payload.has_avg_footer;
      state.selectedTemplate.has_sum_footer = action.payload.has_sum_footer;
      state.selectedTemplate.created_by = action.payload.created_by;
      state.selectedTemplate.min_datum = action.payload.min_datum;
      state.selectedTemplate.max_datum = action.payload.max_datum;
      state.selectedTemplate.last_updated_time = formatDate(
        action.payload.last_updated_time
      );
      state.selectedTemplate.role_permission_list =
        action.payload.role_permission_list;
    },
    loadedTemplateToForm(state, action) {
      state.form.id = action.payload.id;
      state.form.name = action.payload.name;
      state.form.x_axis_type = action.payload.x_axis_type;
      state.form.x_axis = action.payload.x_axis;
      state.form.y_axis_type = action.payload.y_axis_type;
      state.form.y_axis = action.payload.y_axis;
      state.form.chart_type = action.payload.chart_type;
      state.form.has_avg_footer = action.payload.has_avg_footer;
      state.form.has_sum_footer = action.payload.has_sum_footer;
      state.form.columns = action.payload.columns;
    },
    loadedTemplateDataId(state, action) {
      state.selectedTemplate.dataId = action.payload;
    },
    removeSelectedTemplete(state) {
      state.selectedTemplate = {
        name: "",
        x_axis: "",
        y_axis: "",
        columns: [],
        rows: [],
        id: "",
        dataId: "",
        role_permission_list: [],
        created_by: null,
      };
      state.form = {
        name: "",
        x_axis: "",
        x_axis_type: "",
        y_axis: "",
        y_axis_type: "",
        chart_type: "stackedColumn",
        columns: [],
        has_avg_footer: false,
        has_sum_footer: false,
      };
    },
    removeSumSelectedTemplete(state) {
      state.sumSelectedTemplateData = [];
    },
    loadColumnsToTemplate(state, action) {
      state.selectedTemplate.columns = action.payload.columns.map((column) => {
        return {
          field: column.name,
          headerName: column.name,
          type: mapAgGridType(column.data_type),
          can_override_value: column.can_override_value,
        };
      });
      state.isLoading.selectedTemplate = false;
    },
    loadRowsToTemplate(state, action) {
      state.selectedTemplate.rows = action.payload;
      state.isLoading.selectedTemplate = false;
    },
    loadSumSelectedData(state, action) {
      let sumData = {};
      action.payload.rows.forEach((item) => {
        for (const [key, value] of Object.entries(item)) {
          if (typeof value === "number") {
            if (sumData.hasOwnProperty(key))
              sumData[key] += parseFloat(value) || 0;
            else sumData[key] = parseFloat(value) || 0;
          }
        }
      });
      let result = [];
      if (action.payload.average) {
        const average = {};
        for (const [key, value] of Object.entries(sumData)) {
          let count = 0;
          action.payload.rows.forEach((row) => {
            if (!isNaN(row[key])) {
              return (count += 1);
            }
          });
          Object.assign(average, {
            [key.replaceAll(/\./g, "")]: (value / count).toLocaleString(
              undefined,
              {
                maximumFractionDigits: 2,
              }
            ),
          });
        }
        result.push({
          ...average,
          [state.selectedTemplate && state.selectedTemplate.columns[0]?.field]:
            "ค่าเฉลี่ย",
        });
      }
      if (action.payload.sum) {
        const sum = {};
        for (const [key, value] of Object.entries(sumData)) {
          Object.assign(sum, {
            [key.replaceAll(/\./g, "")]: value.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }),
          });
        }
        result.push({
          ...sum,
          [state.selectedTemplate && state.selectedTemplate.columns[0]?.field]:
            "รวม",
        });
      }

      state.sumSelectedTemplateData = result;
    },
    removeRowsfromTemplate(state, action) {
      state.selectedTemplate.rows = state.selectedTemplate.rows.filter(
        (row) => parseInt(row.id) !== parseInt(action.payload)
      );
    },
    resetFormState(state) {
      state.form = {
        name: "",
        x_axis: "",
        x_axis_type: "",
        y_axis: "",
        y_axis_type: "",
        chart_type: "stackedColumn",
        columns: [],
        has_avg_footer: false,
        has_sum_footer: false,
      };
    },
    redirectAction(state = {}, action) {
      switch (action.type) {
        case REDIRECT:
          return { redirectTo: action.payload };
        default:
          return state;
      }
    },
  },
});

export const templateActions = templateSlice.actions;

export default templateSlice.reducer;
