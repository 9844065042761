import { Grid, Typography } from "@mui/material";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import StyledButton from "../Styled/StyledButton";
import StyledModal from "../Styled/StyledModal";
import StyledSelect from "../Styled/StyledSelect";
import StyledTextField from "../Styled/StyledTextField";
import { useDispatch, useSelector } from "react-redux";
import { monitorActions } from "../../features/Monitor/monitor-slice";
import {
  getAllJobConfigs,
  getJobConfigById,
  postTriggerJob,
} from "../../features/Monitor/monitor-actions";
import { v4 as uuidv4 } from "uuid";

const schema = Yup.object().shape({
  job_id: Yup.string().required("กรุณาเลือก"),
  data_date: Yup.string().required("กรุณากรอก"),
});

const TriggerModal = ({ open, closeHandler, api }) => {
  const dispatch = useDispatch();
  const { jobConfigs, jobConfig, isLoading } = useSelector(
    (state) => state.monitor
  );
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      job_id: "",
      data_date: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(getAllJobConfigs());
    return () => dispatch(monitorActions.resetJobConfigs());
  }, [dispatch]);

  const jobOptions = jobConfigs.map((job) => ({
    id: uuidv4(),
    label: job.name,
    value: job.id,
  }));

  const onJobChange = (event) => {
    setValue("job_id", event.target.value);
    dispatch(getJobConfigById(parseInt(event.target.value)));
  };

  const onTriggerSubmit = (data) => {
    dispatch(
      postTriggerJob(parseInt(data.job_id), data.data_date, closeHandler, api)
    );
  };

  return (
    <StyledModal
      content={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Controller
                name="job_id"
                control={control}
                render={({ field }) => {
                  return (
                    <StyledSelect
                      label="Job name"
                      options={jobOptions}
                      error={Boolean(errors.job_id)}
                      helperText={errors.job_id?.message}
                      {...field}
                      onChange={onJobChange}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Controller
                name="data_date"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    label="Data Date"
                    error={Boolean(errors.data_date)}
                    helperText={errors.data_date?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Typography mt={2} variant="h6">
            Description
          </Typography>
          <Typography mt={2}>{jobConfig.description ?? "-"}</Typography>
        </>
      }
      action={
        <form onSubmit={handleSubmit(onTriggerSubmit)}>
          <StyledButton
            type="submit"
            title="Run"
            variant="contained"
            disabled={isLoading.trigger}
          />
        </form>
      }
      title={"Trigger"}
      open={open}
      maxWidth="xs"
      closeHandler={closeHandler}
      isLoading={isLoading.trigger}
    />
  );
};

export default TriggerModal;
