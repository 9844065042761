import {
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMERS,
  GET_SALES,
  GET_AR_LIST,
  GET_AP_LIST,
  GET_ALL_NAVISION,
} from "./queries";
import { graphQLClientWithHeader } from "../graphql";

class NavisionService {
  async getNavision(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { navision_report } = await graphQLClient.request(
      GET_ALL_NAVISION,
      input
    );
    return navision_report;
  }
  async getCustomer(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { customer } = await graphQLClient.request(GET_CUSTOMER_BY_ID, input);
    return customer;
  }
  async getCustomers(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { customers } = await graphQLClient.request(GET_CUSTOMERS, input);
    return customers;
  }
  async getSales(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesFromDate } = await graphQLClient.request(GET_SALES, input);
    return salesFromDate;
  }
  async getARList(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { ar_list } = await graphQLClient.request(GET_AR_LIST, input);
    return ar_list;
  }
  async getAPList(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { ap_list } = await graphQLClient.request(GET_AP_LIST, input);
    return ap_list;
  }
}

export default new NavisionService();
