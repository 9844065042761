import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DesktopLayout from "../../components/Layout/DesktopLayout";
import MobileLayout from "../../components/Layout/MobileLayout";
import StyledButton from "../../components/Styled/StyledButton";
import StyledTab from "../../components/Styled/StyledTab";
import ActionBar from "../../components/UI/ActionBar";
import AgGrid from "../../components/UI/AgGrid";
import TriggerModal from "../../components/UI/TriggerModal";
import { getAllJobConfigs } from "../../features/Monitor/monitor-actions";
import { monitorActions } from "../../features/Monitor/monitor-slice";
import { useAuth } from "../../hooks/use-auth";
import { useNavbar } from "../../hooks/use-navbar";

const JobConfig = () => {
  const { user } = useAuth();
  const isCreatable = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("MONITOR__JOB__CREATE") ||
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const { jobConfigs, isLoading } = useSelector((state) => state.monitor);
  const { pathname } = useLocation();
  const gridRef = useRef();
  const { mobile } = useNavbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  const [open, setOpen] = useState(false);
  const closeHandler = () => {
    setOpen(false);
  };

  const columnDefs = [
    { field: "type", headerName: "Type" },
    { field: "script", headerName: "Script" },
    { field: "description", headerName: "Description" },
    { field: "cron_schedule", headerName: "Cron Schedule" },
    { field: "remark", headerName: "Remark" },
  ];

  const breadcrumbs = [
    {
      name: t("monitor.index"),
    },
    {
      name: t("monitor.jobConfig"),
    },
  ];

  const tabs = [
    {
      label: t("monitor.jobLog"),
      path: "/monitor",
    },
    {
      label: t("monitor.jobConfig"),
      path: "/monitor/config",
    },
  ];

  const buttons = [
    isCreatable && {
      title: t("button.trigger"),
      type: "button",
      variant: "contained",
      onClick: () => setOpen(true),
    },
  ];

  useEffect(() => {
    dispatch(getAllJobConfigs());
    return () => {
      dispatch(monitorActions.resetJobConfigs());
    };
  }, [dispatch]);

  useEffect(() => {
    mobile.setMobileTitle(t("monitor.jobConfig"));
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t]);

  return (
    <>
      {!isMobile && (
        <DesktopLayout isSidebarOpen pathname="/monitor">
          <ActionBar
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isSidebarOpen
            isLoading={isLoading.jobConfigs}
          />
          <StyledTab tabs={tabs} currentTab={pathname} />
          <AgGrid
            ref={gridRef}
            rowData={jobConfigs}
            columnDefs={columnDefs}
            height={500}
          />
          <TriggerModal open={open} closeHandler={closeHandler} />
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout pathname="/monitor">
          <Box sx={{ width: "100%", mt: 2 }}>
            <StyledTab tabs={tabs} centered={isMobile} currentTab={pathname} />
            <AgGrid
              ref={gridRef}
              rowData={jobConfigs}
              columnDefs={columnDefs}
              height={500}
              isMobile
            />
          </Box>
          <StyledButton
            title="Trigger"
            variant="contained"
            fullWidth
            onClick={() => setOpen(true)}
          />
          <TriggerModal
            open={open}
            closeHandler={closeHandler}
            api={gridRef?.current?.api}
          />
        </MobileLayout>
      )}
    </>
  );
};

export default JobConfig;
