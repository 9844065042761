import { gql } from "graphql-request";

export const GET_ALL_USERS = gql`
  query Employees {
    employees {
      id
      first_name
      last_name
      email
      phone
      department
      position
      is_active
      line_uid
      img_url
      last_login_date
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query Roles {
    roles {
      id
      name
      description
      permission_list
      employee_list {
        id
      }
    }
  }
`;

export const GET_USER = gql`
  query Employee($employeeId: Int!) {
    employee(id: $employeeId) {
      id
      first_name
      last_name
      email
      phone
      department
      position
      is_active
      line_uid
      img_url
      last_login_date
      role_list {
        id
        name
        permission_list
      }
    }
  }
`;

export const GET_ROLE = gql`
  query Role($roleId: Int!) {
    role(id: $roleId) {
      id
      name
      permission_list
      employee_list {
        id
        first_name
        last_name
        email
        phone
        department
        position
        is_active
        img_url
        last_login_date
      }
      description
    }
  }
`;

export const GET_USER_SETTING = gql`
  query EmployeeSelf {
    employeeSelf {
      id
      first_name
      last_name
      email
      phone
      department
      position
      is_active
      line_uid
      img_url
      last_login_date
      role_list {
        id
        name
        description
        permission_list
      }
    }
  }
`;
