import React, { forwardRef } from "react";
import Chip from "@mui/material/Chip";

const StyledChip = forwardRef(
  ({ handleDelete, variant, value, color, ...props }, ref) => {
    return (
      <Chip
        ref={ref}
        label={value}
        variant={variant || "outlined"}
        onDelete={handleDelete}
        size="medium"
        color={color || "secondary"}
        {...props}
      />
    );
  }
);

export default StyledChip;
