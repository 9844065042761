import React, { useMemo, useState } from "react";
import { createContext } from "react";

const NavbarContext = createContext({
  sidebar: {},
  mobile: {},
  navTab: {},
});

export const NavbarContextProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [mobileTitle, setMobileTitle] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const sidebar = useMemo(
    () => ({ isSidebarOpen, setIsSidebarOpen }),
    [isSidebarOpen, setIsSidebarOpen]
  );
  const mobile = useMemo(
    () => ({ mobileTitle, setMobileTitle }),
    [mobileTitle, setMobileTitle]
  );
  const navTab = useMemo(
    () => ({ tabValue, setTabValue }),
    [tabValue, setTabValue]
  );

  const navbarValue = {
    sidebar,
    mobile,
    navTab,
  };

  return (
    <NavbarContext.Provider value={navbarValue}>
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarContext;
