import { combineReducers } from "@reduxjs/toolkit";
import navisionReducer from "../features/Navision/navision-slice";
import templateReducer from "../features/Template/template-slice";
import accountReducer from "../features/User/Account/account-slice";
import rbacReducer from "../features/User/Rbac/rbac-slice";
import monitorReducer from "../features/Monitor/monitor-slice";

export const rootReducer = combineReducers({
  navision: navisionReducer,
  template: templateReducer,
  account: accountReducer,
  rbac: rbacReducer,
  monitor: monitorReducer,
});
