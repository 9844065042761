import React, { forwardRef, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../table.css";
import { Box, InputAdornment, Typography } from "@mui/material";
import StyledTextField from "../Styled/StyledTextField";
import SearchIcon from "@mui/icons-material/Search";
import AG_GRID_LOCALE_TH from "../../locale.th";
import StyledButton from "../Styled/StyledButton";
import { customChartThemes } from "../../utils/customChartThemes";
import { useSelector } from "react-redux";

const AgGrid = forwardRef(
  (
    {
      rowData,
      columnDefs,
      groupDefaultExpanded,
      enableCharts,
      isExternalFilterPresent,
      autoGroupColumnDef,
      doesExternalFilterPass,
      onFirstDataRendered,
      pinnedBottomRowData,
      onRowClicked,
      onRowDoubleClicked,
      isMobile,
      height,
      formatData,
      getRowStyle,
      groupIncludeTotalFooter,
      rowSelection,
      onSelectionChanged,
      getContextMenuItems,
      searchBox,
      deleteButtonOnclick,
      buttonDelete,
      onGridReady,
      masterDetail,
      detailCellRenderer,
      detailRowHeight,
      pivotMode,
      suppressAggFuncInHeader,
      components,
      onChartCreated,
      onChartOptionsChanged,
      onFilterChanged,
      autoHeight,
      sideBar,
      detailCellRendererParams,
      detailRowAutoHeight,
      has_avg_footer,
      has_sum_footer,
      isLoading,
      sumSelectedTemplateData,
      columns,
      isLandscape,
      isServer,
      isPermission,
    },
    ref
  ) => {
    const { selectedTemplate } = useSelector((state) => state.template);
    const { name, x_axis, y_axis, min_datum, max_datum } = selectedTemplate;

    const defaultColDef = useMemo(() => {
      return {
        editable: false,
        filter: "agMultiColumnFilter",
        floatingFilter: isPermission ? false : true,
        resizable: true,
        sortable: isPermission ? false : true,
      };
    }, [isPermission]);

    const defaultColDefSumTable = useMemo(() => {
      return {
        editable: false,
        filter: false,
        floatingFilter: false,
        resizable: true,
        sortable: false,
      };
    }, []);

    const localeText = useMemo(() => {
      return AG_GRID_LOCALE_TH;
    }, []);

    const chartThemes = ["myCustomTheme"];

    const onFilterTextBoxChanged = useCallback(
      (event) => {
        ref.current.api.setQuickFilter(
          document.getElementById("filter-text-box").value
        );
      },
      [ref]
    );

    const CustomizedNoRowsOverlay = ({ noRowsMessageFunc }) => (
      <Typography>{noRowsMessageFunc()}</Typography>
    );

    const noRowsOverlayComponent = useMemo(() => {
      return CustomizedNoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
      return {
        noRowsMessageFunc: () => "ไม่มีข้อมูล",
      };
    }, []);

    return (
      <>
        {enableCharts && rowData && rowData.length > 0 && (
          <div id="myChart" className="my-chart" />
        )}
        {!isLoading && (has_avg_footer || has_sum_footer) && (
          <div
            id="myGrid"
            className={"ag-theme-material sumTable"}
            style={{ height: 180, width: "100%", marginTop: "1rem" }}
          >
            <AgGridReact
              ref={ref}
              rowData={sumSelectedTemplateData}
              defaultColDef={defaultColDefSumTable}
              columnDefs={columns.map((column, index) => {
                if (index === 0)
                  return {
                    ...column,
                    headerName: "",
                  };
                else {
                  return {
                    ...column,
                    field: column.field.replaceAll(/\./g, ""),
                  };
                }
              })}
            />
          </div>
        )}
        {searchBox ||
          (buttonDelete && (
            <Box
              mt={2}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
              justifyContent={"space-between"}
            >
              {searchBox && (
                <StyledTextField
                  id="filter-text-box"
                  onChange={onFilterTextBoxChanged}
                  sx={{ maxWidth: isMobile ? "100%" : 300 }}
                  placeholder="ค้นหา"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {buttonDelete && (
                <StyledButton
                  sx={{ marginTop: "1rem" }}
                  title="ลบ"
                  variant="contained"
                  onClick={deleteButtonOnclick}
                />
              )}
            </Box>
          ))}
        <Box sx={{ my: 2, height: "100%" }}>
          <div
            id="myGrid"
            className={"ag-theme-material"}
            style={{ height: height, width: "100%" }}
          >
            <AgGridReact
              domLayout={autoHeight ? "autoHeight" : "normal"}
              ref={ref}
              rowData={rowData}
              onGridReady={onGridReady}
              onFilterChanged={onFilterChanged}
              suppressAggFuncInHeader={suppressAggFuncInHeader}
              getRowStyle={getRowStyle}
              suppressMovableColumns={isMobile || isLandscape}
              suppressColumnMoveAnimation={isMobile || isLandscape}
              autoGroupColumnDef={autoGroupColumnDef}
              localeText={localeText}
              pivotMode={pivotMode}
              doesExternalFilterPass={doesExternalFilterPass}
              suppressDragLeaveHidesColumns={isMobile || isLandscape}
              columnDefs={columnDefs}
              components={components}
              groupDefaultExpanded={groupDefaultExpanded}
              defaultColDef={defaultColDef}
              onRowClicked={onRowClicked}
              sideBar={isMobile || isLandscape ? null : sideBar}
              enableCharts={enableCharts}
              detailRowHeight={detailRowHeight}
              enableRangeSelection={false}
              customChartThemes={customChartThemes(
                formatData,
                name,
                x_axis,
                y_axis,
                min_datum,
                max_datum
              )}
              pinnedBottomRowData={pinnedBottomRowData}
              chartThemes={chartThemes}
              noRowsOverlayComponent={noRowsOverlayComponent}
              noRowsOverlayComponentParams={noRowsOverlayComponentParams}
              onFirstDataRendered={onFirstDataRendered}
              onRowDoubleClicked={onRowDoubleClicked}
              getContextMenuItems={getContextMenuItems}
              isExternalFilterPresent={isExternalFilterPresent}
              rowSelection={rowSelection}
              onSelectionChanged={onSelectionChanged}
              groupIncludeTotalFooter={groupIncludeTotalFooter}
              detailCellRenderer={detailCellRenderer}
              masterDetail={masterDetail}
              onChartCreated={onChartCreated}
              onChartOptionsChanged={onChartOptionsChanged}
              detailCellRendererParams={detailCellRendererParams}
              detailRowAutoHeight={detailRowAutoHeight}
              rowModelType={isServer ? "serverSide" : "clientSide"}
              // serverSideStoreType={isServer ? "partial" : "full"}
              serverSideInfiniteScroll={isServer}
              serverSideSortOnServer={isServer}
              serverSideSortAllLevels={isServer}
              serverSideFilterOnServer={isServer}
              // serverSideFilterAllLevels={isServer}
            />
          </div>
        </Box>
      </>
    );
  }
);

export default AgGrid;
