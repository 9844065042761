import { Box, Button, ListItem } from "@mui/material";
import { Link } from "react-router-dom";
import { alpha } from "@mui/material/styles";

export const LeftNavbarItem = (props) => {
  const { active, chip, depth, icon, info, path, title } = props;
  let paddingLeft = 24;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        mb: 0.5,
        py: 0,
        px: 2,
      }}
    >
      <Button
        component={Link}
        to={path}
        startIcon={icon}
        endIcon={chip}
        disableRipple
        sx={{
          borderRadius: 1,
          justifyContent: "flex-start",
          pl: `${paddingLeft}px`,
          pr: 3,
          textAlign: "left",
          textTransform: "none",
          width: "100%",
          ...(active
            ? {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.1),
                color: (theme) => theme.palette.primary.main,
                fontWeight: "900",
              }
            : {
                color: "#52575C",
              }),
          "&:hover": {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
        {info}
      </Button>
    </ListItem>
  );
};
