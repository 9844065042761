import TemplateService from "../../services/Template";
import { mapAgGridTypeFromValueType } from "../../utils/data-transformer";
import { formatDate } from "../../utils/date-converter";
import { templateActions } from "./template-slice";

export const getTemplates = (input) => async (dispatch) => {
  dispatch(templateActions.onLoading("templates"));
  try {
    const templates = await TemplateService.getTemplates(input);
    const formatTemplate = templates.map((template) => {
      return {
        ...template,
        createdAt: formatDate(template.createdAt),
        last_updated_time: formatDate(template.last_updated_time),
      };
    });
    dispatch(templateActions.loadedAlltemplate(formatTemplate));
  } catch (err) {
    dispatch(templateActions.rejectedActions({ ...err, name: "templates" }));
  }
};

export const getTemplatesByUser = () => async (dispatch) => {
  dispatch(templateActions.onLoading("templates"));
  try {
    const templates = await TemplateService.getTemplatesByUser();
    const formatTemplate = templates.map((template) => {
      return {
        ...template,
        createdAt: formatDate(template.createdAt),
        last_updated_time: formatDate(template.last_updated_time),
      };
    });
    dispatch(templateActions.loadedAlltemplate(formatTemplate));
  } catch (err) {
    dispatch(templateActions.rejectedActions({ ...err, name: "template" }));
  }
};
export const getTemplateById = (input) => async (dispatch) => {
  dispatch(templateActions.onLoading("selectedTemplate"));
  try {
    const allTemplate = await TemplateService.getTemplate(input);
    const templateData = await TemplateService.getTemplateData(input);
    let formatData = [];

    templateData.forEach((arr) =>
      arr.data.forEach((datum) => {
        const data = {
          ...datum,
          id: arr.id,
        };
        formatData.push(data);
      })
    );

    const filterNumber = formatData
      .filter((datam) => datam.data_type === "Number")
      .map((number) => parseFloat(number.value))
      .filter((formatNumber) => !isNaN(formatNumber));
    const min_datum = Math.min(...filterNumber);
    const max_datum = Math.max(...filterNumber) * 1.05;

    let template = {
      ...allTemplate,
      min_datum,
      max_datum,
    };

    let tableData = [];
    let checkArray = [];
    formatData.forEach((item) => {
      if (!checkArray.includes(item.id)) {
        checkArray.push(item.id);
        tableData.push({ id: item.id });
      }
      item["name"] = item["name"].replaceAll(/\./g, "");
      let findItem = tableData.find((x) => x.id === item.id);
      let myValue = item["value"];
      if (item.data_type === "Number") myValue = parseFloat(myValue);
      findItem[item["name"]] = myValue;
    });
    dispatch(templateActions.loadedTemplateById(template));
    dispatch(templateActions.loadedTemplateToForm(template));
    dispatch(templateActions.loadColumnsToTemplate(template));
    dispatch(templateActions.loadRowsToTemplate(tableData));
    dispatch(templateActions.successActions("selectedTemplate"));
  } catch (err) {
    console.log(err);
    dispatch(templateActions.rejectedActions({ name: "selectedTemplate" }));
  }
};

export const createTemplate =
  (input, navigate, copy, enqueueSnackbar) => async (dispatch) => {
    dispatch(templateActions.onLoading("creatingTemplate"));
    try {
      const template = await TemplateService.createTemplate(input);
      dispatch(templateActions.loadedTemplateById(template));
      enqueueSnackbar("สร้างรายงานสำเร็จ", {
        variant: "success",
      });
      if (navigate && copy) navigate(`/report/template/${template.id}`);
      if (navigate && !copy) navigate("/report/template");
      dispatch(templateActions.successActions("creatingTemplate"));
    } catch (err) {
      enqueueSnackbar("สร้างรายงานไม่สำเร็จ", {
        variant: "error",
      });
      console.log(err);
      dispatch(templateActions.rejectedActions({ name: "creatingTemplate" }));
    }
  };

export const createTemplateData =
  (template_id, preSendData) => async (dispatch) => {
    dispatch(templateActions.onLoading("creatingTemplateData"));
    try {
      await preSendData.forEach(async (datum) => {
        const createTemplateDatum = {
          template_id: parseInt(template_id),
          data: datum.map((data) => ({
            ...data,
            value: data.value.toString(),
            data_type: mapAgGridTypeFromValueType(data.value),
          })),
        };
        await TemplateService.postCreateTemplateData({
          createTemplateDatum,
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

export const deleteTemplate =
  (input, navigate, enqueueSnackbar) => async (dispatch) => {
    dispatch(templateActions.onLoading("selectedTemplate"));
    try {
      await TemplateService.deleteTemplate(input);
      dispatch(templateActions.removeSelectedTemplete());
      enqueueSnackbar("ลบรายงานสำเร็จ", {
        variant: "success",
      });
      if (navigate) navigate("/report/template");
    } catch (err) {
      enqueueSnackbar("ลบรายงานไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(templateActions.rejectedActions({ err, name: "template" }));
    }
  };

export const updateTemplateData =
  (template_id, preSendData, enqueueSnackbar) => async (dispatch) => {
    dispatch(templateActions.onLoading("selectedTemplate"));
    try {
      await preSendData.forEach(async (datum) => {
        const updateTemplateDatumInput = {
          template_id: parseInt(template_id),
          data: datum.map((data) => ({
            ...data,
            value: data.value.toString(),
            data_type: mapAgGridTypeFromValueType(data.value),
          })),
        };
        await TemplateService.postUpdateTemplateData({
          template_id: parseInt(template_id),
          updateTemplateDatumInput,
        });
      });
      enqueueSnackbar("อัพโหลดข้อมูลสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("อัพโหลดข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        templateActions.rejectedActions({ err, name: "selectedTemplate" })
      );
    }
  };

export const updateTemplate =
  (input, navigate, enqueueSnackbar) => async (dispatch) => {
    dispatch(templateActions.onLoading("selectedTemplate"));
    try {
      const updatedTemplate = await TemplateService.postUpdateTemplate(input);
      if (navigate) navigate(`/report/template/${input.id}`);
      dispatch(templateActions.loadedTemplateById(updatedTemplate));
      dispatch(templateActions.loadedTemplateToForm(updatedTemplate));
      dispatch(templateActions.loadColumnsToTemplate(updatedTemplate));
      enqueueSnackbar("อัพเดทรายงานสำเร็จ", {
        variant: "success",
      });
      dispatch(templateActions.successActions("selectedTemplate"));
    } catch (err) {
      enqueueSnackbar("อัพเดทรายงานไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        templateActions.rejectedActions({ err, name: "selectedTemplate" })
      );
    }
  };

export const removeTemplateDatum =
  (datas, enqueueSnackbar) => async (dispatch) => {
    dispatch(templateActions.onLoading("selectedTemplate"));
    try {
      await datas.forEach(async (data) => {
        await TemplateService.postRemoveTemplateData({ id: parseInt(data.id) });
        await dispatch(
          templateActions.removeRowsfromTemplate(parseInt(data.id))
        );
      });
      enqueueSnackbar("ลบข้อมูลสำเร็จ", {
        variant: "success",
      });
      dispatch(templateActions.successActions("selectedTemplate"));
    } catch (err) {
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(templateActions.rejectedActions({ err, name: "template" }));
    }
  };

export const updateTemplateRole =
  (templateId, updateTemplateRoleInput, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(templateActions.onLoading("selectedTemplate"));
    try {
      await TemplateService.postUpdateTemplateRole(
        templateId,
        updateTemplateRoleInput
      );
      enqueueSnackbar("แชร์รายงานสำเร็จ", {
        variant: "success",
      });
      dispatch(templateActions.successActions("selectedTemplate"));
    } catch (err) {
      enqueueSnackbar("แชร์รายงานไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        templateActions.rejectedActions({ err, name: "selectedTemplate" })
      );
    }
  };
