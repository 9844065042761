import { Box, Grid, useMediaQuery } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DesktopLayout from "../../components/Layout/DesktopLayout";
import MobileLayout from "../../components/Layout/MobileLayout";
import StyledIconBox from "../../components/Styled/StyledIconBox";
import ActionBar from "../../components/UI/ActionBar";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useNavbar } from "../../hooks/use-navbar";

const LandingReport = () => {
  const { mobile } = useNavbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });

  const breadcrumbs = [
    {
      name: t("report.index"),
    },
  ];

  useEffect(() => {
    mobile.setMobileTitle("รายงาน");
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile]);

  const Content = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} sx={6} md={3} lg={2} xl={1.5}>
          <StyledIconBox
            icon={
              <DescriptionOutlinedIcon
                fontSize="large"
                sx={{
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.1),
                  p: "5px",
                  borderRadius: "50%",
                }}
              />
            }
            title={t("report.navision.index")}
            onClick={() => navigate(`${pathname}/navision`)}
          />
        </Grid>
        <Grid item xs={6} sx={6} md={3} lg={2} xl={1.5}>
          <StyledIconBox
            icon={
              <DescriptionOutlinedIcon
                fontSize="large"
                sx={{
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.1),
                  p: "5px",
                  borderRadius: "50%",
                }}
              />
            }
            title={t("report.template.index")}
            onClick={() => navigate(`${pathname}/template`)}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {!isMobile && (
        <DesktopLayout isSidebarOpen>
          <ActionBar breakcrumbs={breadcrumbs} isSidebarOpen />
          <Content />
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout>
          <Box
            sx={{
              my: 2,
            }}
          >
            <Content />
          </Box>
        </MobileLayout>
      )}
    </>
  );
};

export default LandingReport;
