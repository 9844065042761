export const initialState = {
  isLoading: {
    allUsers: false,
    user: false,
  },
  allUsers: [],
  user: {
    first_name: "",
    last_name: "",
    email: "",
    department: null,
    position: null,
    phone: "",
    img_url: null,
    created_date: null,
    last_login_date: null,
    is_active: true,
    role_list: [],
    old_password: "",
    new_password: "",
    confirm_new_password: "",
    confirm_password: "",
  },
  error: null,
};
