import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ColorStatus = styled(Box)(({ bgcolor, variant }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: "3px",
  backgroundColor: bgcolor,
  padding: variant === "logStatus" ? ".15rem 1rem" : ".25rem 1.5rem",
  width: "fit-content",
}));

const StyledStatus = ({ status, variant }) => {
  const { t } = useTranslation();
  const renderStatus = (status) => {
    switch (status.toUpperCase()) {
      case "SUCCESS":
        return (
          <ColorStatus bgcolor="#EDF7ED" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                color: "#1E4620",
              }}
            >
              {status}
            </Typography>
          </ColorStatus>
        );
      case "RUNNING":
        return (
          <ColorStatus bgcolor="#E9F5FE" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                color: "#0D3C61",
              }}
            >
              {status}
            </Typography>
          </ColorStatus>
        );
      case "FAILED":
        return (
          <ColorStatus bgcolor="#FEECEB" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                color: "#621B16",
              }}
            >
              {status}
            </Typography>
          </ColorStatus>
        );
      case "ACTIVE":
        return (
          <ColorStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                color: "#246527",
              }}
            >
              {t("status.active")}
            </Typography>
          </ColorStatus>
        );
      case "IN_ACTIVE":
        return (
          <ColorStatus bgcolor="#FFA396" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                color: "#B54839",
              }}
            >
              {t("status.in_active")}
            </Typography>
          </ColorStatus>
        );
      default:
        return;
    }
  };

  return renderStatus(status);
};

export default StyledStatus;
