import { graphQLClientWithHeader } from "../graphql";
import {
  CREATE_NEW_ROLE,
  CREATE_NEW_USER,
  DELETE_ROLE,
  UPDATE_PASSWORD,
  UPDATE_ROLE,
  UPDATE_USER,
  UPDATE_USER_ROLE,
  UPDATE_USER_SETTING,
} from "./mutations";
import {
  GET_ALL_ROLES,
  GET_ALL_USERS,
  GET_USER,
  GET_ROLE,
  GET_USER_SETTING,
} from "./queries";

class UserService {
  async getAllUsers() {
    const graphQLClient = graphQLClientWithHeader();
    const { employees } = await graphQLClient.request(GET_ALL_USERS);
    return employees;
  }
  async getAllRoles() {
    const graphQLClient = graphQLClientWithHeader();
    const { roles } = await graphQLClient.request(GET_ALL_ROLES);
    return roles;
  }
  async postNewUser(createEmployeeInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createEmployee } = await graphQLClient.request(CREATE_NEW_USER, {
      createEmployeeInput,
    });
    return createEmployee;
  }
  async postNewRole(createRoleInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createRole } = await graphQLClient.request(CREATE_NEW_ROLE, {
      createRoleInput,
    });
    return createRole;
  }
  async getUser(employeeId) {
    const graphQLClient = graphQLClientWithHeader();
    const { employee } = await graphQLClient.request(GET_USER, {
      employeeId,
    });
    return employee;
  }

  async getUserSetting() {
    const graphQLClient = graphQLClientWithHeader();
    const { employeeSelf } = await graphQLClient.request(GET_USER_SETTING);
    return employeeSelf;
  }
  async getRole(roleId) {
    const graphQLClient = graphQLClientWithHeader();
    const { role } = await graphQLClient.request(GET_ROLE, {
      roleId,
    });
    return role;
  }
  async updateRole(updateRoleInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateRole } = await graphQLClient.request(UPDATE_ROLE, {
      updateRoleInput,
    });
    return updateRole;
  }

  async updateUserSetting(updateEmployeeSelfInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateEmployeeSelf } = await graphQLClient.request(
      UPDATE_USER_SETTING,
      {
        updateEmployeeSelfInput,
      }
    );
    return updateEmployeeSelf;
  }

  async updateUser(updateEmployeeInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateEmployee } = await graphQLClient.request(UPDATE_USER, {
      updateEmployeeInput,
    });
    return updateEmployee;
  }

  async updateUserRole(updateEmployeeRolesInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateEmployeeRoles } = await graphQLClient.request(
      UPDATE_USER_ROLE,
      {
        updateEmployeeRolesInput,
      }
    );
    return updateEmployeeRoles;
  }

  async updatePassword(passwordInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updatePassword } = await graphQLClient.request(UPDATE_PASSWORD, {
      passwordInput,
    });
    return updatePassword;
  }

  async deleteRole(deleteRoleId) {
    const graphQLClient = graphQLClientWithHeader();
    const { deleteRole } = await graphQLClient.request(DELETE_ROLE, {
      deleteRoleId,
    });
    return deleteRole;
  }
}

export default new UserService();
