import { forwardRef, useState, useCallback } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StyledDownloadButton from "./StyledDownloadButton";
import { useTranslation } from "react-i18next";

const StyledMenuButton = forwardRef(
  ({ title, variant, csvFilename, excelFilename, headers, data }, ref) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const onBtnExportCSV = useCallback(() => {
      const config = {
        fileName: csvFilename,
      };
      ref.current.api.exportDataAsCsv(config);

      handleClose();
    }, [ref, csvFilename]);

    const onBtnExportExcel = useCallback(() => {
      const config = {
        fileName: excelFilename,
      };
      ref.current.api.exportDataAsExcel(config);
      handleClose();
    }, [ref, excelFilename]);

    const { t } = useTranslation();

    return (
      <>
        {ref && (
          <div>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant={variant}
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {title}
            </Button>
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={onBtnExportCSV} disableRipple>
                CSV
              </MenuItem>
              <MenuItem onClick={onBtnExportExcel} disableRipple>
                Excel
              </MenuItem>
            </Menu>
          </div>
        )}
        {!ref && (
          <StyledDownloadButton
            filename={csvFilename}
            data={data}
            headers={headers}
            onClick={handleClose}
          >
            {t("button.export")}
          </StyledDownloadButton>
        )}
      </>
    );
  }
);

export default StyledMenuButton;
