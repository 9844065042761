import { graphQLClientWithHeader } from "../graphql";
import {
  GET_TEMPLATE_BY_ID,
  GET_TEMPLATES,
  GET_TEMPLATE_DATA_BY_ID,
  GET_TEMPLATES_BY_USER,
} from "./queries";
import {
  CREATE_DATA,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_DATA,
  UPDATE_DATA,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_ROLE,
} from "./mutation";

class TemplateService {
  async getTemplate(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { template } = await graphQLClient.request(GET_TEMPLATE_BY_ID, input);
    return template;
  }

  async getTemplates(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { templates } = await graphQLClient.request(GET_TEMPLATES, input);
    return templates;
  }

  async getTemplatesByUser() {
    const graphQLClient = graphQLClientWithHeader();
    const { templatesByUser } = await graphQLClient.request(
      GET_TEMPLATES_BY_USER
    );
    return templatesByUser;
  }

  async createTemplate(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { createTemplate } = await graphQLClient.request(
      CREATE_TEMPLATE,
      input
    );
    return createTemplate;
  }

  async deleteTemplate(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { templates } = await graphQLClient.request(DELETE_TEMPLATE, input);
    return templates;
  }

  async getTemplateData(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { templateData } = await graphQLClient.request(
      GET_TEMPLATE_DATA_BY_ID,
      input
    );
    return templateData;
  }

  async postCreateTemplateData(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { createTemplateDatum } = await graphQLClient.request(
      CREATE_DATA,
      input
    );
    return createTemplateDatum;
  }

  async postUpdateTemplateData(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateTemplateDatum } = await graphQLClient.request(
      UPDATE_DATA,
      input
    );
    return updateTemplateDatum;
  }

  async postRemoveTemplateData(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { removeTemplateDatum } = await graphQLClient.request(
      DELETE_TEMPLATE_DATA,
      input
    );
    return removeTemplateDatum;
  }

  async postUpdateTemplate(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateTemplate } = await graphQLClient.request(
      UPDATE_TEMPLATE,
      input
    );
    return updateTemplate;
  }

  async postUpdateTemplateRole(templateId, updateTemplateRoleInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateTemplateRole } = await graphQLClient.request(
      UPDATE_TEMPLATE_ROLE,
      {
        templateId,
        updateTemplateRoleInput,
      }
    );
    return updateTemplateRole;
  }
}

export default new TemplateService();
