import { Box, Grid, useMediaQuery } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DesktopLayout from "../../components/Layout/DesktopLayout";
import MobileLayout from "../../components/Layout/MobileLayout";
import StyledIconBox from "../../components/Styled/StyledIconBox";
import ActionBar from "../../components/UI/ActionBar";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useNavbar } from "../../hooks/use-navbar";
import { useAuth } from "../../hooks/use-auth";

const UserLanding = () => {
  const { mobile } = useNavbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });

  const breadcrumbs = [
    {
      name: t("user.account.index"),
    },
  ];

  let allPermissions = [];

  user?.role_list?.forEach((role) => {
    allPermissions.push(...role.permission_list);
  });

  useEffect(() => {
    mobile.setMobileTitle("บัญชีผู้ใช้");
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile]);

  const Content = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} sx={6} md={3} lg={2} xl={1.5}>
          <StyledIconBox
            icon={
              <PermIdentityOutlinedIcon
                fontSize="large"
                sx={{
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.1),
                  p: "5px",
                  borderRadius: "50%",
                }}
              />
            }
            title={t("user.config")}
            onClick={() => navigate(`${pathname}/config`)}
          />
        </Grid>
        {(allPermissions.includes("ADMIN__ADMIN__ADMIN") ||
          allPermissions.includes("CONFIG__RBAC__VIEW") ||
          allPermissions.includes("CONFIG__RBAC__CREATE") ||
          allPermissions.includes("CONFIG__RBAC__EDIT") ||
          allPermissions.includes("CONFIG__RBAC__DELETE")) && (
          <Grid item xs={6} sx={6} md={3} lg={2} xl={1.5}>
            <StyledIconBox
              icon={
                <PrivacyTipOutlinedIcon
                  fontSize="large"
                  sx={{
                    backgroundColor: (theme) =>
                      alpha(theme.palette.primary.main, 0.1),
                    p: "5px",
                    borderRadius: "50%",
                  }}
                />
              }
              title={t("user.rbac.index")}
              onClick={() => navigate(`${pathname}/rbac`)}
            />
          </Grid>
        )}
        {(allPermissions.includes("ADMIN__ADMIN__ADMIN") ||
          allPermissions.includes("CONFIG__USER__VIEW") ||
          allPermissions.includes("CONFIG__USER__CREATE") ||
          allPermissions.includes("CONFIG__USER__EDIT") ||
          allPermissions.includes("CONFIG__USER__DELETE")) && (
          <Grid item xs={6} sx={6} md={3} lg={2} xl={1.5}>
            <StyledIconBox
              icon={
                <PeopleOutlineOutlinedIcon
                  fontSize="large"
                  sx={{
                    backgroundColor: (theme) =>
                      alpha(theme.palette.primary.main, 0.1),
                    p: "5px",
                    borderRadius: "50%",
                  }}
                />
              }
              title={t("user.index")}
              onClick={() => navigate(`${pathname}/admin`)}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      {!isMobile && (
        <DesktopLayout isSidebarOpen>
          <ActionBar breakcrumbs={breadcrumbs} isSidebarOpen />
          <Content />
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout>
          <Box sx={{ my: 2 }}>
            <Content />
          </Box>
        </MobileLayout>
      )}
    </>
  );
};

export default UserLanding;
