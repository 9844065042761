import React, { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import StyledTab from "../../../components/Styled/StyledTab";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import EmployeeTab from "./EmployeeTab";
import PermissionTab from "./PermissionTab";
import { useDispatch, useSelector } from "react-redux";
import {
  mapObjectToEnum,
  mapRolesToObject,
} from "../../../utils/data-transformer";
import { useFieldArray, useForm } from "react-hook-form";
import { permissionValidator } from "./validator";
import ActionBar from "../../../components/UI/ActionBar";
import { useNavbar } from "../../../hooks/use-navbar";
import { DialogContentText, useMediaQuery } from "@mui/material";
import MobileLayout from "../../../components/Layout/MobileLayout";
import { Box } from "@mui/system";
import {
  deleteRole,
  getRole,
  postNewRole,
  setPriviledgeList,
  updateRole,
} from "../../../features/User/Rbac/rbac-action";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../hooks/use-auth";
import { rbacActions } from "../../../features/User/Rbac/rbac-slice";
import StyledModal from "../../../components/Styled/StyledModal";
import StyledButton from "../../../components/Styled/StyledButton";

const RbacContainer = ({ viewOnly }) => {
  const location = useLocation();
  const { user } = useAuth();
  const isCreatable = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("CONFIG__RBAC__CREATE") ||
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const isEditable = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("CONFIG__RBAC__EDIT") ||
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const isDeletable = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("CONFIG__RBAC__DELETE") ||
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const isViewEmployee = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("CONFIG__USER__VIEW") ||
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );

  const selectEmployeegridRef = useRef();
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const { role, isLoading } = useSelector((state) => state.rbac);
  const { roleId } = useParams();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get("step");
  const navigate = useNavigate();
  const { mobile } = useNavbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  const [confirmation, setConfirmation] = useState();

  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: role,
    resolver: yupResolver(permissionValidator),
  });

  const { fields, append, remove, replace } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "employee_list", // unique name for your Field Array
    keyName: "document_id",
  });

  useEffect(() => {
    setSearchParams({ step: "permission", subtab: "template" });
  }, [setSearchParams]);

  useEffect(() => {
    mobile.setMobileTitle(t("user.rbac.index"));
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t]);

  const breadcrumbs = [
    {
      name: t("user.index"),
      href: "/user",
    },
    {
      name: t("user.rbac.index"),
      href: "/user/rbac",
    },
    {
      name: viewOnly ? role.name : t("user.rbac.addNewRole"),
    },
  ];

  const tabs = [
    {
      label: t("user.rbac.index"),
      path: `${pathname}?step=permission&subtab=template`,
    },
    {
      label: t("user.rbac.employee"),
      path: `${pathname}?step=employee`,
    },
  ];

  const currentTab =
    pathname +
    (step
      ? `?step=${step}${step === "permission" ? "&subtab=template" : ""}`
      : "");

  const admin = user?.role_list?.some((role) =>
    role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );

  const onRoleSubmit = (data, type) => {
    if (type !== "triggerRevalidate") {
      const serializedData = Object.assign({}, data);
      delete serializedData.name;
      delete serializedData.description;
      delete serializedData.employee_list;
      delete serializedData.id;
      delete serializedData.permission_list;
      const permission_list = mapObjectToEnum(serializedData);
      const payload = {
        name: data.name,
        description: data.description,
        permission_list: role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
          ? ["ADMIN__ADMIN__ADMIN"]
          : permission_list,
        employee_id_list: data.employee_list.map((employee) => employee.id),
      };

      const updateRolePayload = {
        id: data.id,
        ...payload,
      };

      if (admin && viewOnly) {
        //case : update existing role
        if (type === "updateEmployee") {
          //update employee before delete role
          dispatch(updateRole(updateRolePayload, enqueueSnackbar));
        } else if (type === "updatePermission") {
          //normal update role details
          dispatch(updateRole(updateRolePayload, enqueueSnackbar, navigate));
        }
      } else {
        if (type === "updatePermission") {
          dispatch(updateRole(updateRolePayload, enqueueSnackbar, navigate));
        } else {
          dispatch(postNewRole(payload, navigate, enqueueSnackbar));
        }
        //case : create new role
      }
    }
  };

  const handleCloseModal = () => setOpenModal(false);

  // const onRoleDelete = (deletedRoleId) => {
  //   dispatch(deleteRole({ id: deletedRoleId }, navigate, enqueueSnackbar));
  // };

  const onButtonClicked = async (data) => {
    onRoleSubmit(data, "triggerRevalidate");
    const validInput = await trigger(["name", "description"]);
    if (validInput) navigate(`${pathname}?step=employee`);
  };

  //update employee
  const onEmployeeUpdate = async (data) => {
    onRoleSubmit(data, "updateEmployee");
  };

  const onRoleDelete = (deletedRoleId) => {
    dispatch(deleteRole(deletedRoleId, navigate, enqueueSnackbar));
  };

  useEffect(() => {
    //get data of selected role
    if (roleId) {
      dispatch(getRole(parseInt(roleId), enqueueSnackbar));
    }
  }, [dispatch, roleId, enqueueSnackbar]);

  useEffect(() => {
    if (role) {
      //replace default values of form hook with new value from redux
      Object.entries(role).forEach(([key, value]) => setValue(key, value));
    }
  }, [role, setValue]);

  useEffect(() => {
    //filter row and show permission in the table

    if (roleId) {
      const priviledgeListPayload = mapRolesToObject([role], role.name);
      dispatch(setPriviledgeList(priviledgeListPayload));
    }
  }, [dispatch, role, roleId]);

  useEffect(() => {
    //clear redux state
    return () => dispatch(rbacActions.resetRole());
  }, [dispatch]);

  const handleSelectEmployees = () => {
    const selectedEmployees =
      selectEmployeegridRef.current.api.getSelectedRows();
    selectedEmployees.forEach((employee) => {
      if (
        getValues("employee_list").findIndex(
          (item) => item.id === employee.id
        ) < 0
      )
        append(employee);
    });
    handleCloseModal();
  };

  const handleRemoveEmployees = () => {
    const selectedEmployees = gridRef.current.api.getSelectedRows();
    if (selectedEmployees.length > 0) {
      selectedEmployees.forEach((employee) => {
        const removeIndex = getValues("employee_list").findIndex(
          (item) => item.id === employee.id
        );
        remove(removeIndex);
      });
      if (getValues("id") !== "") {
        onEmployeeUpdate();
      }
    } else {
      enqueueSnackbar("กรุณาเลือกพนักงาน", { variant: "warning" });
    }
  };

  const buttons = [
    !pathname.includes("create") &&
      step === "permission" && {
        type: "menuOption",
        title: t("button.option"),
        variant: "contained",
        options: [{ label: "ลบสิทธิ์", disabled: !isDeletable }],
        onSelect: (e) => {
          if (e.target.innerText === "ลบสิทธิ์") {
            setConfirmation(true);
          }
        },
      },
    step === "employee" && {
      type: "menuOption",
      title: t("button.option"),
      variant: "contained",
      options: [
        { label: "ลบสิทธิ์", disabled: !isDeletable },
        { label: "ลบพนักงาน", disabled: !isDeletable },
      ],
      onSelect: (e) => {
        if (e.target.innerText === "ลบพนักงาน") {
          handleRemoveEmployees();
        } else if (e.target.innerText === "ลบสิทธิ์") {
          setConfirmation(true);
        }
      },
    },
    step === "employee" &&
      (isCreatable || isEditable) && {
        type: "button",
        title: t("user.rbac.assignToEmployee"),
        variant: "contained",
        onClick: () => setOpenModal(true),
      },
    step === "permission" &&
      isViewEmployee && {
        type: "button",
        title: t("button.save"),
        variant: "contained",
        onClick: handleSubmit(onButtonClicked),
      },
    step === "employee" &&
      isCreatable &&
      !roleId && {
        type: "button",
        title: t("button.save"),
        variant: "contained",
        onClick: handleSubmit((data) => onRoleSubmit(data, "addPermission")),
      },
    step === "employee" &&
      roleId &&
      isEditable && {
        type: "button",
        title: t("button.save"),
        variant: "contained",
        onClick: handleSubmit((data) => onRoleSubmit(data, "updatePermission")),
      },
  ];

  return (
    <>
      {!isMobile && (
        <DesktopLayout isSidebarOpen pathname="/user/rbac">
          <form onSubmit={handleSubmit(onRoleSubmit)}>
            <ActionBar
              breakcrumbs={breadcrumbs}
              buttons={buttons}
              isSidebarOpen
              isLoading={isLoading.role || isLoading.priviledgeList}
            />
            <StyledTab tabs={tabs} currentTab={currentTab} />
            <Box sx={{ mt: 2 }}>
              {step === "permission" ? (
                <PermissionTab
                  control={control}
                  errors={errors}
                  trigger={trigger}
                  viewOnly={viewOnly}
                  admin={admin}
                  fields={fields}
                  replace={replace}
                />
              ) : isViewEmployee ? (
                <EmployeeTab
                  open={openModal}
                  handleClose={handleCloseModal}
                  fields={fields}
                  viewOnly={viewOnly}
                  admin={admin}
                  selectEmployeegridRef={selectEmployeegridRef}
                  gridRef={gridRef}
                  handleSelectEmployees={handleSelectEmployees}
                />
              ) : (
                <Navigate
                  to="/unauthorized"
                  state={{ from: location }}
                  replace
                />
              )}
            </Box>
          </form>
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout
          rbac
          isLoading={isLoading.role || isLoading.priviledgeList}
          options={
            !pathname.includes("create") && step === "permission"
              ? [{ label: "ลบสิทธิ์", disabled: !isDeletable }]
              : step === "employee"
              ? [
                  { label: "ลบสิทธิ์", disabled: !isDeletable },
                  { label: "ลบพนักงาน", disabled: !isDeletable },
                ]
              : []
          }
          onSelect={
            !pathname.includes("create") && step === "permission"
              ? (e) => {
                  if (e.target.innerText === "ลบสิทธิ์") {
                    setConfirmation(true);
                  }
                }
              : (e) => {
                  if (e.target.innerText === "ลบพนักงาน") {
                    handleRemoveEmployees();
                  } else if (e.target.innerText === "ลบสิทธิ์") {
                    setConfirmation(true);
                  }
                }
          }
          pathname="/user/rbac"
        >
          <Box sx={{ my: 2 }}>
            <form onSubmit={handleSubmit(onRoleSubmit)}>
              <StyledTab tabs={tabs} currentTab={currentTab} />
              <Box sx={{ mt: 2 }}>
                {step === "permission" ? (
                  <PermissionTab
                    control={control}
                    errors={errors}
                    trigger={trigger}
                    viewOnly={viewOnly}
                    admin={admin}
                    fields={fields}
                    replace={replace}
                  />
                ) : isViewEmployee ? (
                  <EmployeeTab
                    open={openModal}
                    handleClose={handleCloseModal}
                    fields={fields}
                    viewOnly={viewOnly}
                    admin={admin}
                    selectEmployeegridRef={selectEmployeegridRef}
                    gridRef={gridRef}
                    handleSelectEmployees={handleSelectEmployees}
                  />
                ) : (
                  <Navigate
                    to="/unauthorized"
                    state={{ from: location }}
                    replace
                  />
                )}
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
              >
                {buttons
                  .filter((button) => button !== null && button !== undefined)
                  .map((button) => {
                    if (button.type === "button") {
                      return (
                        <StyledButton
                          fullWidth
                          title={button.title}
                          type={button.type}
                          variant={button.variant}
                          onClick={button.onClick}
                        />
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </Box>
            </form>
          </Box>
        </MobileLayout>
      )}
      <StyledModal
        maxWidth="xs"
        closeHandler={() => {
          setConfirmation(false);
        }}
        title={`ยืนยันการลบสิทธิ์ "${role.name}"`}
        open={confirmation}
        action={
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: ".5rem" }}
          >
            <StyledButton
              title="ยกเลิก"
              variant="outlined"
              onClick={() => {
                setConfirmation(false);
              }}
            />
            <StyledButton
              title="ยืนยัน"
              variant="contained"
              onClick={() => onRoleDelete(parseInt(roleId))}
            />
          </Box>
        }
        content={
          <DialogContentText>
            การกระทำดังกล่าวไม่สามารถย้อนได้
          </DialogContentText>
        }
      />
    </>
  );
};

export default RbacContainer;
