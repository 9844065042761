import navisionService from "../../services/Navision";
import { navisionActions } from "./navision-slice";

export const getAllNavision = () => async (dispatch) => {
  dispatch(navisionActions.onLoading(["allNavisions"]));
  try {
    const navision = await navisionService.getNavision();
    dispatch(navisionActions.loadedAllNavision(navision));
  } catch (err) {
    dispatch(navisionActions.rejectedActions({ ...err, name: "allNavisions" }));
  }
};

export const getCustomerById = (input) => async (dispatch) => {
  dispatch(navisionActions.onLoading(["customer", "selectedCustomer"]));
  try {
    const customer = await navisionService.getCustomer(input);
    dispatch(navisionActions.loadedCustomerById(customer));
  } catch (err) {
    dispatch(navisionActions.rejectedActions({ ...err, name: "customer" }));
  }
};

export const getAllCustomer = () => async (dispatch) => {
  // Received Params from customer
  dispatch(navisionActions.onLoading(["customer", "allCustomer"]));
  try {
    // fetch customer based on providing input
    const customers = await navisionService.getCustomers();
    dispatch(navisionActions.loadedCustomers(customers));
  } catch (err) {
    dispatch(navisionActions.rejectedActions({ ...err, name: "customer" }));
  }
};

export const getAllSales = (input, type) => async (dispatch) => {
  // Received Params from customer
  dispatch(navisionActions.onLoading(["sales"]));
  try {
    const sales = await navisionService.getSales(input);

    // succussfully inserted data to Ag-grid by successCallback (first aug -> result per block size => configurate in Ag-grid options, secong aug -> last rows)

    dispatch(navisionActions.loadedSales({ sales, type }));
  } catch (err) {
    dispatch(navisionActions.rejectedActions({ ...err, name: "sales" }));
  }
};

export const getAllAP = (input) => async (dispatch) => {
  // Received Params from customer
  dispatch(navisionActions.onLoading(["ap"]));
  try {
    const apList = await navisionService.getAPList(input);
    // succussfully inserted data to Ag-grid by successCallback (first aug -> result per block size => configurate in Ag-grid options, secong aug -> last rows)
    dispatch(navisionActions.loadedAllAP(apList));
  } catch (err) {
    dispatch(navisionActions.rejectedActions({ ...err, name: "ap" }));
  }
};

export const getAllAR = (input) => async (dispatch) => {
  // Received Params from customer
  dispatch(navisionActions.onLoading(["ar"]));
  try {
    const arList = await navisionService.getARList(input);
    dispatch(navisionActions.loadedAllAR(arList));
  } catch (err) {
    dispatch(navisionActions.rejectedActions({ ...err, name: "ar" }));
  }
};
