import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { useAuth } from "../hooks/use-auth";

const RequireAuth = ({ allowedPermissions }) => {
  const cookies = new Cookies();
  const token = cookies.get("access_token");
  const { isAuthenticated, logout, user } = useAuth();
  const location = useLocation();

  const allPermissions = ["GENERAL__GENERAL__VIEW"];

  user?.role_list?.forEach((role) => {
    allPermissions.push(...role.permission_list);
  });

  useEffect(() => {
    if (!token) {
      logout();
    }
  }, [logout, token]);

  return isAuthenticated &&
    allPermissions.find((permission) =>
      allowedPermissions?.includes(permission)
    ) ? (
    <Outlet />
  ) : isAuthenticated ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
export default RequireAuth;
