import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { formatDate } from "../../utils/date-converter";

export default function CustomerUI({ customerInfo, isMobile }) {
  const styledNameColumnWithBorder = {
    borderBottom: "1px solid #D6CFCF",
    padding: "0.7rem 0 0.7rem 1rem",
    minHeight: "47px",
  };

  const styledNameColumn = {
    padding: "0.7rem 0 0.7rem 1rem",
    minHeight: "47px",
  };

  const styledValueColumn = {
    borderBottom: `1px solid #F9F9FA`,
    bgcolor: "white",
    padding: "0.7rem 0 0.7rem 1rem",
    minHeight: "47px",
  };

  const styledValueColumnRdTRCorner = {
    borderBottom: `1px solid #F9F9FA`,
    borderRadius: "0 8px 0 0",
    bgcolor: "white",
    padding: "0.7rem 0 0.7rem 1rem",
    minHeight: "47px",
  };

  const styledValueColumnRdBRCorner = {
    borderBottom: `1px solid #F9F9FA`,
    borderRadius: "0 0 8px 0",
    bgcolor: "white",
    padding: "0.7rem 0 0.7rem 1rem",
    minHeight: "47px",
  };

  const toLocaleHandle = (data) => {
    if (data) {
      const newData = parseFloat(data);
      return newData.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return data;
  };

  const setSxCondition = (index, number, count) => {
    if (index === count) {
      return styledValueColumnRdTRCorner;
    } else if (index === number.length - 1) {
      return styledValueColumnRdBRCorner;
    } else {
      return styledValueColumn;
    }
  };

  const renderColumn = (name, value, index, number) => {
    return (
      <React.Fragment key={index}>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={
            index === number.length - 1 || index === number.length - 2
              ? styledNameColumn
              : styledNameColumnWithBorder
          }
        >
          <Typography variant="body2">{name}</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={setSxCondition(index, number, 1)}
        >
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </React.Fragment>
    );
  };

  const renderSecondColumn = (name, value, index, number) => {
    return (
      <React.Fragment key={index}>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={
            index === number.length - 1
              ? styledNameColumn
              : styledNameColumnWithBorder
          }
        >
          <Typography variant="body2">{name}</Typography>
        </Grid>
        <Grid
          item
          xs={9}
          sm={9}
          md={9}
          lg={9}
          xl={9}
          sx={setSxCondition(index, number, 0)}
        >
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </React.Fragment>
    );
  };

  const firstLayout = [
    {
      name: "No",
      value: customerInfo.customer_no,
    },
    { name: "Search Name", value: customerInfo.search_name },
    { name: "Name", value: customerInfo.customer_name },
    { name: "E-mail", value: customerInfo.email },
    { name: "Address", value: customerInfo.address },
    { name: "Contact", value: customerInfo.contact },
    { name: "Address 2", value: customerInfo.address2 },
    { name: "TextPhone No.", value: customerInfo.fax_no },
    { name: "Post Code/City", value: customerInfo.post_code },
    { name: "Phone No.", value: customerInfo.phone_no },
    { name: "County/Country Code", value: customerInfo.county },
    { name: "Tax Identification", value: customerInfo.tax_id },
    { name: "Branch Code", value: customerInfo.branch_code },
    { name: "Salesperson Code", value: customerInfo.sales_person_code },
    { name: "Location Code", value: customerInfo.location_code },
    { name: "Blocked", value: customerInfo.blocked },
    { name: "No.Of Ship-to Address", value: customerInfo.no_ship_address },
    {
      name: "Last Date Modification",
      value: formatDate(customerInfo.last_date_modified),
    },
    { name: "", value: "" },
    { name: "Hide", value: "" },
  ];

  const secondLayout = [
    { name: "Remark(Sale)", value: customerInfo.remark_sale },
    { name: "Remark(ACC)", value: customerInfo.remark_acc },
  ];

  const thirdLayout = [
    { name: "Clean credit", value: toLocaleHandle(customerInfo.clean_credit) },
    {
      name: "Outstanding Order",
      value: toLocaleHandle(customerInfo.outstanding_order_lcy),
    },
    {
      name: "Bank Guarantee",
      value: toLocaleHandle(customerInfo.bank_guarantee),
    },
    {
      name: "Shipped Not Invoiced",
      value: toLocaleHandle(customerInfo.shipped_not_invoiced_lcy),
    },
    { name: "Others", value: toLocaleHandle(customerInfo.others) },
    { name: "Not Due", value: toLocaleHandle(customerInfo.not_due) },
    {
      name: "Nop. of Credit doc",
      value: customerInfo.no_of_credit_document,
    },
    {
      name: "Overdue 1-30 Days",
      value: toLocaleHandle(customerInfo.overdue_1_30_days),
    },
    {
      name: "Credit Limit",
      value: toLocaleHandle(customerInfo.credit_limt_lcy),
    },
    {
      name: "Overdue 31-60 Days",
      value: toLocaleHandle(customerInfo.overdue_31_60_days),
    },
    {
      name: "Outstanding Logistic",
      value: toLocaleHandle(customerInfo.outstanding_logistic_charge),
    },
    {
      name: "Overdue 61-90 Days",
      value: toLocaleHandle(customerInfo.overdue_61_90_days),
    },
    {
      name: "Outstanding Loading",
      value: toLocaleHandle(customerInfo.outstanding_loading_charge),
    },
    {
      name: "Overdue 91-180 Days",
      value: toLocaleHandle(customerInfo.overdue_91_180_days),
    },
    {
      name: "Outstanding Web",
      value: toLocaleHandle(customerInfo.outstanding_web_sales),
    },
    {
      name: "Overdue > 180 Days",
      value: toLocaleHandle(customerInfo.overdue_180_days),
    },
    {
      name: "Outstanding Online",
      value: toLocaleHandle(customerInfo.outstanding_online_payment),
    },
    { name: "Balance(LCY)", value: toLocaleHandle(customerInfo.balance_lcy) },
    {
      name: "Remaining Credit",
      value: toLocaleHandle(customerInfo.remaining_credit),
    },
    {
      name: "Provisional Balance",
      value: toLocaleHandle(customerInfo.provisional_balance_lcy),
    },
    { name: "", value: "" },
    { name: "Hide CN(LCY)", value: toLocaleHandle(customerInfo.hide_cn_lcy) },
  ];

  const boxSx = {
    border: "1px solid #D6CFCF",
    borderRadius: "8px",
    minWidth: "900px",
    width: "100%",
  };

  return (
    <Box sx={{ p: isMobile ? "0 24px" : 3 }}>
      <Typography variant="h6">ข้อมูลทั่วไป</Typography>
      <Box my={2} sx={boxSx}>
        <Grid container>
          {firstLayout.map((data, index) =>
            renderColumn(data.name, data.value, index, firstLayout)
          )}
        </Grid>
      </Box>
      <Box my={2} sx={{ border: "1px solid #D6CFCF", borderRadius: "8px" }}>
        <Grid
          container
          sx={{
            minWidth: "900px",
            width: "100%",
          }}
        >
          {secondLayout.map((data, index) =>
            renderSecondColumn(data.name, data.value, index, secondLayout)
          )}
        </Grid>
      </Box>
      <Typography variant="h6">วงเงินและภาระหนี้</Typography>
      <Box my={2} sx={boxSx}>
        <Grid container>
          {thirdLayout.map((data, index) =>
            renderColumn(data.name, data.value, index, thirdLayout)
          )}
        </Grid>
      </Box>
    </Box>
  );
}
