import MonitorService from "../../services/Monitor";
import { monitorActions } from "./monitor-slice";

export const getAllJobLogs = (input, params) => async (dispatch) => {
  dispatch(monitorActions.onLoading("jobLogs"));
  try {
    const allJobLogs = await MonitorService.getJobLogs(input);
    const logsCount = await MonitorService.getJobLogsCount(input);
    params.successCallback(allJobLogs, logsCount);
    dispatch(monitorActions.loadedAllJobLogs(allJobLogs));
  } catch (err) {
    dispatch(monitorActions.rejectedActions({ ...err, name: "jobLogs" }));
    params.failCallback();
  }
};

export const getAllJobConfigs = () => async (dispatch) => {
  dispatch(monitorActions.onLoading("jobConfigs"));
  try {
    const allJobConfigs = await MonitorService.getJobConfigs();
    dispatch(monitorActions.loadedAllJobConfigs(allJobConfigs));
  } catch (err) {
    dispatch(monitorActions.rejectedActions({ ...err, name: "jobConfigs" }));
  }
};

export const getJobConfigById = (id) => async (dispatch) => {
  dispatch(monitorActions.onLoading("jobConfig"));
  try {
    const jobConfig = await MonitorService.getJobConfig(id);
    dispatch(monitorActions.loadedJobConfig(jobConfig));
  } catch (err) {
    dispatch(monitorActions.rejectedActions({ ...err, name: "jobConfig" }));
  }
};

export const postTriggerJob =
  (id, data_date, closeHandler, api) => async (dispatch) => {
    dispatch(monitorActions.onLoading("trigger"));
    try {
      // await P'Knot to fix
      await MonitorService.triggerJob(id, data_date);
      dispatch(monitorActions.loadedNewJobLog());
      setTimeout(async () => {
        await api.refreshServerSide({ route: [], purge: true });
      }, [500]);

      if (closeHandler) {
        closeHandler();
      }
    } catch (err) {
      dispatch(monitorActions.rejectedActions({ ...err, name: "trigger" }));
    }
  };
