import { v4 as uuidv4 } from "uuid";

export const departmentType = [
  {
    id: uuidv4(),
    name: "กรรมการผู้จัดการ",
    value: "กรรมการผู้จัดการ",
  },
  {
    id: uuidv4(),
    name: "ตรวจสอบภายใน",
    value: "ตรวจสอบภายใน",
  },
  {
    id: uuidv4(),
    name: "จัดส่ง",
    value: "จัดส่ง",
  },
  {
    id: uuidv4(),
    name: "บัญชี (HO)",
    value: "บัญชี (HO)",
  },
  {
    id: uuidv4(),
    name: "บัญชี (SO)",
    value: "บัญชี (SO)",
  },
  {
    id: uuidv4(),
    name: "บุคคล/ธุรการ (HO)",
    value: "บุคคล/ธุรการ (HO)",
  },
  {
    id: uuidv4(),
    name: "บุคคล/ธุรการ (SO)",
    value: "บุคคล/ธุรการ (SO)",
  },
  {
    id: uuidv4(),
    name: "ควบคุมต้นทุน",
    value: "ควบคุมต้นทุน",
  },
  {
    id: uuidv4(),
    name: "วิศวกรรม",
    value: "วิศวกรรม",
  },

  {
    id: uuidv4(),
    name: "CSR",
    value: "CSR",
  },
  {
    id: uuidv4(),
    name: "โรงโม่หินปูน/สิ่งแวดล้อม/ความปลอดภัย",
    value: "โรงโม่หินปูน/สิ่งแวดล้อม/ความปลอดภัย",
  },

  {
    id: uuidv4(),
    name: "หน่วยงานธุรกิจถ่านหิน",
    value: "หน่วยงานธุรกิจถ่านหิน",
  },
  {
    id: uuidv4(),
    name: "การตลาด HO",
    value: "การตลาด HO",
  },
  {
    id: uuidv4(),
    name: "จัดซื้อ",
    value: "จัดซื้อ",
  },
  {
    id: uuidv4(),
    name: "กฎหมาย",
    value: "กฎหมาย",
  },
  {
    id: uuidv4(),
    name: "สโตร์",
    value: "สโตร์",
  },
  {
    id: uuidv4(),
    name: "ผลิต",
    value: "ผลิต",
  },
  {
    id: uuidv4(),
    name: "ซ่อมบำรุง-ไฟฟ้า",
    value: "ซ่อมบำรุง-ไฟฟ้า",
  },
  {
    id: uuidv4(),
    name: "ซ่อมบำรุง-เครื่องกล",
    value: "ซ่อมบำรุง-เครื่องกล",
  },
  {
    id: uuidv4(),
    name: "ประกันคุณภาพและจัดหาวัตถุดิบ",
    value: "ประกันคุณภาพและจัดหาวัตถุดิบ",
  },
  {
    id: uuidv4(),
    name: "กรรมการผู้จัดการ(MD)",
    value: "กรรมการผู้จัดการ(MD)",
  },
  {
    id: uuidv4(),
    name: "ฝ่ายคอมพิวเตอร์ SO",
    value: "ฝ่ายคอมพิวเตอร์ SO",
  },
  {
    id: uuidv4(),
    name: "ฝ่ายคอมพิวเตอร์ HO",
    value: "ฝ่ายคอมพิวเตอร์ HO",
  },
  {
    id: uuidv4(),
    name: "ดูแลระบบ",
    value: "ดูแลระบบ",
  },
];

export const positionType = [
  {
    id: uuidv4(),
    name: "กรรมการผู้จัดการ",
    value: "กรรมการผู้จัดการ",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการทั่วไป",
    value: "ผู้จัดการทั่วไป",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยกรรมการผู้จัดการ โรงงาน",
    value: "ผู้ช่วยกรรมการผู้จัดการ โรงงาน",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยกรรมการผู้จัดการ",
    value: "ผู้ช่วยกรรมการผู้จัดการ",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายตรวจสอบภายใน",
    value: "ผู้จัดการฝ่ายตรวจสอบภายใน",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายจัดส่ง",
    value: "ผู้จัดการฝ่ายจัดส่ง",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการฝ่ายบัญชี(HO)",
    value: "ผู้ช่วยผู้จัดการฝ่ายบัญชี(HO)",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายบัญชี",
    value: "ผู้จัดการฝ่ายบัญชี",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการฝ่ายบัญชี(SO)",
    value: "ผู้ช่วยผู้จัดการฝ่ายบัญชี(SO)",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายควบคุมต้นทุน",
    value: "ผู้จัดการฝ่ายควบคุมต้นทุน",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายวิศวกรรม",
    value: "ผู้จัดการฝ่ายวิศวกรรม",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายบุคคล",
    value: "ผู้จัดการฝ่ายบุคคล",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายบุคคล/ธุรการ",
    value: "ผู้จัดการฝ่ายบุคคล/ธุรการ",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่าย CSR",
    value: "ผู้จัดการฝ่าย CSR",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายโรงโม่หินปูน/สิ่งแวดล้อม/ควาดปลอดภัย",
    value: "ผู้จัดการฝ่ายโรงโม่หินปูน/สิ่งแวดล้อม/ควาดปลอดภัย",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายต่างประเทศ",
    value: "ผู้จัดการฝ่ายต่างประเทศ",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการฝ่ายบัญชี",
    value: "ผู้ช่วยผู้จัดการฝ่ายบัญชี",
  },
  {
    id: uuidv4(),
    name: "เจ้าหน้าที่ฝ่ายการเงินโครงการ อาวุโส",
    value: "เจ้าหน้าที่ฝ่ายการเงินโครงการ อาวุโส",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายโครงการ",
    value: "ผู้จัดการฝ่ายโครงการ",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายจัดซื้อ",
    value: "ผู้จัดการฝ่ายจัดซื้อ",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการฝ่ายจัดซื้อ",
    value: "ผู้ช่วยผู้จัดการฝ่ายจัดซื้อ",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายกฎหมาย",
    value: "ผู้จัดการฝ่ายกฎหมาย",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายสโตร์",
    value: "ผู้จัดการฝ่ายสโตร์",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการฝ่ายผลิต",
    value: "ผู้ช่วยผู้จัดการฝ่ายผลิต",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายผลิต",
    value: "ผู้จัดการฝ่ายผลิต",
  },
  {
    id: uuidv4(),
    name: "ธุรการผ่ายผลิต",
    value: "ธุรการผ่ายผลิต",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายไฟฟ้า",
    value: "ผู้จัดการฝ่ายไฟฟ้า",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการฝ่ายซ่อมบำรุง-เครื่องกล",
    value: "ผู้ช่วยผู้จัดการฝ่ายซ่อมบำรุง-เครื่องกล",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายซ่อมบำรุง-เครื่องกล",
    value: "ผู้จัดการฝ่ายซ่อมบำรุง-เครื่องกล",
  },
  {
    id: uuidv4(),
    name: "เจ้าหน้าที่ธุรการฝ่ายซ่อมบำรุงขเครื่องกล",
    value: "เจ้าหน้าที่ธุรการฝ่ายซ่อมบำรุงขเครื่องกล",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายประกันคุณภาพและจัดหาวัตถุดิบ",
    value: "ผู้จัดการฝ่ายประกันคุณภาพและจัดหาวัตถุดิบ",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการฝ่ายประกันคุณภาพ (วัตถุดิบ)",
    value: "ผู้ช่วยผู้จัดการฝ่ายประกันคุณภาพ (วัตถุดิบ)",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการฝ่ายประกันคุณภาพ",
    value: "ผู้ช่วยผู้จัดการฝ่ายประกันคุณภาพ",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการ/เลขานุการกรรมการผู้จัดการ",
    value: "ผู้ช่วยผู้จัดการ/เลขานุการกรรมการผู้จัดการ",
  },
  {
    id: uuidv4(),
    name: "ผู้จัดการฝ่ายขาย",
    value: "ผู้จัดการฝ่ายขาย",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการฝ่ายขาย",
    value: "ผู้ช่วยผู้จัดการฝ่ายขาย",
  },
  {
    id: uuidv4(),
    name: "เจ้าหน้าที่สนับสนุนงานขาย อาวุโส",
    value: "เจ้าหน้าที่สนับสนุนงานขาย อาวุโส",
  },
  {
    id: uuidv4(),
    name: "ผู้ช่วยผู้จัดการเทคนิคบริการ",
    value: "ผู้ช่วยผู้จัดการเทคนิคบริการ",
  },
  {
    id: uuidv4(),
    name: "ผู้แทนขาย อาวุโส",
    value: "ผู้แทนขาย อาวุโส",
  },
  {
    id: uuidv4(),
    name: "ผู้แทนขาย",
    value: "ผู้แทนขาย",
  },
  {
    id: uuidv4(),
    name: "เจ้าหน้าที่ฝ่ายคอมพิวเตอร์ อาวุโส",
    value: "เจ้าหน้าที่ฝ่ายคอมพิวเตอร์ อาวุโส",
  },
  {
    id: uuidv4(),
    name: "ผู้ดูแลระบบ",
    value: "ผู้ดูแลระบบ",
  },
];
