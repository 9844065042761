import { Box, IconButton, useMediaQuery } from "@mui/material";
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DesktopLayout from "../../components/Layout/DesktopLayout";
import MobileLayout from "../../components/Layout/MobileLayout";
import StyledButton from "../../components/Styled/StyledButton";
import StyledStatus from "../../components/Styled/StyledStatus";
import StyledTab from "../../components/Styled/StyledTab";
import ActionBar from "../../components/UI/ActionBar";
import AgGrid from "../../components/UI/AgGrid";
import TriggerModal from "../../components/UI/TriggerModal";
import { monitorActions } from "../../features/Monitor/monitor-slice";
import { useNavbar } from "../../hooks/use-navbar";
import DetailCellRenderer from "./DetailCellRenderer";
import RefreshIcon from "@mui/icons-material/Refresh";
import MonitorService from "../../services/Monitor";
import CustomDate from "../../components/UI/CustomDate";
import { filterParams } from "../../utils/data-transformer";
import { useAuth } from "../../hooks/use-auth";

const JobMonitor = () => {
  const { user } = useAuth();
  const isCreatable = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("MONITOR__JOB__CREATE") ||
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const { pathname } = useLocation();
  const { isLoading } = useSelector((state) => state.monitor);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mobile } = useNavbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  const gridRef = useRef();
  const [open, setOpen] = useState(false);

  const columnDefs = [
    {
      field: "status",
      headerName: "Status",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        innerRenderer: (params) => <StyledStatus status={params.value} />,
      },
      cellStyle: {
        alignItems: "center",
      },
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["SUCCESS", "RUNNING", "FAILED"],
      },
    },
    {
      field: "job_id",
      headerName: "Job ID",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["1", "2", "3"],
      },
    },
    {
      field: "job_name",
      headerName: "Job Name",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["sales", "ap", "ar"],
      },
    },

    {
      field: "job_description",
      headerName: "Description",
      filter: "agTextColumnFilter",
    },
    {
      field: "data_date",
      headerName: "Data Date",
      filter: "agTextColumnFilter",
    },
    {
      field: "data_start_date",
      headerName: "Start Date",
      sort: "desc",
      filter: "agDateColumnFilter",
      filterParams: filterParams("Datetime"),
      width: 240,
    },
    {
      field: "data_end_date",
      headerName: "End Date",
      filter: "agDateColumnFilter",
      filterParams: filterParams("Datetime"),
      width: 240,
    },
    {
      field: "record_count",
      headerName: "Record Count",
      filter: "agNumberColumnFilter",
    },
    {
      field: "run_type",
      headerName: "Run Type",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["schedule", "manual"],
      },
    },
  ];

  const tabs = [
    {
      label: t("monitor.jobLog"),
      path: "/monitor",
    },
    {
      label: t("monitor.jobConfig"),
      path: "/monitor/config",
    },
  ];

  const breadcrumbs = [
    {
      name: t("monitor.index"),
    },
    { name: t("monitor.jobLog") },
  ];

  const buttons = [
    isCreatable && {
      title: t("button.trigger"),
      type: "button",
      variant: "contained",
      onClick: () => setOpen(true),
    },
  ];

  useEffect(() => {
    mobile.setMobileTitle(t("monitor.jobLog"));
    return () => {
      mobile.setMobileTitle("");
      dispatch(monitorActions.resetJobLogs());
    };
  }, [dispatch, mobile, t]);

  const refreshHandler = () => {
    gridRef.current.api.refreshServerSide({ route: [], purge: true });
  };

  const datasource = {
    async getRows(params) {
      try {
        const request = params.request;
        const allJobLogs = await MonitorService.getJobLogs({
          startRow: request.startRow,
          endRow: request.endRow,
          filterModel: request.filterModel,
          sortModel: request.sortModel,
        });
        const logsCount = await MonitorService.getJobLogsCount({
          startRow: request.startRow,
          endRow: request.endRow,
          filterModel: request.filterModel,
          sortModel: request.sortModel,
        });
        params.successCallback(allJobLogs, logsCount);
      } catch (err) {
        params.failCallback();
      }
    },
  };

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(datasource);
  };

  const detailCellRenderer = useCallback(
    (data, node, api) => DetailCellRenderer({ data, node, api }),
    []
  );

  const closeHandler = () => {
    setOpen(false);
  };

  const components = useMemo(() => {
    return {
      agDateInput: CustomDate,
    };
  }, []);

  return (
    <>
      {!isMobile && (
        <DesktopLayout isSidebarOpen pathname="/monitor">
          <ActionBar
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isLoading={isLoading.jobLogs}
            isSidebarOpen
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <StyledTab tabs={tabs} currentTab={pathname} />
            <IconButton aria-label="refresh" onClick={refreshHandler}>
              <RefreshIcon />
            </IconButton>
          </Box>

          <AgGrid
            ref={gridRef}
            columnDefs={columnDefs}
            height="70vh"
            detailRowAutoHeight={true}
            masterDetail={true}
            detailCellRenderer={({ data, node }) =>
              detailCellRenderer(data, node, gridRef?.current?.api)
            }
            components={components}
            onGridReady={onGridReady}
            isServer
          />
          <TriggerModal
            open={open}
            closeHandler={closeHandler}
            api={gridRef?.current?.api}
          />
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout pathname="/monitor">
          <Box sx={{ width: "100%", mt: 2 }}>
            <StyledTab tabs={tabs} centered={isMobile} currentTab={pathname} />
            <AgGrid
              ref={gridRef}
              columnDefs={columnDefs}
              height="70vh"
              detailRowHeight={310}
              masterDetail={true}
              detailCellRenderer={({ data, node }) =>
                detailCellRenderer(data, node, gridRef?.current?.api)
              }
              components={components}
              onGridReady={onGridReady}
              isMobile
              isServer
            />
            {isCreatable && (
              <StyledButton
                title="Trigger"
                variant="contained"
                fullWidth
                onClick={() => setOpen(true)}
              />
            )}
            <TriggerModal
              open={open}
              closeHandler={closeHandler}
              api={gridRef?.current?.api}
            />
          </Box>
        </MobileLayout>
      )}
    </>
  );
};

export default JobMonitor;
