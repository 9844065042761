import { Box } from "@mui/material";
import React from "react";
import StyledButton from "../Styled/StyledButton";
import StyledModal from "../Styled/StyledModal";

const DeleteDataModal = ({
  confirmationRemoveButton,
  setConfirmationRemoveButton,
  closeConfirmationRemoveButton,
  deleteButtonOnclick,
}) => {
  return (
    <StyledModal
      title={"ยืนยันการลบข้อมูล?"}
      action={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
            width: 220,
          }}
        >
          <StyledButton
            title="ยกเลิก"
            variant="outlined"
            onClick={() => setConfirmationRemoveButton(false)}
          />
          <StyledButton
            title="ยืนยัน"
            variant="contained"
            onClick={deleteButtonOnclick}
          />
        </Box>
      }
      open={confirmationRemoveButton}
      closeHandler={closeConfirmationRemoveButton}
    />
  );
};

export default DeleteDataModal;
