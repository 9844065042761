import React from "react";
import UserAccountForm from "../../../components/Form/UserAccount";
import StyledButton from "../../../components/Styled/StyledButton";

const AccountTab = ({
  control,
  errors,
  setValue,
  getValues,
  trigger,
  viewOnly,
  admin,
  option,
  onTriggerRevalidate,
  setting,
  isMobile,
}) => {
  return (
    <>
      <UserAccountForm
        control={control}
        errors={errors}
        setValue={setValue}
        trigger={trigger}
        viewOnly={viewOnly}
        option={option}
        admin={admin}
        getValues={getValues}
        setting={setting}
      />
      {isMobile && (
        <StyledButton
          fullWidth
          title="ดำเนินต่อ"
          variant="contained"
          onClick={onTriggerRevalidate}
        />
      )}
    </>
  );
};

export default AccountTab;
