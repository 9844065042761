import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import StyledDownloadButton from "../Styled/StyledDownloadButton";
import { useSelector } from "react-redux";

const StyledIconMenuButton = ({
  headers,
  setConfirmation,
  setOpenEdit,
  setOpenCopy,
  disabled,
  options,
  onSelect,
}) => {
  const { selectedTemplate } = useSelector((state) => state.template);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editDataHandler = () => {
    setOpenEdit(true);
    handleClose();
  };

  const copyTemplate = () => {
    setOpenCopy(true);
    handleClose();
  };

  const deleteTemplateHandler = () => {
    setConfirmation(true);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options ? (
          options.map((option, index) => (
            <MenuItem
              onClick={(e) => {
                onSelect(e);
                handleClose();
              }}
              disableRipple
              key={index}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          ))
        ) : (
          <>
            {disabled.download ? (
              <MenuItem disabled={disabled.download} disableRipple>
                ดาวโหลด Template
              </MenuItem>
            ) : (
              <StyledDownloadButton
                filename={`Template-${selectedTemplate.name}.csv`}
                data={[]}
                headers={headers}
                onClick={handleClose}
                template
              >
                ดาวโหลด Template
              </StyledDownloadButton>
            )}
            <MenuItem
              disabled={disabled.copy}
              onClick={copyTemplate}
              disableRipple
            >
              คัดลอกรายงาน
            </MenuItem>
            <MenuItem
              disabled={disabled.edit}
              onClick={editDataHandler}
              disableRipple
            >
              แก้ไขข้อมูล
            </MenuItem>
            <MenuItem
              disabled={disabled.delete}
              onClick={deleteTemplateHandler}
              disableRipple
            >
              ลบรายงาน
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};

export default StyledIconMenuButton;
