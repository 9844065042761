import { gql } from "graphql-request";

export const GET_ALL_NAVISION = gql`
  query Navision_report {
    navision_report {
      report_type
      updated_at
      data_date
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query Customers {
    customers {
      customer_name
      customer_no
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query Customer($customerId: ID!) {
    customer(id: $customerId) {
      customer_no
      customer_name
      address
      post_code
      address2
      city
      county
      branch_code
      country_code
      location_code
      no_ship_address
      search_name
      email
      contact
      phone_no
      fax_no
      tax_id
      sales_person_code
      blocked
      last_date_modified
      remark_sale
      remark_acc
      clean_credit
      others
      bank_guarantee
      no_of_credit_document
      credit_limt_lcy
      outstanding_logistic_charge
      outstanding_loading_charge
      outstanding_web_sales
      outstanding_online_payment
      remaining_credit
      outstanding_order_lcy
      shipped_not_invoiced_lcy
      not_due
      overdue_1_30_days
      overdue_31_60_days
      overdue_61_90_days
      overdue_91_180_days
      overdue_180_days
      balance_lcy
      provisional_balance_lcy
      hide_cn_lcy
      load_timestamp
      data_date
    }
  }
`;

export const GET_SALES = gql`
  query SalesFromDate($startDate: DateTime!, $endDate: DateTime!) {
    salesFromDate(startDate: $startDate, endDate: $endDate) {
      id
      customer_name
      customer_no
      data_date
      load_timestamp
      product
      qty_debit_note
      qty_net
      qty_return
      qty_shipped
      region
      salesperson
    }
  }
`;

export const GET_AR_LIST = gql`
  query Ar_list($dataDate: DateTime) {
    ar_list(dataDate: $dataDate) {
      data_date
      load_timestamp
      overdue_365_days
      overdue_181_365_days
      overdue_91_180_days
      overdue_61_90_days
      overdue_1_30_days
      overdue_31_60_days
      not_due
      balance
      credit_term
      customer_name
      customer_no
      id
    }
  }
`;

export const GET_AP_LIST = gql`
  query Ap_list($dataDate: DateTime) {
    ap_list(dataDate: $dataDate) {
      id
      vendor_no
      vendor_name
      credit_term
      not_due
      balance
      overdue_1_30_days
      overdue_31_60_days
      overdue_61_90_days
      overdue_91_180_days
      overdue_365_days
      load_timestamp
      overdue_181_365_days
      data_date
    }
  }
`;
