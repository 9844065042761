import { Box, Button, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import MobileLayout from "../../../components/Layout/MobileLayout";
import ActionBar from "../../../components/UI/ActionBar";
import CustomerUI from "../../../components/UI/CustomerUI";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerById } from "../../../features/Navision/navision-actions";
import { useNavbar } from "../../../hooks/use-navbar";
import { navisionActions } from "../../../features/Navision/navision-slice";
import LandscapeLayout from "../../../components/Layout/LandscapeLayout";
import { formatDate } from "../../../utils/date-converter";
import StyledDownloadButton from "../../../components/Styled/StyledDownloadButton";
import { exportAsImage } from "../../../utils/exportAsImage";

const CustomerReportDetail = () => {
  const [blob, setBlob] = useState();
  const exportRef = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { mobile } = useNavbar();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  const { customer, isLoading } = useSelector((state) => state.navision);
  const { selectedCustomer } = customer;

  const breadcrumbs = [
    {
      name: t("report.index"),
      href: "/report",
    },
    {
      name: t("report.navision.index"),
      href: "/report/navision",
    },
    {
      name: t("report.navision.customer"),
      href: "/report/navision/customer",
    },
    {
      name: selectedCustomer.customer_name,
    },
  ];

  const headers = [
    {
      label: "No",
      key: "customer_no",
    },
    { label: "Search Name", key: "search_name " },
    { label: "Name", key: "customer_name" },
    { label: "E-mail", key: "email" },
    { label: "Address", key: "address" },
    { label: "Contact", key: "contact" },
    { label: "Address 2", key: "address2" },
    { label: "TextPhone No.", key: "fax_no" },
    { label: "Post Code/City", key: "post_code" },
    { label: "Phone No.", key: "phone_no" },
    { label: "County/Country Code", key: "county" },
    { label: "Tax Identification", key: "tax_id" },
    { label: "Branch Code", key: "branch_code" },
    { label: "Salesperson Code", key: "sales_person_code" },
    { label: "Location Code", key: "location_code" },
    { label: "Blocked", key: "blocked" },
    { label: "No.Of Ship-to Address", key: "no_ship_address" },
    {
      label: "Last Date Modification",
      key: "last_date_modified",
    },
    { label: "Hide", key: "" },
    { label: "Remark(Sale)", key: "remark_sale" },
    { label: "Remark(ACC)", key: "remark_acc" },
    { label: "Clean credit", key: "clean_credit" },
    {
      label: "Outstanding Order",
      key: "outstanding_order_lcy",
    },
    {
      label: "Bank Guarantee",
      key: "bank_guarantee",
    },
    {
      label: "Shipped Not Invoiced",
      key: "shipped_not_invoiced_lcy",
    },
    { label: "Others", key: "other" },
    { label: "Not Due", key: "not_due" },
    {
      label: "Nop. of Credit doc",
      key: "no_of_credit_document",
    },
    {
      label: "Overdue 1-30 Days",
      key: "overdue_1_30_days",
    },
    {
      label: "Credit Limit",
      key: "credit_limt_lcy",
    },
    {
      label: "Overdue 31-60 Days",
      key: "overdue_31_60_days",
    },
    {
      label: "Outstanding Logistic",
      key: "outstanding_logistic_charge",
    },
    {
      label: "Overdue 61-90 Days",
      key: "overdue_61_90_days",
    },
    {
      label: "Outstanding Loading",
      key: "outstanding_loading_charge",
    },
    {
      label: "Overdue 91-180 Days",
      key: "overdue_91_180_days",
    },
    {
      label: "Outstanding Web",
      key: "outstanding_web_sales",
    },
    {
      label: "Overdue > 180 Days",
      key: "overdue_180_days",
    },
    {
      label: "Outstanding Online",
      key: "outstanding_online_payment",
    },
    { label: "Balance(LCY)", key: "balance_lcy" },
    {
      label: "Remaining Credit",
      key: "remaining_credit",
    },
    {
      label: "Provisional Balance",
      key: "provisional_balance_lcy",
    },
    { label: "Hide CN(LCY)", key: "hide_cn_lcy" },
  ];

  const toLocaleHandle = (data) => {
    if (data) {
      const newData = parseFloat(data);
      return newData.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return data;
  };

  const formatSelectedCustomer = {
    ...selectedCustomer,
    last_date_modified: formatDate(selectedCustomer.last_date_modified),
    clean_credit: toLocaleHandle(selectedCustomer.clean_credit),
    outstanding_order_lcy: toLocaleHandle(
      selectedCustomer.outstanding_order_lcy
    ),
    bank_guarantee: toLocaleHandle(selectedCustomer.bank_guarantee),
    shipped_not_invoiced_lcy: toLocaleHandle(
      selectedCustomer.shipped_not_invoiced_lcy
    ),
    others: toLocaleHandle(selectedCustomer.others),
    not_due: toLocaleHandle(selectedCustomer.not_due),
    overdue_1_30_days: toLocaleHandle(selectedCustomer.overdue_1_30_days),
    credit_limt_lcy: toLocaleHandle(selectedCustomer.credit_limt_lcy),
    overdue_31_60_days: toLocaleHandle(selectedCustomer.overdue_31_60_days),
    outstanding_logistic_charge: toLocaleHandle(
      selectedCustomer.outstanding_logistic_charge
    ),
    overdue_61_90_days: toLocaleHandle(selectedCustomer.overdue_61_90_days),
    outstanding_loading_charge: toLocaleHandle(
      selectedCustomer.outstanding_loading_charge
    ),
    overdue_91_180_days: toLocaleHandle(selectedCustomer.overdue_91_180_days),
    outstanding_web_sales: toLocaleHandle(
      selectedCustomer.outstanding_web_sales
    ),
    overdue_180_days: toLocaleHandle(selectedCustomer.overdue_180_days),
    outstanding_online_payment: toLocaleHandle(
      selectedCustomer.outstanding_online_payment
    ),
    balance_lcy: toLocaleHandle(selectedCustomer.balance_lcy),
    remaining_credit: toLocaleHandle(selectedCustomer.remaining_credit),
    provisional_balance_lcy: toLocaleHandle(
      selectedCustomer.provisional_balance_lcy
    ),
    hide_cn_lcy: toLocaleHandle(selectedCustomer.hide_cn_lcy),
  };

  const buttons = [
    {
      title: t("button.export"),
      type: "menuButton",
      variant: "outlined",
      csvFilename: `รายงานลูกค้า-${selectedCustomer.customer_name}.csv`,
      headers: headers,
      data: [formatSelectedCustomer],
    },
    {
      title: "ดูรูปเต็ม",
      type: "button",
      variant: "contained",
      href: blob && blob,
      target: "_blank",
      rel: "noreferrer",
    },
  ];

  useEffect(() => {
    mobile.setMobileTitle(id);
    return () => {
      mobile.setMobileTitle("");
    };
  }, [id, mobile]);

  useEffect(() => {
    const input = {
      customerId: id,
    };
    dispatch(getCustomerById(input));
    return () => {
      dispatch(navisionActions.resetSelectedCustomer());
    };
  }, [id, dispatch]);

  const isLandscape = useMediaQuery(
    "(max-device-width: 920px) and (orientation: landscape)",
    {
      noSsr: true,
    }
  );

  useEffect(() => {
    if (selectedCustomer.customer_no) {
      exportAsImage(exportRef.current, setBlob);
    }
  }, [selectedCustomer.customer_no]);

  return (
    <>
      {!isMobile && !isLandscape && (
        <DesktopLayout isSidebarOpen isCustomer>
          <ActionBar
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isLoading={isLoading.customer.selectedCustomer}
            isSidebarOpen
          />
          {!isLoading.customer.selectedCustomer && (
            <Box
              ref={exportRef}
              sx={{
                overflow: "scroll",
              }}
            >
              <CustomerUI customerInfo={customer.selectedCustomer} />
            </Box>
          )}
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout
          isLoading={isLoading.customer.selectedCustomer}
          isCustomer
        >
          {!isLoading.customer.selectedCustomer && (
            <>
              <Box
                ref={exportRef}
                sx={{
                  overflow: "scroll",
                  borderRadius: "8px",
                  my: 2,
                }}
              >
                <CustomerUI customerInfo={customer.selectedCustomer} isMobile />
              </Box>
              <Box
                sx={{
                  p: "0 24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <StyledDownloadButton
                  filename={`รายงานลูกค้า-${selectedCustomer.customer_name}.csv`}
                  data={[formatSelectedCustomer]}
                  headers={headers}
                  customer="customer"
                >
                  {t("button.export")}
                </StyledDownloadButton>
                {blob && (
                  <Button
                    fullWidth
                    variant="contained"
                    href={blob}
                    target="_blank"
                    rel="noreferrer"
                  >
                    ดูรูปภาพ
                  </Button>
                )}
              </Box>
            </>
          )}
        </MobileLayout>
      )}
      {!isMobile && isLandscape && (
        <LandscapeLayout isCustomer>
          <ActionBar
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isLoading={isLoading.customer.selectedCustomer}
            isLandscape
          />
          {!isLoading.customer.selectedCustomer && (
            <Box
              ref={exportRef}
              sx={{
                overflow: "scroll",
                borderRadius: "8px",
                my: 2,
              }}
            >
              <CustomerUI customerInfo={customer.selectedCustomer} isMobile />
            </Box>
          )}
        </LandscapeLayout>
      )}
    </>
  );
};

export default CustomerReportDetail;
