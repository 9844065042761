import UserService from "../../../services/User";
import { rbacActions } from "./rbac-slice";
import { formatPriviledgePayload } from "../../../utils/data-transformer";

export const getAllRoles = (enqueueSnackbar) => async (dispatch) => {
  dispatch(rbacActions.onLoading("allRoles"));
  try {
    const allRoles = await UserService.getAllRoles();
    dispatch(rbacActions.loadedAllRoles(allRoles));
  } catch (err) {
    dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const getRole = (roleId, enqueueSnackbar) => async (dispatch) => {
  dispatch(rbacActions.onLoading("role"));
  try {
    const role = await UserService.getRole(roleId);
    dispatch(rbacActions.loadedRole(role));
  } catch (err) {
    dispatch(rbacActions.rejectedActions({ ...err, name: "role" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const postNewRole =
  (createRoleInput, navigate, enqueueSnackbar) => async (dispatch) => {
    dispatch(rbacActions.onLoading("role"));
    try {
      const insertedRole = await UserService.postNewRole(createRoleInput);
      dispatch(rbacActions.appendedNewRole(insertedRole));
      enqueueSnackbar("เพิ่มสิทธิ์สำเร็จ", {
        variant: "success",
      });
      navigate("/user/rbac");
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "role" }));
      enqueueSnackbar("เพิ่มสิทธิ์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const setPriviledgeList = (priviledgeList) => async (dispatch) => {
  dispatch(rbacActions.onLoading("priviledgeList"));
  dispatch(rbacActions.loadedPriviledgeList(priviledgeList));
  for (const [, priviledges] of Object.entries(priviledgeList)) {
    priviledges.forEach((priviledge) => {
      const payload = formatPriviledgePayload(priviledge);
      dispatch(rbacActions.loadedPriviledgeToRole(payload));
    });
  }
};

export const removePriviledge = (priviledge) => async (dispatch) => {
  priviledge.forEach((item) => {
    const payload = formatPriviledgePayload(item);
    dispatch(rbacActions.removedPriviledgeFromRole(payload));
  });
};

export const updateRole =
  (updateRoleInput, enqueueSnackbar, navigate, navision, closeShare) =>
  async (dispatch) => {
    dispatch(rbacActions.onLoading("allRoles"));
    try {
      const updatedRole = await UserService.updateRole(updateRoleInput);
      dispatch(rbacActions.updatedRole(updatedRole));
      if (navision) {
        enqueueSnackbar("แชร์รายงานสำเร็จ", {
          variant: "success",
          preventDuplicate: true,
        });
        closeShare();
      } else {
        enqueueSnackbar("แก้ไขสิทธิ์สำเร็จ", {
          variant: "success",
        });
        navigate("/user/rbac");
      }
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
      if (navision) {
        enqueueSnackbar("แชร์รายงานไม่สำเร็จ", {
          variant: "error",
          preventDuplicate: true,
        });
        closeShare();
      } else {
        enqueueSnackbar("แก้ไขสิทธิ์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    }
  };

export const deleteRole =
  (uniqueInput, navigate, enqueueSnackbar) => async (dispatch) => {
    dispatch(rbacActions.onLoading("allRoles"));
    try {
      const deletedRole = await UserService.deleteRole(uniqueInput);
      dispatch(rbacActions.deletedRole(deletedRole));
      enqueueSnackbar("ลบสิทธิ์สำเร็จ", {
        variant: "success",
      });
      navigate("/user/rbac");
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
      enqueueSnackbar("ลบสิทธิ์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
