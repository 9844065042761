import { gql } from "graphql-request";

export const GET_JOB_LOGS = gql`
  query JobLogsAggrid($aggridInput: AnyAggridInput!) {
    jobLogsAggrid(AggridInput: $aggridInput) {
      id
      job_id
      status
      data_date
      data_start_date
      data_end_date
      record_count
      run_type
      job_name
      job_description
      job {
        id
        name
        description
        type
        cron_schedule
        script
        remark
      }
    }
  }
`;

export const GET_JOB_LOGS_COUNT = gql`
  query Query($jobLogsAggridCountAggridInput: AnyAggridInput!) {
    jobLogsAggridCount(AggridInput: $jobLogsAggridCountAggridInput)
  }
`;

export const GET_JOB_LOG_BY_ID = gql`
  query JobLog($jobLogId: Int!) {
    jobLog(id: $jobLogId) {
      id
      job_id
      status
      data_date
      data_start_date
      data_end_date
      record_count
      run_type
    }
  }
`;

export const GET_JOB_CONFIGS = gql`
  query Jobs {
    jobs {
      id
      name
      description
      type
      cron_schedule
      script
      remark
      task_key
    }
  }
`;

export const GET_JOB_CONFIG_BY_ID = gql`
  query Job($jobId: Int!) {
    job(id: $jobId) {
      id
      name
      description
      type
      cron_schedule
      script
      remark
      task_key
    }
  }
`;
