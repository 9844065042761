import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const CustomMonth = forwardRef((props, ref) => {
  const [portal, setPortal] = useState(false);
  const [month, setMonth] = useState(null);
  const refInput = useRef(null);
  const dateRef = useRef(null);

  const onDateChanged = (selectedDates) => {
    setMonth(selectedDates);
    dateRef.current = selectedDates;
    props.onDateChanged();
  };

  useEffect(() => {
    if (refInput.current) {
      setPortal(true);
    }
  }, []);

  useEffect(() => {
    if (portal) {
      var element = document.getElementById("custom-month");
      if (element) element.classList.add("ag-custom-component-popup");
    }
  }, [portal]);

  useImperativeHandle(ref, () => ({
    getDate() {
      return dateRef.current;
    },
    setDate(date) {
      dateRef.current = date;
      setMonth(date);
    },
  }));
  return (
    <DatePicker
      ref={refInput}
      selected={month}
      onChange={onDateChanged}
      dateFormat="yyyy-MM"
      showMonthYearPicker
      portalId="custom-month"
    />
  );
});

export default CustomMonth;
