export const initialState = {
  isLoading: {
    allRoles: false,
    role: false,
    priviledgeList: false,
  },
  allRoles: [],
  priviledgeList: {},
  role: {
    id: "",
    name: "",
    description: "",
    employee_list: [],
    NAVISION: {
      SALES: {
        VIEW: false,
      },
      SALES_PERSON: {
        VIEW: false,
      },
      SALES_REGION: {
        VIEW: false,
      },
      CUSTOMER: {
        VIEW: false,
      },
      AP: {
        VIEW: false,
      },
      AR: {
        VIEW: false,
      },
    },
    TEMPLATE: {
      GENERAL: {
        CREATE: false,
      },
    },
    MONITOR: {
      JOB: {
        view: false,
        CREATE: false,
      },
    },
    CONFIG: {
      RBAC: {
        view: false,
        CREATE: false,
        EDIT: false,
        DELETE: false,
      },
      USER: {
        view: false,
        CREATE: false,
        EDIT: false,
      },
    },
  },
  error: null,
};
