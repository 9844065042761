import { createSlice } from "@reduxjs/toolkit";

const monitorSlice = createSlice({
  name: "monitor",
  initialState: {
    isLoading: {
      jobLogs: false,
      jobConfigs: false,
      jobConfig: false,
      trigger: false,
    },
    jobLogs: [],
    jobConfigs: [],
    jobConfig: {
      id: "",
      name: "",
      description: "",
      type: "",
      cron_schedule: "",
      script: "",
      remark: "",
      task_key: "",
    },
    error: null,
  },
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload] = false;
      state.error = action.payload;
    },
    loadedAllJobLogs(state, action) {
      state.jobLogs = action.payload;
      state.isLoading.jobLogs = false;
    },
    loadedNewJobLog(state, action) {
      // state.jobLogs = [...state.jobConfig];
      state.isLoading.trigger = false;
    },
    loadedAllJobConfigs(state, action) {
      state.jobConfigs = action.payload;
      state.isLoading.jobConfigs = false;
    },
    loadedJobConfig(state, action) {
      state.jobConfig = action.payload;
      state.isLoading.jobConfig = false;
    },
    resetJobLogs(state) {
      state.jobLogs = [];
    },
    resetJobConfigs(state) {
      state.jobConfigs = [];
    },
    resetJobConfig(state) {
      state.jobConfig = {
        id: "",
        name: "",
        description: "",
        type: "",
        cron_schedule: "",
        script: "",
        remark: "",
        task_key: "",
      };
    },
  },
});

export const monitorActions = monitorSlice.actions;

export default monitorSlice.reducer;
