import React from "react";
import AgGrid from "./AgGrid";
import { useTranslation } from "react-i18next";

const UserTable = ({
  gridRef,
  onGridReady,
  rowSelection,
  height,
  searchBox,
  enableCheckbox,
  rowData,
  headerCheckboxSelection,
  enableRangeSelection,
  onRowClicked,
  isMobile,
}) => {
  const { t } = useTranslation();

  const columnDefs = [
    {
      field: "id",
      headerName: t("user.account.employeeId"),
      filter: "agTextColumnFilter",
      checkboxSelection: enableCheckbox,
      headerCheckboxSelection: headerCheckboxSelection,
      sort: "asc",
    },
    {
      field: "name",
      headerName: t("user.account.name"),
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        `${params.data.first_name} ${params.data.last_name}`,
    },
    {
      field: "email",
      headerName: t("user.account.email"),
      filter: "agTextColumnFilter",
    },
    {
      field: "department",
      headerName: t("user.account.department"),
      filter: "agSetColumnFilter",
    },
    {
      field: "position",
      headerName: t("user.account.position"),
      filter: "agTextColumnFilter",
    },
  ];

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={height}
      onGridReady={onGridReady}
      rowSelection={rowSelection}
      onRowClicked={onRowClicked}
      enableRangeSelection={enableRangeSelection}
      searchBox={searchBox}
      rowData={rowData}
      isMobile={isMobile}
    />
  );
};

export default UserTable;
