import * as React from "react";
import Avatar from "@mui/material/Avatar";

function stringAvatar(name) {
  return {
    sx: {
      color: "#5608E1",
      background: "rgba(86, 8, 225, 0.1)",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export default function StyledLetterAvatar({ name }) {
  return <Avatar {...stringAvatar(name)} />;
}
