import { useEffect, useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";

export default function FooterNavbar() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [value, setValue] = useState();
  const navigate = useNavigate();
  const { user } = useAuth();

  let allPermissions = [];

  user?.role_list?.forEach((role) => {
    allPermissions.push(...role.permission_list);
  });

  useEffect(() => {
    if (pathname.includes("report")) {
      setValue(0);
    } else if (pathname.includes("monitor")) {
      setValue(1);
    } else {
      setValue(2);
    }
  }, [pathname]);

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 2 }}
      elevation={10}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          onClick={() => navigate("/report")}
          label={t("report.index")}
          icon={<DescriptionOutlinedIcon fontSize="small" />}
        />
        {(allPermissions.includes("ADMIN__ADMIN__ADMIN") ||
          allPermissions.includes("MONITOR__JOB__VIEW") ||
          allPermissions.includes("MONITOR__JOB__CREATE")) && (
          <BottomNavigationAction
            onClick={() => navigate("/monitor")}
            label={t("monitor.index")}
            icon={<AvTimerOutlinedIcon />}
          />
        )}
        <BottomNavigationAction
          onClick={() => navigate("/user")}
          label={t("user.index")}
          icon={<PersonOutlineIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
}
