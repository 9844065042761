import React, { forwardRef } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const StyledCheckbox = forwardRef(
  ({ sx, value, isDisabled, onChange, label }, ref) => {
    return (
      <FormControlLabel
        ref={ref}
        control={
          <Checkbox
            checked={value}
            value={value}
            disabled={isDisabled}
            onChange={onChange}
            sx={sx}
          />
        }
        label={label}
      />
    );
  }
);

export default StyledCheckbox;
