import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";

const StyledButton = ({
  sx,
  variant,
  title,
  onClick,
  startIcon,
  fullWidth,
  type,
  disabled,
  form,
  rel,
  href,
  target,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  return (
    <Button
      rel={rel}
      href={href}
      target={target}
      disabled={disabled}
      type={type ?? "button"}
      sx={{
        ...sx,
        whiteSpace: "nowrap",
      }}
      size={isMobile ? "large" : "small"}
      variant={variant}
      onClick={onClick}
      startIcon={startIcon}
      fullWidth={fullWidth}
      form={form}
    >
      {title}
    </Button>
  );
};

export default StyledButton;
