import { Box } from "@mui/material";
import React from "react";

const LandscapeLayout = ({ children, template, isCustomer }) => {
  return (
    <>
      <Box
        sx={{
          paddingTop: template ? "20px" : "64px",
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          px: isCustomer ? 0 : "20px",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default LandscapeLayout;
