import "simplebar/dist/simplebar.min.css";
import { forwardRef } from "react";
import { CSVLink } from "react-csv";
import { styled } from "@mui/material/styles";

const DownloadButtonRoot = styled(CSVLink)((props) => ({
  ...(props.template
    ? {
        backgroundColor: "#fff",
        minWidth: "64px",
        textDecoration: "none",
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.5",
        color: "#25282B",
        display: "flex",
        alignItems: "center",
        padding: "6px 1rem",
        "&:hover": {
          textDecoration: "none",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      }
    : props.customer
    ? {
        display: " inline-flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        boxSizing: "border-box",
        backgroundColor: "transparent",
        outline: " 0px",
        margin: " 0px",
        cursor: "pointer",
        userSelect: "none",
        verticalAlign: "middle",
        fontSize: "0.875rem",
        appearance: "none",
        textDecoration: "none",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        width: "100%",
        padding: " 5px 15px",
        borderRadius: "4px",
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        border: "1px solid rgba(28, 80, 38, 0.5)",
        color: "rgb(28, 80, 38)",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "rgba(28, 80, 38, 0.04)",
          border: "1px solid #1C5026",
        },
      }
    : {
        display: " inline-flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        boxSizing: "border-box",
        backgroundColor: "transparent",
        outline: " 0px",
        margin: " 0px",
        cursor: "pointer",
        userSelect: "none",
        verticalAlign: "middle",
        fontSize: "0.875rem",
        appearance: "none",
        textDecoration: "none",
        textTransform: "uppercase",
        minWidth: "64px",
        padding: " 5px 15px",
        borderRadius: "4px",
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        border: "1px solid rgba(28, 80, 38, 0.5)",
        color: "rgb(28, 80, 38)",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "rgba(28, 80, 38, 0.04)",
          border: "1px solid #1C5026",
        },
        whiteSpace: "nowrap",
      }),
}));

const StyledDownloadButton = forwardRef((props, ref) => {
  return <DownloadButtonRoot ref={ref} {...props} />;
});

export default StyledDownloadButton;
