import {
  Box,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import StyledMobileNavbar from "../Styled/StyledMobileNavbar";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useNavbar } from "../../hooks/use-navbar";
import StyledIconMenuButton from "../Styled/StyledIconMenuButton";
import StyledAvatarMenu from "../Styled/StyledAvatarMenu";

const TopMobileNavbar = ({
  isLoading,
  template,
  headers,
  setConfirmation,
  setOpenEdit,
  setOpenCopy,
  disabled,
  updateTime,
  rbac,
  options,
  onSelect,
  pathname,
}) => {
  const { mobile } = useNavbar();
  const navigate = useNavigate();
  return (
    <StyledMobileNavbar>
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={
            pathname
              ? () =>
                  navigate({
                    pathname: pathname,
                  })
              : () => navigate(-1)
          }
        >
          <ArrowBackIosNewIcon fontSize="small" />
        </IconButton>
        <Box textAlign={"center"} alignItems={"center"}>
          <Typography>{mobile.mobileTitle}</Typography>
          <Typography
            sx={{ color: "#52575C" }}
            variant="subtitle2"
            px={2}
            mx={1}
          >
            {updateTime}
          </Typography>
        </Box>
        {template || (rbac && options.length > 0) ? (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <StyledAvatarMenu />
            <StyledIconMenuButton
              headers={headers}
              setConfirmation={setConfirmation}
              setOpenEdit={setOpenEdit}
              setOpenCopy={setOpenCopy}
              disabled={disabled}
              options={options}
              onSelect={onSelect}
            />
          </Box>
        ) : (
          <StyledAvatarMenu />
        )}
      </Toolbar>
      {isLoading && <LinearProgress />}
    </StyledMobileNavbar>
  );
};

export default TopMobileNavbar;
