import { Box, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import MobileLayout from "../../../components/Layout/MobileLayout";
import StyledButton from "../../../components/Styled/StyledButton";
import ActionBar from "../../../components/UI/ActionBar";
import UserTable from "../../../components/UI/UserTable";
import { getAllUsers } from "../../../features/User/Account/account-actions";
import { accountActions } from "../../../features/User/Account/account-slice";
import { useAuth } from "../../../hooks/use-auth";
import { useNavbar } from "../../../hooks/use-navbar";

const UserAccount = () => {
  const { user } = useAuth();
  const isCreatable = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("CONFIG__USER__CREATE") ||
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allUsers, isLoading } = useSelector((state) => state.account);
  const gridRef = useRef();
  const { mobile } = useNavbar();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });

  const breadcrumbs = [
    {
      name: t("user.account.index"),
      href: "/user",
    },
    {
      name: t("user.index"),
    },
  ];

  const buttons = [
    isCreatable && {
      type: "button",
      title: t("button.add"),
      variant: "contained",
      onClick: () => navigate("/user/admin/create"),
    },
  ];

  useEffect(() => {
    mobile.setMobileTitle(t("user.index"));
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t]);

  useEffect(() => {
    dispatch(getAllUsers(enqueueSnackbar));
    return () => {
      dispatch(accountActions.resetAllUsers());
    };
  }, [dispatch, enqueueSnackbar]);

  const onRowClicked = (params) => {
    navigate(`${pathname}/${params.data.id}`);
  };

  return (
    <>
      {!isMobile && (
        <DesktopLayout isSidebarOpen pathname="/user">
          <ActionBar
            ref={gridRef}
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isSidebarOpen
            isLoading={isLoading.allUsers}
          />
          <UserTable
            gridRef={gridRef}
            searchBox
            rowData={allUsers}
            height={730}
            rowSelection="multiple"
            enableRangeSelection={true}
            onRowClicked={onRowClicked}
          />
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout isLoading={isLoading.allUsers} pathname="/user">
          <Box>
            <UserTable
              gridRef={gridRef}
              searchBox
              rowData={allUsers}
              height={600}
              rowSelection="multiple"
              onRowClicked={onRowClicked}
              enableRangeSelection={true}
              isMobile
            />
            <StyledButton
              fullWidth
              title={t("button.add")}
              variant="contained"
              onClick={() => navigate("/user/admin/create")}
            />
          </Box>
        </MobileLayout>
      )}
    </>
  );
};

export default UserAccount;
