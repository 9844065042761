import React, { forwardRef } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const StyledRadioGroup = forwardRef(
  ({ onChange, value, row, radioList, name, disabled }, ref) => {
    return (
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name={name}
        value={value}
        onChange={onChange}
        row={row}
        ref={ref}
      >
        {radioList &&
          radioList.map((item, index) => (
            <FormControlLabel
              value={item.value}
              control={<Radio />}
              label={item.label}
              key={index}
              disabled={disabled}
            />
          ))}
      </RadioGroup>
    );
  }
);

export default StyledRadioGroup;
