import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    disableFocusListener
    disableHoverListener
    disableTouchListener
    placement="bottom"
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "none !important",
    color: "none !important",
    boxShadow: "none !important",
    fontSize: 11,
  },
}));

export default function ToolTipMobile({
  children,
  handleTooltipOpen,
  handleTooltipClose,
  open,
}) {
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <LightTooltip
          onClose={handleTooltipClose}
          open={open}
          title={<React.Fragment>{children}</React.Fragment>}
        >
          <InfoIcon onClick={handleTooltipOpen} />
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
}
