import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { forwardRef, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

const SmallEditButton = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `2px solid ${theme.palette.background.paper}`,
  backgroundColor: theme.palette.primary.main,
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledBadgeAvatar = forwardRef(({ control }, ref) => {
  const { user } = useSelector((state) => state.account);
  const [selectedFile, setSelectedFile] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    let objectUrl;
    setImage(user.img_url);
    if (selectedFile) {
      objectUrl = URL.createObjectURL(selectedFile);
      setImage(objectUrl);
    }
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, user.img_url]);

  return (
    <Badge
      sx={{ mb: 4 }}
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <Controller
          name="img_url"
          control={control}
          render={({ field }) => (
            <SmallEditButton aria-label="edit" component="label">
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => {
                  if (!e.target.files || e.target.files.length === 0) {
                    setSelectedFile(undefined);
                    field.onChange(undefined);
                    return;
                  }
                  setSelectedFile(e.target.files[0]);
                  field.onChange(e.target.files[0]);
                }}
              />
              <EditIcon sx={{ width: 16.5, height: 16.5 }} />
            </SmallEditButton>
          )}
        />
      }
    >
      <Avatar
        alt="user"
        src={image || "/static/user.png"}
        sx={{ width: 128, height: 128 }}
      />
    </Badge>
  );
});

export default StyledBadgeAvatar;
