import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import StyledCheckbox from "../../Styled/StyledCheckbox";
import StyledTab from "../../Styled/StyledTab";
import ConfigPermission from "./Config";
import TemplatePermission from "./Template";
import MonitoringPermission from "./Monitoring";
import { Box } from "@mui/material";

const PermissionForm = ({ control, addable }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const subtab = searchParams.get("subtab");
  const { priviledgeList } = useSelector((state) => state.rbac);

  const [disabledRowIndex] = useState({
    template: [],
    monitor: [],
    config: [],
  });

  const tabs = [
    {
      label: t("report.template.index"),
      path: `${pathname}?step=permission&subtab=template`,
    },
    {
      label: t("monitor.index"),
      path: `${pathname}?step=permission&subtab=monitor`,
    },
    {
      label: t("user.index"),
      path: `${pathname}?step=permission&subtab=config`,
    },
  ];

  const renderStyledCheckBoxes = (params, name) => {
    if (params.value) {
      const formatName =
        `${subtab}.${params.data.functionName}.${name}`.toUpperCase();

      const checkIsDisabled = () => {
        return disabledRowIndex[subtab]?.includes(params.rowIndex);
      };

      return (
        <Controller
          control={control}
          name={formatName}
          render={({ field }) => (
            <StyledCheckbox
              {...field}
              isDisabled={
                pathname.includes("admin") ||
                pathname.includes("config") ||
                checkIsDisabled() ||
                addable
              }
            />
          )}
        />
      );
    }
  };

  const columnDefs = [
    {
      field: "name",
      headerName: "หัวเรื่อง",
      filter: "agTextColumnFilter",
    },
    {
      field: "view",
      headerName: "ดู",
      filter: "agTextColumnFilter",
      cellRenderer: (params) => renderStyledCheckBoxes(params, "view"),
    },
    {
      field: "create",
      headerName: "สร้าง",
      filter: "agTextColumnFilter",
      cellRenderer: (params) => renderStyledCheckBoxes(params, "create"),
    },
    {
      field: "edit",
      headerName: "แก้ไข",
      filter: "agTextColumnFilter",
      cellRenderer: (params) => renderStyledCheckBoxes(params, "edit"),
    },
    {
      field: "delete",
      headerName: "ลบ",
      filter: "agTextColumnFilter",
      cellRenderer: (params) => renderStyledCheckBoxes(params, "delete"),
    },
  ];

  const renderTab = (subtab) => {
    switch (subtab) {
      case "template":
        return (
          <TemplatePermission
            columnDefs={columnDefs}
            priviledgeList={!pathname.includes("rbac") && priviledgeList}
            resource={subtab.toUpperCase()}
          />
        );
      case "monitor":
        return (
          <MonitoringPermission
            columnDefs={columnDefs}
            priviledgeList={!pathname.includes("rbac") && priviledgeList}
            resource={subtab.toUpperCase()}
          />
        );
      case "config":
        return (
          <ConfigPermission
            columnDefs={columnDefs}
            priviledgeList={!pathname.includes("rbac") && priviledgeList}
            resource={subtab.toUpperCase()}
          />
        );
      default:
        return;
    }
  };

  const currentTab =
    pathname + (subtab ? `?step=permission&subtab=${subtab}` : "");

  return (
    <Box sx={{ mt: 2 }}>
      <StyledTab tabs={tabs} currentTab={currentTab} />
      {renderTab(subtab)}
    </Box>
  );
};

export default PermissionForm;
