import {
  alpha,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import StyledButton from "../Styled/StyledButton";
import StyledModal from "../Styled/StyledModal";
import StyledComboBox from "../Styled/StyledComboBox";
import StyledChip from "../Styled/StyledChip";
import StyledSelect from "../Styled/StyledSelect";
import { useAuth } from "../../hooks/use-auth";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllRoles, updateRole } from "../../features/User/Rbac/rbac-action";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import UserService from "../../services/User";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { updateTemplateRole } from "../../features/Template/template-actions";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";

const permissionsOption = [
  {
    id: 1,
    label: "ดู",
    value: "viewer",
  },
  {
    id: 2,
    label: "แก้ไข",
    value: "editor",
  },
  {
    id: 3,
    label: "จัดการ",
    value: "manager",
  },
];

const ShareModal = ({ name, share, closeShare, navision, viewOnly }) => {
  const [navisionPermission, setNavisionPermission] = useState();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { selectedTemplate } = useSelector((state) => state.template);
  const { allRoles } = useSelector((state) => state.rbac);
  const { user } = useAuth();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });

  const admin = user?.role_list?.some((role) =>
    role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      roles: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "roles", // unique name for your Field Array
  });

  useEffect(() => {
    switch (pathname) {
      case "/report/navision/sales": {
        const findRole = allRoles.filter((role) =>
          role.permission_list.includes("NAVISION__SALES__VIEW")
        );
        const formatRole = findRole.map((role) => ({
          id: role.id,
          name: role.name,
          permission: "viewer",
        }));
        setValue("roles", formatRole);
        setNavisionPermission("NAVISION__SALES__VIEW");
        break;
      }
      case "/report/navision/sales-person": {
        const findRole = allRoles.filter((role) =>
          role.permission_list.includes("NAVISION__SALES_PERSON__VIEW")
        );
        const formatRole = findRole.map((role) => ({
          id: role.id,
          name: role.name,
          permission: "viewer",
        }));
        setValue("roles", formatRole);
        setNavisionPermission("NAVISION__SALES_PERSON__VIEW");
        break;
      }
      case "/report/navision/sales-region": {
        const findRole = allRoles.filter((role) =>
          role.permission_list.includes("NAVISION__SALES_REGION__VIEW")
        );
        const formatRole = findRole.map((role) => ({
          id: role.id,
          name: role.name,
          permission: "viewer",
        }));
        setValue("roles", formatRole);
        setNavisionPermission("NAVISION__SALES_REGION__VIEW");
        break;
      }
      case "/report/navision/sales-sum": {
        const findRole = allRoles.filter((role) =>
          role.permission_list.includes("NAVISION__SALES_SUM__VIEW")
        );
        const formatRole = findRole.map((role) => ({
          id: role.id,
          name: role.name,
          permission: "viewer",
        }));
        setValue("roles", formatRole);
        setNavisionPermission("NAVISION__SALES_SUM__VIEW");
        break;
      }
      case "/report/navision/customer": {
        const findRole = allRoles.filter((role) =>
          role.permission_list.includes("NAVISION__CUSTOMER__VIEW")
        );
        const formatRole = findRole.map((role) => ({
          id: role.id,
          name: role.name,
          permission: "viewer",
        }));
        setValue("roles", formatRole);
        setNavisionPermission("NAVISION__CUSTOMER__VIEW");
        break;
      }
      case "/report/navision/ar": {
        const findRole = allRoles.filter((role) =>
          role.permission_list.includes("NAVISION__AR__VIEW")
        );
        const formatRole = findRole.map((role) => ({
          id: role.id,
          name: role.name,
          permission: "viewer",
        }));
        setValue("roles", formatRole);
        setNavisionPermission("NAVISION__AR__VIEW");
        break;
      }
      case "/report/navision/ap": {
        const findRole = allRoles.filter((role) =>
          role.permission_list.includes("NAVISION__AP__VIEW")
        );
        const formatRole = findRole.map((role) => ({
          id: role.id,
          name: role.name,
          permission: "viewer",
        }));
        setValue("roles", formatRole);
        setNavisionPermission("NAVISION__AP__VIEW");
        break;
      }
      default:
        const formatRole = selectedTemplate?.role_permission_list?.map(
          (ele) => ({
            id: ele.role.id,
            name: ele.role.name,
            permission: ele.template_priviledge,
          })
        );
        console.log(formatRole);
        setValue("roles", formatRole);
        break;
    }
  }, [allRoles, pathname, selectedTemplate.role_permission_list, setValue]);

  const onShareSubmit = (data) => {
    if (navision) {
      switch (pathname) {
        case "/report/navision/sales": {
          const findRoles = allRoles.filter((role) =>
            role.permission_list.includes("NAVISION__SALES__VIEW")
          );
          const findRoleIds = findRoles.map((role) => role.id);
          const dataRoleIds = data.roles.map((role) => role.id);
          let intersect = findRoleIds.filter((roleId) =>
            dataRoleIds.includes(roleId)
          );
          let union = [...findRoleIds, ...dataRoleIds];
          let diff = union.filter((x) => !intersect.includes(x));
          diff.forEach(async (id) => {
            let { permission_list } = await UserService.getRole(id);
            let formatPermissionList = [...new Set(permission_list)];
            formatPermissionList.splice(
              permission_list.indexOf("NAVISION__SALES__VIEW"),
              1
            );
            let updateRoleInput = {
              id: id,
              permission_list: formatPermissionList,
            };
            dispatch(
              updateRole(
                updateRoleInput,
                enqueueSnackbar,
                navigate,
                navision,
                closeShare
              )
            );
          });
          break;
        }
        case "/report/navision/sales-person": {
          const findRoles = allRoles.filter((role) =>
            role.permission_list.includes("NAVISION__SALES_PERSON__VIEW")
          );
          const findRoleIds = findRoles.map((role) => role.id);
          const dataRoleIds = data.roles.map((role) => role.id);
          let intersect = findRoleIds.filter((roleId) =>
            dataRoleIds.includes(roleId)
          );
          let union = [...findRoleIds, ...dataRoleIds];
          let diff = union.filter((x) => !intersect.includes(x));
          diff.forEach(async (id) => {
            let { permission_list } = await UserService.getRole(id);
            let formatPermissionList = [...new Set(permission_list)];
            formatPermissionList.splice(
              permission_list.indexOf("NAVISION__SALES_PERSON__VIEW"),
              1
            );
            let updateRoleInput = {
              id: id,
              permission_list: formatPermissionList,
            };
            dispatch(
              updateRole(
                updateRoleInput,
                enqueueSnackbar,
                navigate,
                navision,
                closeShare
              )
            );
          });
          break;
        }
        case "/report/navision/sales-region": {
          const findRoles = allRoles.filter((role) =>
            role.permission_list.includes("NAVISION__SALES_REGION__VIEW")
          );
          const findRoleIds = findRoles.map((role) => role.id);
          const dataRoleIds = data.roles.map((role) => role.id);
          let intersect = findRoleIds.filter((roleId) =>
            dataRoleIds.includes(roleId)
          );
          let union = [...findRoleIds, ...dataRoleIds];
          let diff = union.filter((x) => !intersect.includes(x));
          diff.forEach(async (id) => {
            let { permission_list } = await UserService.getRole(id);
            let formatPermissionList = [...new Set(permission_list)];
            formatPermissionList.splice(
              permission_list.indexOf("NAVISION__SALES_REGION__VIEW"),
              1
            );
            let updateRoleInput = {
              id: id,
              permission_list: formatPermissionList,
            };
            dispatch(
              updateRole(
                updateRoleInput,
                enqueueSnackbar,
                navigate,
                navision,
                closeShare
              )
            );
          });
          break;
        }
        case "/report/navision/sales-sum": {
          const findRoles = allRoles.filter((role) =>
            role.permission_list.includes("NAVISION__SALES_SUM__VIEW")
          );
          const findRoleIds = findRoles.map((role) => role.id);
          const dataRoleIds = data.roles.map((role) => role.id);
          let intersect = findRoleIds.filter((roleId) =>
            dataRoleIds.includes(roleId)
          );
          let union = [...findRoleIds, ...dataRoleIds];
          let diff = union.filter((x) => !intersect.includes(x));
          diff.forEach(async (id) => {
            let { permission_list } = await UserService.getRole(id);
            let formatPermissionList = [...new Set(permission_list)];
            formatPermissionList.splice(
              permission_list.indexOf("NAVISION__SALES_SUM__VIEW"),
              1
            );
            let updateRoleInput = {
              id: id,
              permission_list: formatPermissionList,
            };
            dispatch(
              updateRole(
                updateRoleInput,
                enqueueSnackbar,
                navigate,
                navision,
                closeShare
              )
            );
          });
          break;
        }
        case "/report/navision/customer": {
          const findRoles = allRoles.filter((role) =>
            role.permission_list.includes("NAVISION__CUSTOMER__VIEW")
          );
          const findRoleIds = findRoles.map((role) => role.id);
          const dataRoleIds = data.roles.map((role) => role.id);
          let intersect = findRoleIds.filter((roleId) =>
            dataRoleIds.includes(roleId)
          );
          let union = [...findRoleIds, ...dataRoleIds];
          let diff = union.filter((x) => !intersect.includes(x));
          diff.forEach(async (id) => {
            let { permission_list } = await UserService.getRole(id);
            let formatPermissionList = [...new Set(permission_list)];
            formatPermissionList.splice(
              permission_list.indexOf("NAVISION__CUSTOMER__VIEW"),
              1
            );
            let updateRoleInput = {
              id: id,
              permission_list: formatPermissionList,
            };
            dispatch(
              updateRole(
                updateRoleInput,
                enqueueSnackbar,
                navigate,
                navision,
                closeShare
              )
            );
          });
          break;
        }
        case "/report/navision/ar": {
          const findRoles = allRoles.filter((role) =>
            role.permission_list.includes("NAVISION__AR__VIEW")
          );
          const findRoleIds = findRoles.map((role) => role.id);
          const dataRoleIds = data.roles.map((role) => role.id);
          let intersect = findRoleIds.filter((roleId) =>
            dataRoleIds.includes(roleId)
          );
          let union = [...findRoleIds, ...dataRoleIds];
          let diff = union.filter((x) => !intersect.includes(x));
          diff.forEach(async (id) => {
            let { permission_list } = await UserService.getRole(id);
            let formatPermissionList = [...new Set(permission_list)];
            formatPermissionList.splice(
              permission_list.indexOf("NAVISION__AR__VIEW"),
              1
            );
            let updateRoleInput = {
              id: id,
              permission_list: formatPermissionList,
            };
            dispatch(
              updateRole(
                updateRoleInput,
                enqueueSnackbar,
                navigate,
                navision,
                closeShare
              )
            );
          });
          break;
        }
        case "/report/navision/ap": {
          const findRoles = allRoles.filter((role) =>
            role.permission_list.includes("NAVISION__AP__VIEW")
          );
          const findRoleIds = findRoles.map((role) => role.id);
          const dataRoleIds = data.roles.map((role) => role.id);
          let intersect = findRoleIds.filter((roleId) =>
            dataRoleIds.includes(roleId)
          );
          let union = [...findRoleIds, ...dataRoleIds];
          let diff = union.filter((x) => !intersect.includes(x));
          diff.forEach(async (id) => {
            let { permission_list } = await UserService.getRole(id);
            let formatPermissionList = [...new Set(permission_list)];
            formatPermissionList.splice(
              permission_list.indexOf("NAVISION__AP__VIEW"),
              1
            );
            let updateRoleInput = {
              id: id,
              permission_list: formatPermissionList,
            };
            dispatch(
              updateRole(
                updateRoleInput,
                enqueueSnackbar,
                navigate,
                navision,
                closeShare
              )
            );
          });
          break;
        }
        default:
          break;
      }
      data.roles.forEach(async (role) => {
        let { permission_list } = await UserService.getRole(role.id);
        let updateRoleInput = {
          id: role.id,
          permission_list: [...permission_list, navisionPermission],
        };
        dispatch(
          updateRole(
            updateRoleInput,
            enqueueSnackbar,
            navigate,
            navision,
            closeShare
          )
        );
      });
    } else {
      const formatPayload = data.roles.map((role) => ({
        role_id: role.id,
        template_priviledge: role.permission,
      }));
      dispatch(
        updateTemplateRole(selectedTemplate.id, formatPayload, enqueueSnackbar)
      );
    }
    closeShare();
  };

  const addRoleHandler = (_, newValue) => {
    const fieldName = fields.map((field) => field.name);
    if (newValue && !fieldName.includes(newValue.name)) {
      append({ id: newValue.id, name: newValue.name, permission: "viewer" });
    }
  };

  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  useEffect(() => {
    if (errors.roles) {
      enqueueSnackbar(errors?.roles?.message, {
        variant: "error",
      });
    }
  }, [errors, enqueueSnackbar]);

  return (
    <StyledModal
      title={`แชร์ "${name}"`}
      content={
        <>
          {!viewOnly && (
            <StyledComboBox
              onChange={addRoleHandler}
              options={allRoles
                .filter((filtered) => filtered.name.toUpperCase() !== "ADMIN")
                .map((role) => ({
                  id: role.id,
                  name: role.name,
                }))}
              label="เพิ่มสิทธิ"
            />
          )}

          <Typography sx={{ fontWeight: "bold", my: 1.5 }}>
            สิทธิการเข้าถึง
          </Typography>
          <Box
            sx={{
              display: "flex",
              p: 1,
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
              color: "#52575C",
            }}
          >
            <Typography
              sx={{
                flex: 3,
                fontWeight: "bold",
                color: "inherit",
              }}
            >
              ชื่อสิทธิ
            </Typography>
            <Typography sx={{ flex: 3, fontWeight: "bold", color: "inherit" }}>
              สิทธิ
            </Typography>
            {!viewOnly && <Box sx={{ flex: 1 }} />}
          </Box>
          {fields.map((item, index) => (
            <Box key={item.id}>
              <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                <Box sx={{ flex: 3, overflow: "clip" }}>
                  <Tooltip title={item.name} placement="top">
                    <StyledChip
                      size="small"
                      color="primary"
                      label={item.name}
                    />
                  </Tooltip>
                </Box>
                <Box sx={{ flex: 3 }}>
                  <Controller
                    control={control}
                    name={`roles[${index}].permission`}
                    render={({ field }) => (
                      <StyledSelect
                        options={permissionsOption}
                        {...field}
                        disabled={navision || viewOnly}
                      />
                    )}
                  />
                </Box>
                {!viewOnly && (
                  <Box
                    sx={{ display: "flex", flex: 1, justifyContent: "center" }}
                  >
                    <IconButton
                      onClick={() => remove(index)}
                      aria-label="remove role"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Divider />
            </Box>
          ))}
          {selectedTemplate.created_by && (
            <>
              <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
                เจ้าของ
              </Typography>
              <StyledChip
                size="small"
                color="primary"
                label={
                  selectedTemplate?.created_by?.first_name +
                  " " +
                  selectedTemplate?.created_by?.last_name
                }
              />
            </>
          )}
        </>
      }
      action={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
            gap: isMobile ? 1 : 0,
          }}
        >
          <Box>
            <CopyToClipboard text={window.location.href}>
              <StyledButton
                fullWidth={isMobile}
                startIcon={<LinkOutlinedIcon size="small" />}
                title="คัดลอกลิ้งค์"
                variant="outlined"
              />
            </CopyToClipboard>
          </Box>
          {!isMobile && (admin || !viewOnly) && (
            <Box sx={{ display: "flex", gap: ".5rem" }}>
              <StyledButton
                fullWidth={isMobile}
                onClick={handleSubmit(onShareSubmit)}
                title="บันทึก"
                variant="contained"
              />
            </Box>
          )}
          {isMobile && (admin || !viewOnly) && (
            <StyledButton
              onClick={handleSubmit(onShareSubmit)}
              title="บันทึก"
              variant="contained"
            />
          )}
        </Box>
      }
      open={share}
      closeHandler={closeShare}
      maxWidth="xs"
    />
  );
};

export default ShareModal;
