import { Box } from "@mui/material";
import React from "react";
import TemplateForm from "../Form/Template";
import StyledModal from "../Styled/StyledModal";

const EditModal = ({
  control,
  handleSubmit,
  errors,
  reset,
  setOpenEdit,
  openEdit,
  closeEditHandler,
  isMobile,
}) => {
  return (
    <StyledModal
      title="แก้ไขข้อมูล"
      content={
        <Box>
          <TemplateForm
            control={control}
            handleSubmit={handleSubmit}
            errors={errors}
            edit={true}
            reset={reset}
            setOpenEdit={setOpenEdit}
            isMobile={isMobile}
          />
        </Box>
      }
      open={openEdit}
      closeHandler={closeEditHandler}
    />
  );
};

export default EditModal;
