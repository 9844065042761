import { Divider, List, ListSubheader } from "@mui/material";
import { LeftNavbarItem } from "./LeftNavbarItem";

const renderNavItems = ({ depth = 0, items, path }) => (
  <List disablePadding>
    {items.reduce(
      (acc, item) => reduceChildRoutes({ acc, depth, item, path }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({ acc, depth, item, path }) => {
  const key = `${item.title}-${depth}`;
  const partialMatch = item.path ? path.includes(item.path) : false;
  const exactMatch = path.split("?")[0] === item.path; // We don't compare query params

  if (item.children) {
    acc.push(
      <LeftNavbarItem
        active={partialMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          path,
        })}
      </LeftNavbarItem>
    );
  } else {
    acc.push(
      <LeftNavbarItem
        active={exactMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

export const LeftNavbarSection = (props) => {
  const { items, path, title, ...other } = props;
  return (
    <>
      <List
        subheader={
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              fontSize: "1rem",
              fontWeight: 500,
              lineHeight: 2.5,
              color: "text.primary",
              ml: 4,
            }}
          >
            {title}
          </ListSubheader>
        }
        {...other}
      >
        {renderNavItems({
          items,
          path,
        })}
      </List>
      <Divider variant="middle" />
    </>
  );
};
