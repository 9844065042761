import React, { useState } from "react";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../hooks/use-auth";
import { yupResolver } from "@hookform/resolvers/yup";
import StyledButton from "../Styled/StyledButton";
import { useLocation, useNavigate } from "react-router-dom";
import StyledTextField from "../Styled/StyledTextField";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { errorMessageHandler } from "../../utils/data-transformer";

const LoginForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required("กรุณากรอก"),
        password: Yup.string().required("กรุณากรอก"),
      })
    ),
  });

  const onLoginSubmit = async ({ email, password }) => {
    try {
      await login(email, password);
      navigate(from, { replace: true });
    } catch (err) {
      err.response.errors.forEach((error) => {
        enqueueSnackbar(errorMessageHandler(error.message), {
          variant: "error",
        });
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onLoginSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <StyledTextField
            fullWidth
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            label="อีเมล"
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <FormControl variant="outlined" fullWidth size="small" sx={{ mt: 1 }}>
            <InputLabel htmlFor="outlined-adornment-password">
              รหัสผ่าน
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              {...field}
            />
          </FormControl>
        )}
      />
      <StyledButton
        type="submit"
        title="เข้าสู่ระบบ"
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
      />
    </form>
  );
};

export default LoginForm;
