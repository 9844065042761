import { Grid, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import StyledBox from "../../../components/Styled/StyledBox";
import StyledTextField from "../../../components/Styled/StyledTextField";
import PermissionForm from "../../../components/Form/Permission";

const PermissionTab = ({ control, errors, viewOnly }) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledBox>
        <Typography fontWeight="bold" ml={1} mb={2}>
          {t("user.rbac.index")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  fullWidth
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  label={t("user.rbac.name")}
                  {...field}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  fullWidth
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  label={t("user.rbac.description")}
                  {...field}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
        </Grid>
      </StyledBox>
      <PermissionForm control={control} errors={errors} viewOnly={viewOnly} />
    </>
  );
};

export default PermissionTab;
