import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  th: {
    translation: {
      report: {
        index: "รายงาน",
        navision: {
          index: "รายงาน Navision",
          reportName: "ชื่อรายงาน",
          dataDate: "ข้อมูลของวันที่",
          lastUpdated: "อัพเดทเมื่อ",
          product: "ผลิตภัณฑ์",
          sales: "รายงานยอดขายตามผลิตภัณฑ์",
          salesPerson: "รายงานยอดขายตามผู้แทนขาย",
          salesSum: "รายงานยอดขายรวมและตามผลิตภัณฑ์",
          salesRegion: "รายงานยอดขายตามภาค",
          salePerson: "ชื่อผู้แทนขาย",
          region: "ตามภาค",
          customer: "รายงานลูกค้า",
          customerName: "ชื่อลูกค้า",
          customerNo: "รหัสลูกค้า",
          creditTerm: "เครดิตเทอม",
          qtyNet: "จำนวน (ตัน)",
          date: "วันที่",
          DataDate: "วันที่สร้าง",
          loadTimestamp: "วันที่โหลด",
          vendorNo: "รหัส Supplier",
          vendorName: "รายชื่อ Supplier",
          ar: "ยอด AR และ Overdue ลูกค้า",
          ap: "ยอด AP และ Overdue Supplier",
        },
        template: {
          index: "รายงาน Template",
          reportId: "เลขที่รายงาน",
          reportName: "ชื่อรายงาน",
          dataDate: "ข้อมูลของวันที่",
          createdAt: "วันที่สร้าง",
          updatedAt: "อัพเดทเมื่อ",
          newTemplate: "รายงานใหม่",
          info: "ข้อมูลทั่วไป",
        },
      },
      monitor: {
        index: "ติดตามการทำงาน",
        jobLog: "Job Log",
        jobConfig: "Config",
      },
      user: {
        index: "ผู้ใช้งาน",
        account: {
          index: "บัญชีผู้ใช้",
          employeeId: "รหัสพนักงาน",
          picture: "รูป",
          name: "ชื่อจริง - นามสกุล",
          firstname: "ชื่อจริง",
          lastname: "นามสกุล",
          password: "รหัสผ่าน",
          old_password: "รหัสผ่านเก่า",
          new_password: "รหัสผ่านใหม่",
          confirm_new_password: "ยืนยันรหัสผ่านใหม่",
          email: "อีเมล",
          phone: "เบอร์โทรศัพท์",
          department: "แผนก",
          position: "ตำแหน่ง",
          status: "สถานะ",
          tab: {
            index: "ทั้งหมด",
            sales: "ขาย",
            inventory: "คลัง",
            accounting: "บัญชี",
            purchase: "จัดซื้อ",
            leader: "หัวหน้า",
            engineer: "ดูแลระบบ",
          },
          addNewAccount: "สร้างบัญชีผู้ใช้",
          permission: "สิทธิ์การเข้าถึง",
        },
        rbac: {
          index: "กำหนดสิทธิ์",
          access: "การเข้าถึง",
          roleId: "รหัสสิทธิ์",
          name: "ชื่อสิทธิ์",
          description: "คำอธิบาย",
          totalUser: "จำนวนผู้ใช้งาน",
          permission: "สิทธิ์",
          addNewRole: "เพิ่มสิทธิ์",
          employee: "พนักงาน",
          permittedEmployee: "พนักงานที่มีสิทธิ์",
          assignToEmployee: "เพิ่มผู้ที่มีสิทธิ์",
          selectEmployee: "เลือกผู้ที่มีสิทธิ์",
        },
        config: "ตั้งค่าผู้ใช้งาน",
      },
      button: {
        trigger: "trigger",
        back: "ย้อนกลับ",
        add: "เพิ่ม",
        delete: "ลบรายงาน",
        create: "สร้าง",
        export: "นำออกข้อมูล",
        import: "นำเข้าข้อมูล",
        download: "ดาวน์โหลด Template",
        save: "บันทึก",
        option: "ตัวเลือก",
        share: "แชร์",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "th",
  fallbackLng: "th",
  interpolation: {
    escapeValue: false,
  },
});
