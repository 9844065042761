import {
  GET_JOB_CONFIGS,
  GET_JOB_CONFIG_BY_ID,
  GET_JOB_LOGS,
  GET_JOB_LOGS_COUNT,
  GET_JOB_LOG_BY_ID,
} from "./queries";
import { graphQLClient } from "../graphql";
import { POST_TRIGGER_JOB } from "./mutations";

class MonitorService {
  async getJobLogs(aggridInput) {
    const { jobLogsAggrid } = await graphQLClient.request(GET_JOB_LOGS, {
      aggridInput,
    });
    return jobLogsAggrid;
  }
  async getJobLogsCount(jobLogsAggridCountAggridInput) {
    const { jobLogsAggridCount } = await graphQLClient.request(
      GET_JOB_LOGS_COUNT,
      {
        jobLogsAggridCountAggridInput,
      }
    );
    return jobLogsAggridCount;
  }
  async getJobLog(jobLogId) {
    const { jobLog } = await graphQLClient.request(GET_JOB_LOG_BY_ID, {
      jobLogId,
    });
    return jobLog;
  }
  async getJobConfigs() {
    const { jobs } = await graphQLClient.request(GET_JOB_CONFIGS);
    return jobs;
  }
  async getJobConfig(jobId) {
    const { job } = await graphQLClient.request(GET_JOB_CONFIG_BY_ID, {
      jobId,
    });
    return job;
  }
  async triggerJob(triggerJobId, dataDate) {
    const { triggerJob } = await graphQLClient.request(POST_TRIGGER_JOB, {
      triggerJobId,
      dataDate,
    });
    return triggerJob;
  }
}

export default new MonitorService();
