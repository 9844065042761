import moment from "moment";

export const unixToDate = (unix) => {
  return moment.unix(unix).format();
};

export const unixToDateWithFormat = (unix) => {
  return moment.unix(unix).format("DD/MM/YYYY");
};

export const unixToDateTimeWithFormat = (unix) => {
  return moment.unix(unix).format("DD/MM/YYYY hh:mm");
};

export const dateToUnix = (date) => {
  return moment(date).format("X");
};

export const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};
