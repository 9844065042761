import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf";

export const exportAsImage = async (element, setBlob) => {
  const html = document.getElementsByTagName("html")[0];
  const body = document.getElementsByTagName("body")[0];
  let htmlWidth = html.clientWidth;
  let bodyWidth = body.clientWidth;

  const newWidth = element.scrollWidth - element.clientWidth;

  if (newWidth > element.clientWidth) {
    htmlWidth += newWidth;
    bodyWidth += newWidth;
  }

  html.style.width = htmlWidth + 200 + "px";
  body.style.width = bodyWidth + 200 + "px";

  const canvas = await html2canvas(element);
  const image = canvas.toDataURL("image/png", 1.0);
  const res = await fetch(image);
  const blob = await res.blob();

  const blobUrl = URL.createObjectURL(blob);
  setBlob(blobUrl);
  html.style.width = null;
  body.style.width = null;
  //   window.location.assign(blobUrl);
  //   let winOpen = window.open("", "_blank");
  //   winOpen.location = "https://example.com";
  //   setTimeout(() => {
  //     window.open(blobUrl, "_blank");
  //   });

  /* Duplicated Solution 
  const contentType = "image/png";
  const byteCharacters = atob(
    image.substring(`data:${contentType};base64,`.length)
  );
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  */
  //   window.open(image);
  //   const pdf = new jsPDF();
  //   pdf.addImage(image, "JPEG", 0, 0);
  //   pdf.output("dataurlnewwindow");
  //   downloadImage(image, imageFileName);
};

// const downloadImage = (blob, fileName) => {
//   const fakeLink = window.document.createElement("a");
//   fakeLink.style = "display:none;";
//   fakeLink.download = fileName;

//   fakeLink.href = blob;

//   document.body.appendChild(fakeLink);
//   fakeLink.click();
//   document.body.removeChild(fakeLink);

//   fakeLink.remove();
// };

export default exportAsImage;
