import { gql } from "graphql-request";

export const GET_TEMPLATES = gql`
  query Query($beforeId: ID, $limit: Int) {
    templates(beforeId: $beforeId, limit: $limit) {
      columns {
        can_override_value
        data_type
        name
      }
      createdAt
      id
      name
      last_updated_time
    }
  }
`;

export const GET_TEMPLATE_BY_ID = gql`
  query Query($id: ID!) {
    template(id: $id) {
      columns {
        can_override_value
        data_type
        name
      }
      createdAt
      id
      chart_type
      created_by {
        id
        first_name
        last_name
      }
      name
      x_axis
      y_axis_type
      x_axis_type
      y_axis
      has_avg_footer
      has_sum_footer
      last_updated_time
      role_permission_list {
        template_priviledge
        role {
          id
          name
        }
      }
    }
  }
`;

export const GET_TEMPLATE_DATA_BY_ID = gql`
  query Query($id: ID!) {
    templateData(template_id: $id) {
      createdAt
      data {
        data_type
        name
        value
      }
      id
      template_id
    }
  }
`;

export const GET_TEMPLATES_BY_USER = gql`
  query TemplatesByUser {
    templatesByUser {
      id
      name
      x_axis
      x_axis_type
      y_axis
      y_axis_type
      has_sum_footer
      has_avg_footer
      chart_type
      createdAt
      last_updated_time
      columns {
        name
        data_type
        can_override_value
      }
      role_permission_list {
        template_priviledge
        role {
          id
          name
        }
      }
      created_by {
        id
        first_name
        last_name
      }
    }
  }
`;
