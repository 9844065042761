import { styled, TextField } from "@mui/material";
import React, { forwardRef } from "react";

const ColorTextField = styled(TextField)({
  backgroundColor: "#fff",
});

const StyledTextField = forwardRef(
  (
    {
      id,
      name,
      label,
      value,
      onChange,
      disabled,
      multiline,
      rows,
      error,
      helperText,
      type,
      sx,
      InputProps,
      inputProps,
      placeholder,
      required,
      margin,
      ...rest
    },
    ref
  ) => {
    return (
      <ColorTextField
        ref={ref}
        placeholder={placeholder}
        autoComplete="off"
        size="small"
        fullWidth
        label={label}
        type={type ?? "text"}
        sx={sx}
        InputProps={InputProps}
        inputProps={inputProps}
        value={value}
        onChange={onChange}
        disabled={disabled}
        id={id}
        name={name}
        multiline={multiline}
        rows={rows}
        error={error}
        helperText={helperText}
        required={required}
        margin={margin}
        {...rest}
      />
    );
  }
);

export default StyledTextField;
