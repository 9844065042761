import { gql } from "graphql-request";

export const CREATE_TEMPLATE = gql`
  mutation Mutation($input: TemplateInput!) {
    createTemplate(createTemplateInput: $input) {
      id
      name
      x_axis
      y_axis
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation Mutation($id: Int!, $input: TemplateInput!) {
    updateTemplate(id: $id, updateTemplateInput: $input) {
      id
      name
      columns {
        name
        data_type
        can_override_value
      }
      chart_type
      x_axis_type
      x_axis
      y_axis_type
      y_axis
      has_avg_footer
      has_sum_footer
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation Mutation($id: Int!) {
    removeTemplate(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_DATA = gql`
  mutation Mutation($createTemplateDatum: CreateTemplateDatumInput!) {
    createTemplateDatum(createTemplateDatumInput: $createTemplateDatum) {
      id
      template_id
      data {
        name
        value
      }
    }
  }
`;

export const UPDATE_DATA = gql`
  mutation UpdateTemplateDatum(
    $template_id: ID!
    $updateTemplateDatumInput: UpdateTemplateDatumInput!
  ) {
    updateTemplateDatum(
      template_id: $template_id
      updateTemplateDatumInput: $updateTemplateDatumInput
    ) {
      id
      template_id
      data {
        name
        value
        data_type
      }
    }
  }
`;

export const DELETE_TEMPLATE_DATA = gql`
  mutation Mutation($id: Int!) {
    removeTemplateDatum(id: $id) {
      createdAt
      id
    }
  }
`;

export const UPDATE_TEMPLATE_ROLE = gql`
  mutation UpdateTemplateRole(
    $templateId: Int!
    $updateTemplateRoleInput: [TemplateRoleInput]!
  ) {
    updateTemplateRole(
      id: $templateId
      updateTemplateRoleInput: $updateTemplateRoleInput
    ) {
      id
      name
      x_axis
      x_axis_type
      y_axis
      y_axis_type
      has_sum_footer
      has_avg_footer
      columns {
        name
        data_type
        can_override_value
      }
      chart_type
      createdAt
      last_updated_time
    }
  }
`;
