import { Box, styled } from "@mui/material";

const StyledBox = styled(Box)(({ isMobile }) => ({
  marginTop: isMobile ? "1rem" : 0,
  border: `1px solid #d6cfcf`,
  backgroundColor: "#fff",
  padding: isMobile ? "1rem" : "1.5rem",
}));

export default StyledBox;
