import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { positionType } from "./user-info";

export const toLocale = (params) => {
  if (isNaN(params.value)) return "";
  if (params && params.value)
    return params.value.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  return "0";
};

export const mapAgGridType = (type) => {
  if (type === "Number") return "numericColumn";
  else return type;
};

export const mapAgGridTypeFromValueType = (value) => {
  const check = typeof value;
  if (check === "string") return "String";
  if (check === "number") return "Number";
  else return;
};

export const mapObjectToEnum = (object) => {
  let permissions = [];
  for (const [key1, value1] of Object.entries(object)) {
    for (const [key2, value2] of Object.entries(value1)) {
      for (const [key3, value3] of Object.entries(value2)) {
        if (typeof value3 === "string") {
          permissions.push(`${key1}__${key2}__${value3}`.toUpperCase());
        } else if (value3 === true) {
          permissions.push(`${key1}__${key2}__${key3}`.toUpperCase());
        }
      }
    }
  }
  return permissions;
};

export const filteredRow = (initialrows, priviledgeObject, resource) => {
  if (initialrows && priviledgeObject && resource) {
    let deDuplicatedFilteredRows = [];

    for (const [, priviledgeList] of Object.entries(priviledgeObject)) {
      const filteredPriviledgesBySource = priviledgeList?.filter(
        (priviledge) => priviledge.split("__")[0] === resource
      );
      const extractedFunctions = filteredPriviledgesBySource.map(
        (priviledge) => priviledge.split("__")[1]
      );

      if (extractedFunctions !== []) {
        initialrows
          .filter((row) => extractedFunctions.includes(row.functionName))
          .forEach((row) => {
            if (
              deDuplicatedFilteredRows.some(
                (item) => item.functionName === row.functionName
              ) === false
            ) {
              deDuplicatedFilteredRows.push(row);
            }
          });
      }
    }
    return deDuplicatedFilteredRows;
  }
  return initialrows;
};

export const formatPriviledgePayload = (priviledge) => {
  let formattedPriviledge = {};
  const splittedPriviledge = priviledge.split("__");

  formattedPriviledge.RESOURCE = splittedPriviledge[0];
  formattedPriviledge.FUNCTION = splittedPriviledge[1];
  formattedPriviledge.PRIVILEDGE = splittedPriviledge[2];

  return formattedPriviledge;
};

export const mapRolesToObject = (allRoles, selectedRoleNames) => {
  if (allRoles && selectedRoleNames) {
    let priviledgeList = {};
    const removedAdmin = allRoles.filter(
      (role) =>
        !role.permission_list.some((permission) =>
          permission.includes("ADMIN__ADMIN__ADMIN")
        )
    );
    const filteredRoles = removedAdmin.filter((role) =>
      selectedRoleNames.includes(role.name)
    );
    for (const role of filteredRoles) {
      priviledgeList[role.name] = role.permission_list;
    }
    return priviledgeList;
  }
  return {};
};

export const extractRoleNames = (allRoles) => allRoles.map((role) => role.name);

export const filterParams = (type) => ({
  filters: [
    {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) return -1;
          const dateParts = dateAsString.split("-");
          let cellDate;
          if (type === "Month") {
            cellDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1);
            if (
              filterLocalDateAtMidnight.getMonth() === cellDate.getMonth() &&
              filterLocalDateAtMidnight.getFullYear() === cellDate.getFullYear()
            ) {
              return 0;
            }
          } else if (type === "Datetime") {
            cellDate = new Date(
              Number(dateParts[0]),
              Number(dateParts[1]) - 1,
              Number(dateParts[2])
            );
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
          } else {
            cellDate = new Date(Number(dateParts[0]), 0);
            if (
              filterLocalDateAtMidnight.getFullYear() === cellDate.getFullYear()
            ) {
              return 0;
            }
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        filterOptions: [
          "equals",
          "notEqual",
          "greaterThan",
          "lessThan",
          {
            displayKey: "inRange",
            displayName: "inRange",
            predicate: ([fv1, fv2], cellValue) => {
              const dateValue = new Date(cellValue);
              let formatFv2;
              if (type === "Year") {
                formatFv2 = new Date(moment(fv2).endOf("year"));
              } else if (type === "Month") {
                formatFv2 = new Date(moment(fv2).endOf("month"));
              } else if (type === "Datetime") {
                formatFv2 = new Date(moment(fv2).endOf("day"));
              }
              return (
                dateValue == null ||
                (dateValue >= fv1 && dateValue <= formatFv2)
              );
            },
            numberOfInputs: 2,
          },
        ],
        browserDatePicker: true,
        suppressAndOrCondition: true,
      },
    },
    {
      filter: "agSetColumnFilter",
    },
  ],
});

export const dateToString = (date) => {
  const mount = date.split("/")[1];
  const year = date.split("/")[2];
  switch (mount) {
    case "01":
      return "JAN " + year;
    case "02":
      return "FEB " + year;
    case "03":
      return "MAR " + year;
    case "04":
      return "APR " + year;
    case "05":
      return "MAY " + year;
    case "06":
      return "JUN " + year;
    case "07":
      return "JUL " + year;
    case "08":
      return "AUG " + year;
    case "09":
      return "SEP " + year;
    case "10":
      return "OCT " + year;
    case "11":
      return "NOV " + year;
    case "12":
      return "DEC " + year;
    default:
      break;
  }
};

export const filterPositionByDepartment = (department, setPosition) => {
  switch (department) {
    case "กรรมการผู้จัดการ":
      setPosition([
        {
          id: uuidv4(),
          name: "กรรมการผู้จัดการ",
          value: "กรรมการผู้จัดการ",
        },
        {
          id: uuidv4(),
          name: "ผู้จัดการทั่วไป",
          value: "ผู้จัดการทั่วไป",
        },
        {
          id: uuidv4(),
          name: "ผู้ช่วยกรรมการผู้จัดการ โรงงาน",
          value: "ผู้ช่วยกรรมการผู้จัดการ โรงงาน",
        },
        {
          id: uuidv4(),
          name: "ผู้ช่วยกรรมการผู้จัดการ",
          value: "ผู้ช่วยกรรมการผู้จัดการ",
        },
      ]);
      break;
    case "ตรวจสอบภายใน":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายตรวจสอบภายใน",
          value: "ผู้จัดการฝ่ายตรวจสอบภายใน",
        },
      ]);
      break;
    case "จัดส่ง":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายจัดส่ง",
          value: "ผู้จัดการฝ่ายจัดส่ง",
        },
      ]);
      break;
    case "บัญชี (HO)":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการฝ่ายบัญชี(HO)",
          value: "ผู้ช่วยผู้จัดการฝ่ายบัญชี(HO)",
        },
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายบัญชี",
          value: "ผู้จัดการฝ่ายบัญชี",
        },
      ]);
      break;
    case "บัญชี (SO)":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการฝ่ายบัญชี(SO)",
          value: "ผู้ช่วยผู้จัดการฝ่ายบัญชี(SO)",
        },
      ]);
      break;
    case "บุคคล/ธุรการ (HO)":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายบุคคล",
          value: "ผู้จัดการฝ่ายบุคคล",
        },
      ]);
      break;
    case "บุคคล/ธุรการ (SO)":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายบุคคล/ธุรการ",
          value: "ผู้จัดการฝ่ายบุคคล/ธุรการ",
        },
      ]);
      break;
    case "ควบคุมต้นทุน":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายควบคุมต้นทุน",
          value: "ผู้จัดการฝ่ายควบคุมต้นทุน",
        },
      ]);
      break;
    case "วิศวกรรม":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายวิศวกรรม",
          value: "ผู้จัดการฝ่ายวิศวกรรม",
        },
      ]);
      break;
    case "CSR":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่าย CSR",
          value: "ผู้จัดการฝ่าย CSR",
        },
      ]);
      break;
    case "โรงโม่หินปูน/สิ่งแวดล้อม/ความปลอดภัย":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายโรงโม่หินปูน/สิ่งแวดล้อม/ควาดปลอดภัย",
          value: "ผู้จัดการฝ่ายโรงโม่หินปูน/สิ่งแวดล้อม/ควาดปลอดภัย",
        },
      ]);
      break;
    case "หน่วยงานธุรกิจถ่านหิน":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายต่างประเทศ",
          value: "ผู้จัดการฝ่ายต่างประเทศ",
        },
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายบัญชี",
          value: "ผู้จัดการฝ่ายบัญชี",
        },
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการฝ่ายบัญชี",
          value: "ผู้ช่วยผู้จัดการฝ่ายบัญชี",
        },
        {
          id: uuidv4(),
          name: "เจ้าหน้าที่ฝ่ายการเงินโครงการ อาวุโส",
          value: "เจ้าหน้าที่ฝ่ายการเงินโครงการ อาวุโส",
        },
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายโครงการ",
          value: "ผู้จัดการฝ่ายโครงการ",
        },
      ]);
      break;
    case "การตลาด HO":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายขาย",
          value: "ผู้จัดการฝ่ายขาย",
        },
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการฝ่ายขาย",
          value: "ผู้ช่วยผู้จัดการฝ่ายขาย",
        },
        {
          id: uuidv4(),
          name: "เจ้าหน้าที่สนับสนุนงานขาย อาวุโส",
          value: "เจ้าหน้าที่สนับสนุนงานขาย อาวุโส",
        },
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการเทคนิคบริการ",
          value: "ผู้ช่วยผู้จัดการเทคนิคบริการ",
        },
        {
          id: uuidv4(),
          name: "ผู้แทนขาย",
          value: "ผู้แทนขาย",
        },
      ]);
      break;
    case "จัดซื้อ":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายจัดซื้อ",
          value: "ผู้จัดการฝ่ายจัดซื้อ",
        },
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการฝ่ายจัดซื้อ",
          value: "ผู้ช่วยผู้จัดการฝ่ายจัดซื้อ",
        },
      ]);
      break;
    case "กฎหมาย":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายกฎหมาย",
          value: "ผู้จัดการฝ่ายกฎหมาย",
        },
      ]);
      break;
    case "สโตร์":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายสโตร์",
          value: "ผู้จัดการฝ่ายสโตร์",
        },
      ]);
      break;
    case "ผลิต":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการฝ่ายผลิต",
          value: "ผู้ช่วยผู้จัดการฝ่ายผลิต",
        },
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายผลิต",
          value: "ผู้จัดการฝ่ายผลิต",
        },
        {
          id: uuidv4(),
          name: "ธุรการผ่ายผลิต",
          value: "ธุรการผ่ายผลิต",
        },
      ]);
      break;
    case "ซ่อมบำรุง-ไฟฟ้า":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายไฟฟ้า",
          value: "ผู้จัดการฝ่ายไฟฟ้า",
        },
      ]);
      break;
    case "ซ่อมบำรุง-เครื่องกล":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการฝ่ายซ่อมบำรุง-เครื่องกล",
          value: "ผู้ช่วยผู้จัดการฝ่ายซ่อมบำรุง-เครื่องกล",
        },
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายซ่อมบำรุง-เครื่องกล",
          value: "ผู้จัดการฝ่ายซ่อมบำรุง-เครื่องกล",
        },
        {
          id: uuidv4(),
          name: "เจ้าหน้าที่ธุรการฝ่ายซ่อมบำรุงขเครื่องกล",
          value: "เจ้าหน้าที่ธุรการฝ่ายซ่อมบำรุงขเครื่องกล",
        },
      ]);
      break;
    case "ประกันคุณภาพและจัดหาวัตถุดิบ":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้จัดการฝ่ายประกันคุณภาพและจัดหาวัตถุดิบ",
          value: "ผู้จัดการฝ่ายประกันคุณภาพและจัดหาวัตถุดิบ",
        },
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการฝ่ายประกันคุณภาพ (วัตถุดิบ)",
          value: "ผู้ช่วยผู้จัดการฝ่ายประกันคุณภาพ (วัตถุดิบ)",
        },
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการฝ่ายประกันคุณภาพ",
          value: "ผู้ช่วยผู้จัดการฝ่ายประกันคุณภาพ",
        },
      ]);
      break;
    case "กรรมการผู้จัดการ(MD)":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้ช่วยผู้จัดการ/เลขานุการกรรมการผู้จัดการ",
          value: "ผู้ช่วยผู้จัดการ/เลขานุการกรรมการผู้จัดการ",
        },
      ]);
      break;
    case "ฝ่ายคอมพิวเตอร์ SO":
      setPosition([
        {
          id: uuidv4(),
          name: "เจ้าหน้าที่ฝ่ายคอมพิวเตอร์ อาวุโส",
          value: "เจ้าหน้าที่ฝ่ายคอมพิวเตอร์ อาวุโส",
        },
      ]);
      break;
    case "ฝ่ายคอมพิวเตอร์ HO":
      setPosition([
        {
          id: uuidv4(),
          name: "เจ้าหน้าที่ฝ่ายคอมพิวเตอร์ อาวุโส",
          value: "เจ้าหน้าที่ฝ่ายคอมพิวเตอร์ อาวุโส",
        },
      ]);
      break;
    case "ดูแลระบบ":
      setPosition([
        {
          id: uuidv4(),
          name: "ผู้ดูแลระบบ",
          value: "ผู้ดูแลระบบ",
        },
      ]);
      break;
    default:
      setPosition(positionType);
      break;
  }
};

export const allRoles = [
  "ADMIN__ADMIN__ADMIN",
  "NAVISION__SALES__VIEW",
  "NAVISION__SALES_REGION__VIEW",
  "NAVISION__SALES_PERSON__VIEW",
  "NAVISION__CUSTOMER__VIEW",
  "NAVISION__AR__VIEW",
  "NAVISION__AP__VIEW",
  "MONITOR__JOB__VIEW",
  "MONITOR__JOB__CREATE",
  "MONITOR__JOB__EDIT",
  "MONITOR__JOB__DELETE",
  "CONFIG__RBAC__VIEW",
  "CONFIG__RBAC__CREATE",
  "CONFIG__RBAC__EDIT",
  "CONFIG__RBAC__DELETE",
  "CONFIG__USER__VIEW",
  "CONFIG__USER__CREATE",
  "CONFIG__USER__EDIT",
  "CONFIG__USER__DELETE",
];

export const errorMessageHandler = (message) => {
  switch (message) {
    case "Invalid credentials":
      return "อีเมลหรือรหัสผ่านไม่ถูกต้อง";
    case "Employee is not active":
      return "บัญชีผู้ใช้หยุดใช้งาน";
    case "Maximum number of employee reached Limit":
      return "จำนวนผู้ใช้งานเกินกำหนด";
    case "invalid password":
      return "รหัสผ่านไม่ถูกต้อง";
    default:
      return "เซิฟเวอร์หยุดใช้งาน";
  }
};

export const mapNameToPermission = (name) => {
  switch (name) {
    case "รายงานยอดขายตามผลิตภัณฑ์":
      return "NAVISION__SALES__VIEW";
    case "รายงานยอดขายตามผู้แทนขาย":
      return "NAVISION__SALES_PERSON__VIEW";
    case "รายงานยอดขายตามภาค":
      return "NAVISION__SALES_REGION__VIEW";
    case "รายงานยอดขายรวมและตามผลิตภัณฑ์":
      return "NAVISION__SALES_SUM__VIEW";
    case "รายงานลูกค้า":
      return "NAVISION__CUSTOMER__VIEW";
    case "ยอด AR และ Overdue ลูกค้า":
      return "NAVISION__AR__VIEW";
    case "ยอด AP และ Overdue Supplier":
      return "NAVISION__AP__VIEW";
    default:
      return;
  }
};
