import { gql } from "graphql-request";
export const LOGIN = gql`
  mutation Mutation($loginInfo: InputLogin!) {
    login(login_info: $loginInfo) {
      employee {
        id
        first_name
        last_name
        email
        phone
        department
        position
        is_active
        line_uid
        img_url
        last_login_date
        role_list {
          id
          name
          permission_list
          accessible_template_list {
            template_id
            template_priviledge
          }
        }
      }
      token {
        access_token
      }
    }
  }
`;

export const REVOKE = gql`
  mutation Mutation {
    revokeToken
  }
`;

export const REFRESH = gql`
  mutation Refresh {
    refreshToken {
      token {
        access_token
      }
    }
  }
`;
