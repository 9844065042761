import { gql } from "graphql-request";

export const POST_TRIGGER_JOB = gql`
  mutation TriggerJob($triggerJobId: Int!, $dataDate: String!) {
    triggerJob(id: $triggerJobId, data_date: $dataDate) {
      id
      name
      description
      type
      cron_schedule
      script
      remark
      task_key
    }
  }
`;
