import React from "react";
import { AgGridReact } from "ag-grid-react";
import StyledButton from "../../components/Styled/StyledButton";
import { Box } from "@mui/system";
import { postTriggerJob } from "../../features/Monitor/monitor-actions";
import { useDispatch } from "react-redux";

export default function DetailCellRenderer({ data, node, api }) {
  const dispatch = useDispatch();
  const columnDefs = [
    { field: "type", headerName: "Type" },
    { field: "script", headerName: "Script" },
    { field: "description", headerName: "Description" },
    { field: "cron_schedule", headerName: "Cron Schedule" },
    { field: "remark", headerName: "Remark" },
  ];

  const defaultColDef = {
    flex: 1,
    minWidth: 120,
  };

  const onGridReady = (params) => {
    const gridInfo = {
      id: node.id,
      api: params.api,
      columnApi: params.columnApi,
    };
    api.addDetailGridInfo(node.id, gridInfo);
  };

  const onButtonClick = () => {
    dispatch(postTriggerJob(parseInt(data.job_id), data.data_date, null, api));
  };

  const renderJobButton = (status) => {
    switch (status) {
      case "SUCCESS":
        return;
      case "RUNNING":
        return (
          <Box gap={1} mx={4} py={2} display={"flex"}>
            <StyledButton title="Run" variant="contained" disabled />
          </Box>
        );
      case "FAILED":
        return (
          <Box gap={1} mx={4} py={2} display={"flex"}>
            <StyledButton
              onClick={onButtonClick}
              title="Rerun"
              variant="contained"
            />
          </Box>
        );
      default:
        return;
    }
  };

  return (
    <div>
      <div style={{ height: 250, width: "100%" }}>
        <AgGridReact
          domLayout="autoHeight"
          id="detailGrid"
          className="ag-theme-material ag-details-row"
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={[data.job] || []}
          onGridReady={onGridReady}
          masterDetail={true}
        />
      </div>
      {renderJobButton(data.status)}
    </div>
  );
}
