import { Box } from "@mui/material";
import { useNavbar } from "../../hooks/use-navbar";
import { StyledDashboardLayout } from "../Styled/StyledDashboardLayout";
import LeftNavbar from "../UI/LeftNavbar";
import TopNavbar from "../UI/TopNavbar";

const DesktopLayout = ({ children, isCustomer, pathname }) => {
  const { sidebar } = useNavbar();
  return (
    <>
      <StyledDashboardLayout open={sidebar.isSidebarOpen}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            height: "100%",
            p: isCustomer ? 0 : 3,
          }}
        >
          {children}
        </Box>
      </StyledDashboardLayout>
      <TopNavbar pathname={pathname} />
      <LeftNavbar />
    </>
  );
};

export default DesktopLayout;
