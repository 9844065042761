import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";

function LinkTab(props) {
  return <Tab component={Link} {...props} />;
}

export default function StyledTab({ tabs, currentTab, centered }) {
  return (
    <Tabs
      centered={centered}
      value={currentTab}
      aria-label="nav tabs example"
      sx={{
        width: "100%",
      }}
    >
      {tabs.map((tab, index) => (
        <LinkTab key={index} label={tab.label} value={tab.path} to={tab.path} />
      ))}
    </Tabs>
  );
}
