import { Box, useMediaQuery } from "@mui/material";
import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import MobileLayout from "../../../components/Layout/MobileLayout";
import ActionBar from "../../../components/UI/ActionBar";
import AgGrid from "../../../components/UI/AgGrid";
import MobileExport from "../../../components/UI/MobileExport";
import {
  getAllNavision,
  getAllSales,
} from "../../../features/Navision/navision-actions";
import { useNavbar } from "../../../hooks/use-navbar";
import { filterParams, toLocale } from "../../../utils/data-transformer";
import LandscapeLayout from "../../../components/Layout/LandscapeLayout";
import { navisionActions } from "../../../features/Navision/navision-slice";
import ShareModal from "../../../components/UI/ShareModal";
import { useAuth } from "../../../hooks/use-auth";
import SalesDateFilter from "../../../components/UI/SalesDateFilter";
import CustomDate from "../../../components/UI/CustomDate";
import StyledButton from "../../../components/Styled/StyledButton";
import CustomMonth from "../../../components/UI/CustomMonth";
import CustomYear from "../../../components/UI/CustomYear";

const SalesRegionReport = () => {
  const { user } = useAuth();
  const admin = user?.role_list?.some((role) =>
    role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const [share, setShare] = useState(false);
  const dispatch = useDispatch();
  const gridRef = useRef();
  const { mobile } = useNavbar();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  const { allNavisions, sales, isLoading, dateFilterType } = useSelector(
    (state) => state.navision
  );
  const mapDateFilterType = () => {
    if (dateFilterType === "month") return "Month";
    else if (dateFilterType === "year") return "Year";
    else return "Datetime";
  };

  const mapDateType = () => {
    if (dateFilterType === "month") return "เดือน";
    else if (dateFilterType === "year") return "ปี";
    else return "วันที่";
  };

  const columnDefs = [
    {
      field: "data_date",
      headerName: mapDateType(),
      filter: "agMultiColumnFilter",
      filterParams: filterParams(mapDateFilterType()),
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      field: "salesperson",
      headerName: t("report.navision.salePerson"),
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      field: "region",
      headerName: t("report.navision.region"),
      enableRowGroup: true,
      enablePivot: true,
      rowGroup: true,
    },
    {
      field: "product",
      headerName: t("report.navision.product"),
      enableRowGroup: true,
      enablePivot: true,
      pivot: true,
    },
    {
      field: "customer_no",
      headerName: t("report.navision.customerNo"),
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      field: "customer_name",
      headerName: t("report.navision.customerName"),
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      field: "qty_net",
      headerName: t("report.navision.qtyNet"),
      filter: "agNumberColumnFilter",
      cellRenderer: toLocale,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      aggFunc: "sum",
    },
  ];

  const autoGroupColumnDef = useMemo(() => {
    return {
      cellRendererParams: {
        suppressCount: true,
      },
    };
  }, []);

  const breadcrumbs = [
    {
      name: t("report.index"),
      href: "/report",
    },
    {
      name: t("report.navision.index"),
      href: "/report/navision",
    },
    {
      name: t("report.navision.salesRegion"),
    },
  ];

  const buttons = [
    admin && {
      title: "แชร์",
      type: "button",
      variant: "contained",
      onClick: () => setShare(true),
    },
    {
      type: "menuButton",
      title: t("button.export"),
      variant: "outlined",
      csvFilename: "รายงานยอดขายตามภาค.csv",
      excelFilename: "รายงานยอดขายตามภาค.xlsx",
    },
  ];

  useEffect(() => {
    dispatch(getAllNavision());

    dispatch(
      getAllSales({
        startDate: new Date(
          moment().startOf("day").subtract(1, "week")
        ).toISOString(),
        endDate: new Date(moment().endOf("day")).toISOString(),
      })
    );
    return () => {
      dispatch(navisionActions.resetSales());
      dispatch(navisionActions.resetDateFilterType());
    };
  }, [dispatch]);

  useEffect(() => {
    mobile.setMobileTitle(t("report.navision.salesRegion"));
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t]);

  const components = useMemo(() => {
    if (dateFilterType === "month") return { agDateInput: CustomMonth };
    else if (dateFilterType === "year") return { agDateInput: CustomYear };
    else return { agDateInput: CustomDate };
  }, [dateFilterType]);

  const onFirstDataRendered = useCallback(
    (event) => {
      const createRangeChartParams = {
        cellRange: {
          //Pagination concept
          rowStartIndex: 0,
          rowEndIndex: 11,
          columns: ["data_date", "product"],
        },
        // suppressChartRanges: true,
        chartType: "groupedColumn",
        chartContainer: document.querySelector("#myChart"),
        // aggFunc: "sum",
      };
      event.api.createPivotChart(createRangeChartParams);
      // gridRef.current.api.createRangeChart(createRangeChartParams);
      if (!isMobile) {
        gridRef.current.api.sizeColumnsToFit();
      }
    },
    [isMobile]
  );

  const sideBar = {
    toolPanels: [
      {
        id: "id",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressPivotMode: true,
        },
      },
      "filters",
    ],
  };

  const isLandscape = useMediaQuery(
    "(max-device-width: 920px) and (orientation: landscape)",
    {
      noSsr: true,
    }
  );

  const closeShare = () => {
    setShare(false);
  };

  return (
    <>
      {!isMobile && !isLandscape && (
        <DesktopLayout isSidebarOpen>
          <ActionBar
            ref={gridRef}
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isLoading={isLoading.sales}
            updateTime={`อัพเดทเมื่อ ${moment(
              allNavisions[0]?.navision_last_updated
            ).fromNow()}`}
            isSidebarOpen
          />
          <SalesDateFilter disabled={isLoading.sales} />
          {!isLoading.sales && (
            <AgGrid
              ref={gridRef}
              columnDefs={columnDefs}
              searchBox
              rowData={sales}
              pivotMode={true}
              suppressAggFuncInHeader={true}
              autoGroupColumnDef={autoGroupColumnDef}
              groupIncludeTotalFooter
              components={components}
              enableCharts={true}
              sideBar={sideBar}
              onFirstDataRendered={onFirstDataRendered}
              height={450}
            />
          )}
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout
          isLoading={isLoading.sales}
          updateTime={`อัพเดทเมื่อ ${moment(
            allNavisions[0]?.navision_last_updated
          ).fromNow()}`}
        >
          <SalesDateFilter isMobile disabled={isLoading.sales} />
          {!isLoading.sales && (
            <Box sx={{ my: 2 }}>
              <AgGrid
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={sales}
                pivotMode={true}
                suppressAggFuncInHeader={true}
                autoGroupColumnDef={autoGroupColumnDef}
                groupIncludeTotalFooter
                components={components}
                enableCharts={true}
                sideBar={sideBar}
                onFirstDataRendered={onFirstDataRendered}
                height={450}
              />
              <StyledButton
                sx={{ mb: 1 }}
                title={t("button.share")}
                fullWidth
                variant="contained"
                onClick={() => setShare(true)}
              />
              <MobileExport
                ref={gridRef}
                csvFilename={"รายงานยอดขายตามภาค"}
                excelFilename={"รายงานยอดขายตามภาค"}
              />
            </Box>
          )}
        </MobileLayout>
      )}
      {!isMobile && isLandscape && (
        <LandscapeLayout>
          <ActionBar
            ref={gridRef}
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isLoading={isLoading.sales}
            updateTime={`อัพเดทเมื่อ ${moment(
              allNavisions[0]?.navision_last_updated
            ).fromNow()}`}
            isLandscape
          />
          <SalesDateFilter isLandscape disabled={isLoading.sales} />
          {!isLoading.sales && (
            <Box sx={{ my: 2 }}>
              <AgGrid
                ref={gridRef}
                rowData={sales}
                columnDefs={columnDefs}
                enableCharts={true}
                pivotMode={true}
                onFirstDataRendered={onFirstDataRendered}
                autoGroupColumnDef={autoGroupColumnDef}
                groupIncludeTotalFooter
                sideBar={sideBar}
                suppressAggFuncInHeader={true}
                components={components}
                height={450}
              />
            </Box>
          )}
        </LandscapeLayout>
      )}
      <ShareModal
        name={t("report.navision.salesRegion")}
        share={share}
        closeShare={closeShare}
        navision
      />
    </>
  );
};

export default SalesRegionReport;
