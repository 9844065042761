import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import StyledButton from "../../../components/Styled/StyledButton";
import UserTable from "../../../components/UI/UserTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../features/User/Account/account-actions";
import StyledModal from "../../../components/Styled/StyledModal";
import { rbacActions } from "../../../features/User/Rbac/rbac-slice";
import { useSnackbar } from "notistack";

const EmployeeTab = ({
  open,
  handleClose,
  fields,
  handleSelectEmployees,
  gridRef,
  selectEmployeegridRef,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { allUsers } = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllUsers(enqueueSnackbar));
    return () => dispatch(rbacActions.resetAllRole());
  }, [dispatch, enqueueSnackbar]);

  return (
    <>
      <StyledModal
        maxWidth="md"
        open={open}
        closeHandler={handleClose}
        title={t("user.rbac.assignToEmployee")}
        content={
          <UserTable
            rowData={allUsers}
            gridRef={selectEmployeegridRef}
            height={450}
            rowSelection={"multiple"}
            enableCheckbox
            headerCheckboxSelection
          />
        }
        action={
          <StyledButton
            title={t("user.rbac.selectEmployee")}
            variant="contained"
            onClick={handleSelectEmployees}
            sx={{ mt: 2 }}
          />
        }
      />
      <UserTable
        gridRef={gridRef}
        height={600}
        rowData={fields || []}
        rowSelection={"multiple"}
        enableCheckbox
        headerCheckboxSelection
      />
    </>
  );
};

export default EmployeeTab;
