import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import StyledButton from "../components/Styled/StyledButton";

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Typography
        sx={{ fontSize: "150px", lineHeight: 1.25, color: "#DAE1DC" }}
      >
        401
      </Typography>
      <Typography variant="h5">คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล</Typography>
      <Typography>กรุณาติดต่อเจ้าหน้าที่เพื่อขอสิทธิ์</Typography>
      <StyledButton
        sx={{ mt: 2 }}
        title="ย้อนกลับ"
        onClick={() => navigate(-1)}
        variant="contained"
      />
    </Box>
  );
};

export default Unauthorized;
