import { Box } from "@mui/material";
import React from "react";
import StyledButton from "../Styled/StyledButton";
import StyledModal from "../Styled/StyledModal";

const CopyModal = ({
  setOpenCopy,
  copyTemplateAction,
  openCopy,
  closeConfirmationCopyButton,
}) => {
  return (
    <StyledModal
      title="ยืนยันการคัดลอกรายงาน"
      content={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
            width: 220,
          }}
        >
          <StyledButton
            title="ยกเลิก"
            variant="outlined"
            onClick={() => setOpenCopy(false)}
          />
          <StyledButton
            title="ยืนยัน"
            variant="contained"
            onClick={copyTemplateAction}
          />
        </Box>
      }
      open={openCopy}
      closeHandler={closeConfirmationCopyButton}
    />
  );
};

export default CopyModal;
