import { Box } from "@mui/material";
import React from "react";
import StyledButton from "../Styled/StyledButton";
import StyledModal from "../Styled/StyledModal";

const DeleteReportModal = ({
  confirmation,
  setConfirmation,
  removeTemplateHandle,
  closeConfirmation,
}) => {
  return (
    <StyledModal
      title={"ยืนยันการลบรายงาน?"}
      content={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
            width: 220,
          }}
        >
          <StyledButton
            title="ยกเลิก"
            variant="outlined"
            onClick={() => setConfirmation(false)}
          />
          <StyledButton
            title="ยืนยัน"
            variant="contained"
            onClick={removeTemplateHandle}
          />
        </Box>
      }
      open={confirmation}
      closeHandler={closeConfirmation}
    />
  );
};

export default DeleteReportModal;
