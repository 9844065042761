import moment from "moment";

export const customChartThemes = (
  formatData,
  title,
  xAxis,
  yAxis,
  min,
  max
) => {
  return {
    myCustomTheme: {
      baseTheme: "ag-theme-material",
      palette: {
        fills: [
          "#57b99d",
          "#4f98d5",
          "#925eb1",
          "#65c87a",
          "#ebc445",
          "#d9823b",
          "#d75645",
          "#98a5a6",
          "#57b99d",
          "#4f98d5",
          "#925eb1",
          "#65c87a",
          "#ebc445",
          "#d9823b",
          "#d75645",
          "#98a5a6",
          "#57b99d",
          "#4f98d5",
          "#925eb1",
          "#65c87a",
          "#ebc445",
          "#d9823b",
          "#d75645",
          "#98a5a6",
        ],
        strokes: [
          "#57b99d",
          "#4f98d5",
          "#925eb1",
          "#65c87a",
          "#ebc445",
          "#d9823b",
          "#d75645",
          "#98a5a6",
          "#57b99d",
          "#4f98d5",
          "#925eb1",
          "#65c87a",
          "#ebc445",
          "#d9823b",
          "#d75645",
          "#98a5a6",
          "#57b99d",
          "#4f98d5",
          "#925eb1",
          "#65c87a",
          "#ebc445",
          "#d9823b",
          "#d75645",
          "#98a5a6",
        ],
      },
      overrides: {
        common: {
          title: {
            fontSize: 16,
            fontFamily: "Kanit, sans-serif",
            text: title ? title : "",
            enabled: title ? true : false,
          },
          legend: {
            position: "top",
          },
          axes: {
            number: {
              // min: 0,
              min: min ?? undefined,
              max: max ?? undefined,
              nice: true,
              tick: {
                // count: 5,
                width: 0,
              },
              title: {
                enabled: true,
                text: yAxis,
              },
              label: {
                formatter: function (params) {
                  if (params.value) return params.value.toLocaleString();
                  else return params.value;
                },
              },
            },
            category: {
              title: {
                enabled: true,
                text: xAxis,
              },
              label: {
                rotation: 45,
                formatter: (params) => {
                  if (!formatData) return params.value.value;
                  return moment(new Date(params.value)).format(formatData);
                },
              },
            },
          },
        },
        column: {
          series: {
            strokeWidth: 0,
            highlightStyle: {
              item: {
                fill: undefined,
              },
              series: {
                dimOpacity: 0.2,
              },
            },
            visible: true,
            // flipXY: true,
            label: {
              placement: "outside",
              enabled: true,
              fontWeight: "normal",
              fontSize: 10,
              formatter: function (params) {
                if (params.value) return params.value.toLocaleString();
                else return params.value;
              },
            },
            tooltip: {
              renderer: (params) => {
                return {
                  content:
                    params.xValue +
                    ":</b> " +
                    parseFloat(params.yValue).toLocaleString(),
                };
              },
            },
          },
          navigator: {
            enabled: true,
            height: 50,
            minHandle: {
              width: 20,
              height: 30,
            },
            maxHandle: {
              width: 20,
              height: 30,
            },
          },
        },
        bar: {
          series: {
            strokeWidth: 0,
            highlightStyle: {
              item: {
                fill: undefined,
              },
              series: {
                dimOpacity: 0.2,
              },
            },
            label: {
              placement: "outside",
              enabled: true,
              fontWeight: "normal",
              fontSize: 10,
              formatter: function (params) {
                if (params.value) return params.value.toLocaleString();
                else return params.value;
              },
            },
          },
          navigator: {
            enabled: true,
            height: 50,
            minHandle: {
              width: 20,
              height: 30,
            },
            maxHandle: {
              width: 20,
              height: 30,
            },
          },
        },
        line: {
          series: {
            highlightStyle: {
              item: {
                fill: undefined,
              },
              series: {
                dimOpacity: 0.2,
              },
            },
            label: {
              enabled: true,
              fontWeight: "normal",
              fontSize: 10,
              formatter: function (params) {
                if (params.value) return params.value.toLocaleString();
                else return params.value;
              },
            },
          },
          navigator: {
            enabled: true,
            height: 50,
            minHandle: {
              width: 20,
              height: 30,
            },
            maxHandle: {
              width: 20,
              height: 30,
            },
          },
        },
        pie: {
          series: {
            strokeWidth: 0,
            highlightStyle: {
              item: {
                fill: undefined,
              },
              series: {
                dimOpacity: 0.2,
              },
            },
            label: {
              placement: "outside",
              enabled: true,
              fontWeight: "normal",
              fontSize: 10,
              formatter: function (params) {
                if (params.value) return params.value.toLocaleString();
                else return params.value;
              },
            },
          },
        },
      },
    },
  };
};
