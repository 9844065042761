import moment from "moment";
import { Box } from "@mui/system";
import React, { useCallback, useState } from "react";
import StyledSelect from "../Styled/StyledSelect";
import StyledTextField from "../../components/Styled/StyledTextField";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../utils/date-converter";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getAllSales } from "../../features/Navision/navision-actions";
import { navisionActions } from "../../features/Navision/navision-slice";

export default function SalesDateFilter({ isLandscape, isMobile, disabled }) {
  const dispatch = useDispatch();
  const { dateFilterType } = useSelector((state) => state.navision);
  const [inDate, setInDate] = useState("lastWeek");
  const [startDateTime, setStartDateTime] = useState(
    new Date(moment().startOf("day").subtract(1, "week"))
  );
  const [endDateTime, setEndDateTime] = useState(
    new Date(moment().endOf("day").subtract(1, "day"))
  );

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const externalFilterChangedStartDate = useCallback(
    (newValue) => {
      setStartDateTime(newValue);
      if (isValidDate(newValue)) {
        const newDate = formatDate(newValue);
        setTimeout(() => {
          dispatch(
            getAllSales(
              {
                startDate: new Date(
                  moment(newDate).startOf("day")
                ).toISOString(),
                endDate: new Date(
                  moment(endDateTime).endOf("day")
                ).toISOString(),
              },
              convertDateType(inDate)
            )
          );
        }, [500]);
      }
    },
    [dispatch, endDateTime, inDate]
  );

  const externalFilterChangedEndDate = useCallback(
    (newValue) => {
      setEndDateTime(newValue);
      if (isValidDate(newValue)) {
        const newDate = formatDate(newValue);
        setTimeout(() => {
          dispatch(
            getAllSales(
              {
                startDate: new Date(
                  moment(startDateTime).startOf("day")
                ).toISOString(),
                endDate: new Date(moment(newDate).endOf("day")).toISOString(),
              },
              convertDateType(inDate)
            )
          );
        }, [500]);
      }
    },
    [dispatch, startDateTime, inDate]
  );

  const filterChangedDate = useCallback(
    (startDate, endDate, dateType) => {
      const newStartDate = formatDate(startDate);
      const newEndDate = formatDate(endDate);
      setStartDateTime(startDate);
      setEndDateTime(endDate);
      dispatch(
        getAllSales(
          {
            startDate: new Date(
              moment(newStartDate).startOf("day")
            ).toISOString(),
            endDate: new Date(moment(newEndDate).endOf("day")).toISOString(),
          },
          dateType
        )
      );
    },
    [dispatch]
  );

  const getDateOption = (type) => {
    if (type === "day")
      return [
        { label: "เมื่อวาน", value: "yesterday" },
        { label: "7 วันที่แล้ว", value: "lastWeek" },
        { label: "14 วันที่แล้ว", value: "lastTwoWeek" },
        { label: "30 วันที่แล้ว", value: "last30Days" },
      ];
    else if (type === "month")
      return [
        { label: "เดือนนี้", value: "currentMonth" },
        { label: "เดือนที่แล้ว", value: "lastMonth" },
        { label: "3 เดือนที่แล้ว", value: "lastThreeMonth" },
      ];
    else if (type === "year")
      return [
        { label: "ปีนี้", value: "currentYear" },
        { label: "ปีที่แล้ว", value: "lastYear" },
      ];
  };

  const setInDateByDateType = (input) => {
    if (input === "day") return setInDate("lastWeek");
    if (input === "month") return setInDate("lastThreeMonth");
    if (input === "year") return setInDate("currentYear");
  };

  const convertDateType = (input) => {
    switch (input) {
      case "day":
      case "yesterday":
      case "lastWeek":
      case "lastTwoWeek":
      case "last30Days":
        return "day";
      case "month":
      case "currentMonth":
      case "lastMonth":
      case "lastThreeMonth":
        return "month";
      case "year":
      case "currentYear":
      case "lastYear":
        return "year";
      default:
        return "month";
    }
  };

  const onChangeDateHandle = ({ type, value }) => {
    let startDate = new Date(moment().startOf("day").subtract(1, "day"));
    let endDate = new Date(moment().endOf("day"));
    if (type === "type") {
      if (value === "day") {
        startDate = new Date(moment().startOf("day").subtract(1, "week"));
        endDate = new Date(moment().endOf("day").subtract(1, "day"));
      } else if (value === "month") {
        startDate = new Date(moment().startOf("month").subtract(3, "month"));
        endDate = new Date(moment().endOf("month").subtract(1, "month"));
      } else if (value === "year") {
        startDate = new Date(moment().startOf("year"));
        endDate = new Date(moment().endOf("year"));
      }
    } else if (type === "inDate") {
      if (value === "yesterday") {
        startDate = new Date(moment().startOf("day").subtract(1, "day"));
        endDate = new Date(moment().endOf("day").subtract(1, "day"));
      } else if (value === "lastWeek") {
        startDate = new Date(moment().startOf("day").subtract(1, "week"));
        endDate = new Date(moment().endOf("day").subtract(1, "day"));
      } else if (value === "lastTwoWeek") {
        startDate = new Date(moment().startOf("day").subtract(2, "week"));
        endDate = new Date(moment().endOf("day").subtract(1, "day"));
      } else if (value === "last30Days") {
        startDate = new Date(moment().startOf("day").subtract(30, "day"));
        endDate = new Date(moment().endOf("day").subtract(1, "day"));
      } else if (value === "currentMonth") {
        startDate = new Date(moment().startOf("month"));
        endDate = new Date(moment().endOf("day"));
      } else if (value === "lastMonth") {
        startDate = new Date(moment().startOf("month").subtract(1, "month"));
        endDate = new Date(moment().endOf("month").subtract(1, "month"));
      } else if (value === "lastThreeMonth") {
        startDate = new Date(moment().startOf("month").subtract(3, "month"));
        endDate = new Date(moment().endOf("month").subtract(1, "month"));
      } else if (value === "currentYear") {
        startDate = new Date(moment().startOf("year"));
        endDate = new Date(moment().endOf("day"));
      } else if (value === "lastYear") {
        startDate = new Date(moment().startOf("year").subtract(1, "year"));
        endDate = new Date(moment().endOf("year").subtract(1, "year"));
      }
    }
    filterChangedDate(startDate, endDate, convertDateType(value));
  };

  const dateOption = [
    { label: "วัน", value: "day" },
    { label: "เดือน", value: "month" },
    { label: "ปี", value: "year" },
  ];

  return (
    <Box>
      {!isMobile && !isLandscape && (
        <Box
          maxWidth={800}
          display={"flex"}
          alignItems={"center"}
          gap={2}
          mb={2}
        >
          <StyledSelect
            label="ประเภท"
            options={dateOption}
            value={dateFilterType}
            disabled={disabled}
            onChange={(e) => {
              dispatch(navisionActions.setDateFilterType(e.target.value));
              setInDateByDateType(e.target.value);
              onChangeDateHandle({ type: "type", value: e.target.value });
            }}
          />
          <StyledSelect
            label="ช่วงเวลา"
            options={getDateOption(dateFilterType)}
            value={inDate}
            disabled={disabled}
            onChange={(e) => {
              setInDate(e.target.value);
              onChangeDateHandle({ type: "inDate", value: e.target.value });
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="ตั้งแต่"
              value={startDateTime}
              disabled={disabled}
              inputFormat="yyyy-MM-dd"
              onChange={externalFilterChangedStartDate}
              renderInput={(params) => <StyledTextField {...params} />}
            />
            <DatePicker
              label="จนถึง"
              value={endDateTime}
              disabled={disabled}
              inputFormat="yyyy-MM-dd"
              onChange={externalFilterChangedEndDate}
              renderInput={(params) => <StyledTextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      )}
      {isMobile && (
        <Box display={"grid"} alignItems={"center"} gap={2} mt={2}>
          <StyledSelect
            label="ประเภท"
            options={dateOption}
            value={dateFilterType}
            disabled={disabled}
            onChange={(e) => {
              dispatch(navisionActions.setDateFilterType(e.target.value));
              setInDateByDateType(e.target.value);
              onChangeDateHandle({ type: "type", value: e.target.value });
            }}
          />
          <StyledSelect
            label="ช่วงเวลา"
            options={getDateOption(dateFilterType)}
            value={inDate}
            disabled={disabled}
            onChange={(e) => {
              setInDate(e.target.value);
              onChangeDateHandle({ type: "inDate", value: e.target.value });
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="ตั้งแต่"
              value={startDateTime}
              disabled={disabled}
              inputFormat="yyyy-MM-dd"
              onChange={externalFilterChangedStartDate}
              renderInput={(params) => <StyledTextField {...params} />}
            />
            <DatePicker
              label="จนถึง"
              value={endDateTime}
              disabled={disabled}
              inputFormat="yyyy-MM-dd"
              onChange={externalFilterChangedEndDate}
              renderInput={(params) => <StyledTextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      )}
      {isLandscape && (
        <Box display={"flex"} alignItems={"center"} gap={2} mt={2}>
          <StyledSelect
            label="ประเภท"
            options={dateOption}
            disabled={disabled}
            value={dateFilterType}
            onChange={(e) => {
              dispatch(navisionActions.setDateFilterType(e.target.value));
              setInDateByDateType(e.target.value);
              onChangeDateHandle({ type: "type", value: e.target.value });
            }}
          />
          <StyledSelect
            label="ช่วงเวลา"
            options={getDateOption(dateFilterType)}
            disabled={disabled}
            value={inDate}
            onChange={(e) => {
              setInDate(e.target.value);
              onChangeDateHandle({ type: "inDate", value: e.target.value });
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="ตั้งแต่"
              value={startDateTime}
              disabled={disabled}
              inputFormat="yyyy-MM-dd"
              onChange={externalFilterChangedStartDate}
              renderInput={(params) => <StyledTextField {...params} />}
            />
            <DatePicker
              label="จนถึง"
              value={endDateTime}
              disabled={disabled}
              inputFormat="yyyy-MM-dd"
              onChange={externalFilterChangedEndDate}
              renderInput={(params) => <StyledTextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      )}
    </Box>
  );
}
