import { Link as RouterLink } from "react-router-dom";
import { Box, LinearProgress, Link, Toolbar, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import StyledButton from "../Styled/StyledButton";
import StyledDashboardNavbar from "../Styled/StyledDashboardNavbar";
import StyledMenuButton from "../Styled/StyledMenuButton";
import BreadcrumbUI from "./BreadcrumbUI";
import StyledIconMenuButton from "../Styled/StyledIconMenuButton";
import { useNavbar } from "../../hooks/use-navbar";
import { HideOnScroll } from "./HideOnScroll";
import StyledMenuOption from "../Styled/StyledMenuOption";

const ActionBar = forwardRef(
  (
    {
      breakcrumbs,
      buttons,
      headers,
      setConfirmation,
      setOpenEdit,
      setOpenCopy,
      isLoading,
      isLandscape,
      updateTime,
    },
    ref
  ) => {
    const { sidebar } = useNavbar();

    const checkButtonType = (buttons) => {
      return buttons
        .filter((button) => button !== null && button !== undefined)
        .map((button, index) => {
          if (button.type === "button") {
            return (
              <StyledButton
                key={index}
                href={button?.href}
                target={button?.target}
                rel={button?.rel}
                sx={{
                  p: "5px 15px",
                  fontSize: 14,
                }}
                title={button.title}
                variant={button.variant}
                onClick={button.onClick}
              />
            );
          } else if (button.type === "iconMenuButton" && headers) {
            return (
              <StyledIconMenuButton
                key={index}
                filename={button.filename}
                headers={button.headers}
                disabled={button.disabled}
                setConfirmation={setConfirmation}
                setOpenEdit={setOpenEdit}
                setOpenCopy={setOpenCopy}
              />
            );
          } else if (button.type === "submit") {
            return (
              <StyledButton
                key={index}
                sx={{ p: "5px 15px", fontSize: 14 }}
                type="submit"
                title={button.title}
                variant={button.variant}
              />
            );
          } else if (button.type === "menuButton") {
            return (
              <StyledMenuButton
                ref={ref}
                key={index}
                title={button.title}
                csvFilename={button.csvFilename}
                excelFilename={button.excelFilename}
                variant={button.variant}
                headers={button.headers}
                data={button.data}
              />
            );
          } else if (button.type === "menuOption") {
            return (
              <StyledMenuOption
                label={"ตัวเลือก"}
                options={button.options}
                onSelect={button.onSelect}
              />
            );
          } else {
            return <></>;
          }
        });
    };

    const filterBreadcrumb = breakcrumbs.filter((element) => {
      return element !== null;
    });

    return (
      <HideOnScroll>
        <StyledDashboardNavbar
          sx={{
            pt: isLandscape ? 0 : "64px",
          }}
          open={sidebar.isSidebarOpen && !isLandscape}
        >
          <Toolbar
            disableGutters
            sx={{
              minHeight: 64,
              left: 0,
              px: 2,
            }}
          >
            <BreadcrumbUI>
              {filterBreadcrumb.map((breakcrumb, index, row) => {
                if (index + 1 === row.length) {
                  return (
                    <Typography key={index} fontWeight="bold">
                      {breakcrumb.name}
                    </Typography>
                  );
                } else {
                  if (breakcrumb.href) {
                    return (
                      <Link
                        component={RouterLink}
                        key={index}
                        color="inherit"
                        underline="none"
                        to={breakcrumb.href}
                      >
                        {breakcrumb.name}
                      </Link>
                    );
                  } else {
                    return (
                      <Typography key={index}>{breakcrumb.name}</Typography>
                    );
                  }
                }
              })}
            </BreadcrumbUI>
            {updateTime && (
              <Typography
                sx={{ color: "#52575C" }}
                variant={!isLandscape ? "subtitle2" : "caption"}
                px={!isLandscape ? 2 : 0}
                pt={0.2}
                mx={1}
              >
                {updateTime}
              </Typography>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {buttons && checkButtonType(buttons)}
            </Box>
          </Toolbar>
          {isLoading && <LinearProgress />}
        </StyledDashboardNavbar>
      </HideOnScroll>
    );
  }
);

export default ActionBar;
