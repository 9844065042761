import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { forwardRef } from "react";
const StyledSelect = forwardRef(
  (
    {
      id,
      name,
      label,
      value,
      onChange,
      options,
      disabled,
      error,
      helperText,
      required,
      sx,
    },
    ref
  ) => {
    return (
      <FormControl
        size="small"
        fullWidth
        disabled={disabled}
        error={error}
        ref={ref}
      >
        <InputLabel id="demo-simple-select-required-label" required={required}>
          {label}
        </InputLabel>
        <Select
          id={id}
          error={error}
          name={name}
          value={value}
          label={label}
          onChange={onChange}
          sx={{
            background: "#fff",
            ...sx,
          }}
        >
          {options &&
            options.map((option) => {
              return (
                <MenuItem key={option.id} value={option.value}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {option?.img?.props && (
                      <img
                        src={option?.img?.props.src}
                        alt={option?.img?.props.alt}
                      />
                    )}
                    <Typography>{option?.label}</Typography>
                  </Box>
                </MenuItem>
              );
            })}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

export default StyledSelect;
