import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="bottom" {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "none !important",
    color: "none !important",
    boxShadow: "none !important",
    fontSize: 11,
  },
}));

export default function ToolTip({ children }) {
  return (
    <LightTooltip title={<React.Fragment>{children}</React.Fragment>}>
      <InfoIcon />
    </LightTooltip>
  );
}
