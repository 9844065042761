import styled from "@emotion/styled";
import { AppBar } from "@mui/material";

const StyledMobileNavbar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderBottomColor: theme.palette.divider,
  borderBottomStyle: "solid",
  borderBottomWidth: 1,
  borderLeftColor: theme.palette.divider,
  borderLeftStyle: "solid",
  borderLeftWidth: 1,
  boxShadow: "none",
  width: "100%",
}));

export default StyledMobileNavbar;
