import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import StyledBox from "../../Styled/StyledBox";
import StyledTextField from "../../Styled/StyledTextField";
import {
  departmentType,
  positionType as allPosition,
} from "../../../utils/user-info";
import StyledComboBox from "../../Styled/StyledComboBox";
import { filterPositionByDepartment } from "../../../utils/data-transformer";
import StyledBadgeAvatar from "../../Styled/StyledBadgeAvatar";
import StyledRadioGroup from "../../Styled/StyledRadioGroup";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const radioList = [
  { value: true, label: "ใช้งาน" },
  { value: false, label: "หยุดใช้งาน" },
];

const UserAccountForm = ({
  control,
  errors,
  setValue,
  viewOnly,
  option,
  admin,
  setting,
  getValues,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [positionType, setPosition] = useState(allPosition);
  const { t } = useTranslation();
  const formDisableState = option === "แก้ไข" ? false : viewOnly;

  const onDepartmentChange = (value) => {
    filterPositionByDepartment(value, setPosition);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledBox sx={{ my: 2 }}>
      <StyledBadgeAvatar control={control} getValues={getValues} />
      <Grid container>
        <Typography fontWeight="bold" marginBottom={2}>
          ข้อมูลทั่วไป
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  fullWidth
                  error={Boolean(errors.first_name)}
                  helperText={errors.first_name?.message}
                  label={t("user.account.firstname")}
                  disabled={formDisableState}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  fullWidth
                  error={Boolean(errors.last_name)}
                  helperText={errors.last_name?.message}
                  label={t("user.account.lastname")}
                  disabled={formDisableState}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  fullWidth
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  label={t("user.account.email")}
                  disabled={formDisableState}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  fullWidth
                  error={Boolean(errors.phone)}
                  helperText={errors.phone?.message}
                  label={t("user.account.phone")}
                  disabled={formDisableState}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Typography fontWeight="bold" sx={{ my: 2 }}>
          จัดการหน้าที่
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="department"
              control={control}
              render={({ field }) => (
                <StyledComboBox
                  {...field}
                  error={Boolean(errors.department)}
                  helperText={errors.department?.message}
                  label={t("user.account.department")}
                  options={departmentType}
                  onChange={(_, newValue) => {
                    if (newValue !== null) {
                      field.onChange(newValue.value);
                      onDepartmentChange(newValue.value);
                    } else {
                      field.onChange(null);
                      onDepartmentChange(null);
                    }
                    setValue("position", null);
                  }}
                  disabled={formDisableState}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="position"
              control={control}
              render={({ field }) => (
                <StyledComboBox
                  {...field}
                  error={Boolean(errors.position)}
                  helperText={errors.position?.message}
                  label={t("user.account.position")}
                  options={positionType}
                  onChange={(_, newValue) => {
                    if (newValue !== null) {
                      field.onChange(newValue.value);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  disabled={formDisableState}
                />
              )}
            />
          </Grid>
        </Grid>
        {setting && (
          <>
            <Typography fontWeight="bold" sx={{ my: 2 }}>
              เปลี่ยนรหัสผ่าน
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Controller
                      name="old_password"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          fullWidth
                          size="small"
                          sx={{ mt: 1 }}
                        >
                          <InputLabel htmlFor="outlined-adornment-password3">
                            {t("user.account.old_password")}
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password3"
                            type={showOldPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowOldPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showOldPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label={t("user.account.old_password")}
                            {...field}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Controller
                      name="new_password"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          fullWidth
                          size="small"
                          sx={{ mt: 1 }}
                        >
                          <InputLabel htmlFor="outlined-adornment-password2">
                            {t("user.account.new_password")}
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password2"
                            type={showNewPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowNewPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label={t("user.account.new_password")}
                            {...field}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Controller
                      name="confirm_new_password"
                      control={control}
                      render={({ field }) => (
                        <StyledTextField
                          type="password"
                          fullWidth
                          error={Boolean(errors.confirm_new_password)}
                          helperText={errors.confirm_new_password?.message}
                          label={t("user.account.confirm_new_password")}
                          disabled={formDisableState}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {!setting && admin && (
          <>
            <Typography fontWeight="bold" sx={{ my: 2 }}>
              ตั้งรหัสผ่าน
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      disabled={formDisableState}
                      variant="outlined"
                      fullWidth
                      size="small"
                      sx={{ mt: 1 }}
                    >
                      <InputLabel htmlFor="outlined-adornment-password1">
                        {t("user.account.password")}
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password1"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              disabled={formDisableState}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label={t("user.account.password")}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2 }}>
              สถานะ
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="is_active"
                  control={control}
                  render={({ field }) => (
                    <StyledRadioGroup
                      {...field}
                      radioList={radioList}
                      row
                      disabled={formDisableState}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </StyledBox>
  );
};

export default UserAccountForm;
