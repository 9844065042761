import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import MobileLayout from "../../../components/Layout/MobileLayout";
import StyledBox from "../../../components/Styled/StyledBox";
import ActionBar from "../../../components/UI/ActionBar";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TemplateForm from "../../../components/Form/Template";
import StyledButton from "../../../components/Styled/StyledButton";
import { createTemplate } from "../../../features/Template/template-actions";
import { useNavigate } from "react-router-dom";
import { useNavbar } from "../../../hooks/use-navbar";
import { useAuth } from "../../../hooks/use-auth";
import { useSnackbar } from "notistack";

const schema = Yup.object().shape({
  name: Yup.string().required("กรุณากรอก"),
  x_axis: Yup.string()
    .required("กรุณาระบุแกน X")
    .typeError("กรุณาระบุเป็นวันที่ ex.20XX-XX-XX"),
  x_axis_type: Yup.string().required("กรุณาเลือกแกนข้อมูล"),
  y_axis: Yup.string().required("กรุณากรอกแกน Y"),
  y_axis_type: Yup.string().required("กรุณาเลือกค่าข้อมูล"),
  columns: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("กรุณาระบุชื่อคอลัมน์"),
    })
  ),
});

const TemplateCreate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { form } = useSelector((state) => state.template);
  const dispatch = useDispatch();
  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: form,
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation();
  const { mobile } = useNavbar();

  const isCreatable = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("TEMPLATE__GENERAL__CREATE") ||
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });

  const breadcrumbs = [
    {
      name: t("report.index"),
      href: "/report",
    },
    {
      name: t("report.template.index"),
      href: "/report/template",
    },
    {
      name: t("report.template.newTemplate"),
    },
  ];

  const addNewTemplateHandler = (data) => {
    const input = {
      ...data,
      columns:
        data.columns.length > 0
          ? [
              {
                name: data.x_axis,
                data_type: data.x_axis_type,
                can_override_value: true,
              },
              ...data.columns.map((column) => {
                return {
                  ...column,
                  name: column.name.trim(),
                  data_type: data.y_axis_type,
                };
              }),
            ]
          : [
              {
                name: data.x_axis,
                data_type: data.x_axis_type,
                can_override_value: true,
              },
              {
                name: data.y_axis.trim(),
                data_type: data.y_axis_type,
                can_override_value: false,
              },
            ],
    };
    dispatch(createTemplate({ input }, navigate, false, enqueueSnackbar));
  };

  const buttons = [
    isCreatable && {
      title: t("button.save"),
      type: "button",
      variant: "contained",
      onClick: handleSubmit(addNewTemplateHandler),
    },
  ];

  useEffect(() => {
    mobile.setMobileTitle(t("report.template.newTemplate"));
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t]);

  return (
    <>
      {!isMobile && (
        <DesktopLayout isSidebarOpen>
          <ActionBar
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isSidebarOpen
          />
          <StyledBox sx={{ mt: 5 }}>
            <TemplateForm
              handleSubmit={handleSubmit}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          </StyledBox>
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout>
          <StyledBox isMobile sx={{ mt: 2 }}>
            <TemplateForm
              handleSubmit={handleSubmit}
              control={control}
              setValue={setValue}
              errors={errors}
              isMobile
            />
          </StyledBox>
          <Box sx={{ mt: 2 }}>
            <StyledButton
              sx={{ mb: 1 }}
              title={t("button.export")}
              variant="outlined"
              onClick={handleSubmit(addNewTemplateHandler)}
              fullWidth
            />
            <StyledButton
              sx={{ mb: 2 }}
              title={t("button.save")}
              variant="outlined"
              onClick={handleSubmit(addNewTemplateHandler)}
              fullWidth
            />
          </Box>
        </MobileLayout>
      )}
    </>
  );
};

export default TemplateCreate;
