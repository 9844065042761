import { Box, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import MobileLayout from "../../../components/Layout/MobileLayout";
import StyledButton from "../../../components/Styled/StyledButton";
import ActionBar from "../../../components/UI/ActionBar";
import AgGrid from "../../../components/UI/AgGrid";
import { getAllRoles } from "../../../features/User/Rbac/rbac-action";
import { rbacActions } from "../../../features/User/Rbac/rbac-slice";
import { useAuth } from "../../../hooks/use-auth";
import { useNavbar } from "../../../hooks/use-navbar";

const Rbac = () => {
  const { user } = useAuth();
  const isCreatable = user?.role_list?.some(
    (role) =>
      role?.permission_list?.includes("CONFIG__RBAC__CREATE") ||
      role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );

  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { allRoles, isLoading } = useSelector((state) => state.rbac);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridRef = useRef();
  const { mobile } = useNavbar();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });

  const breadcrumbs = [
    {
      name: t("user.account.index"),
      href: "/user",
    },
    {
      name: t("user.rbac.index"),
    },
  ];

  const buttons = [
    isCreatable && {
      type: "button",
      title: t("button.add"),
      variant: "contained",
      onClick: () => navigate("/user/rbac/create"),
    },
  ];

  useEffect(() => {
    mobile.setMobileTitle(t("user.rbac.index"));
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t]);

  const columnDefs = [
    {
      field: "id",
      headerName: t("user.rbac.roleId"),
      filter: "agTextColumnFilter",
      sort: "asc",
    },
    {
      field: "name",
      headerName: t("user.rbac.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "description",
      headerName: t("user.rbac.description"),
      filter: "agTextColumnFilter",
    },
    {
      headerName: t("user.rbac.totalUser"),
      filter: "agNumberColumnFilter",
      valueGetter: (params) => params.data.employee_list.length,
    },
  ];

  useEffect(() => {
    dispatch(getAllRoles(enqueueSnackbar));
    return () => dispatch(rbacActions.resetAllRole());
  }, [dispatch, enqueueSnackbar]);

  const onRowClicked = (params) => {
    navigate(`${pathname}/${params.data.id}?step=permission&subtab=template`);
  };

  return (
    <>
      {!isMobile && (
        <DesktopLayout isSidebarOpen pathname="/user">
          <ActionBar
            ref={gridRef}
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isSidebarOpen
            isLoading={isLoading.allRoles}
          />
          <AgGrid
            columnDefs={columnDefs}
            gridRef={gridRef}
            searchBox
            rowData={allRoles}
            height={730}
            enableCheckbox
            rowSelection="multiple"
            onRowClicked={onRowClicked}
          />
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout isLoading={isLoading.allRoles} pathname="/user">
          <Box>
            <AgGrid
              columnDefs={columnDefs}
              gridRef={gridRef}
              searchBox
              rowData={allRoles}
              height={600}
              enableCheckbox
              rowSelection="multiple"
              onRowClicked={onRowClicked}
              isMobile
            />
          </Box>
          <StyledButton
            fullWidth
            title={t("button.add")}
            variant="contained"
            onClick={() => navigate("/user/rbac/create")}
          />
        </MobileLayout>
      )}
    </>
  );
};

export default Rbac;
