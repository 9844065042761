import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { filteredRow } from "../../../utils/data-transformer";
import AgGrid from "../../UI/AgGrid";

const TemplatePermission = ({ columnDefs, priviledgeList, resource }) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "GENERAL",
        name: t("report.template.index"),
        create: "create",
      },
    ];
    return filteredRow(rows, priviledgeList, resource);
  }, [priviledgeList, resource, t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      isPermission
    />
  );
};

export default TemplatePermission;
