import { Box, IconButton, Toolbar } from "@mui/material";
// import { alpha } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import StyledButton from "../Styled/StyledButton";
import StyledDashboardNavbar from "../Styled/StyledDashboardNavbar";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { useNavbar } from "../../hooks/use-navbar";
import StyledAvatarMenu from "../Styled/StyledAvatarMenu";

const TopNavbar = ({ pathname }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sidebar } = useNavbar();

  return (
    <>
      <StyledDashboardNavbar open={sidebar.isSidebarOpen}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          {!sidebar.isSidebarOpen && (
            <IconButton onClick={() => sidebar.setIsSidebarOpen(true)}>
              <MenuIcon fontSize="small" />
            </IconButton>
          )}
          <Box sx={{ ml: sidebar.isSidebarOpen ? 0 : 4 }}>
            <StyledButton
              variant="outlined"
              title={t("button.back")}
              onClick={
                pathname
                  ? () => navigate({ pathname: pathname })
                  : () => navigate(-1)
              }
              startIcon={<ArrowBackIcon fontSize="small" />}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <StyledAvatarMenu />
        </Toolbar>
      </StyledDashboardNavbar>
    </>
  );
};

export default TopNavbar;
