import styled from "@emotion/styled";
import { alpha, Box, Typography } from "@mui/material";
import React from "react";

const ColorIconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: `1px solid #d6cfcf`,
  borderRadius: "15px",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  cursor: "pointer",
  padding: "1.5rem",
  width: "100%",
  gap: "1rem",
  height: "100%",
}));

const StyledIconBox = ({ icon, title, onClick }) => {
  return (
    <ColorIconBox onClick={onClick}>
      {icon}
      <Typography>{title}</Typography>
    </ColorIconBox>
  );
};

export default StyledIconBox;
