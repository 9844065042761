import React, { forwardRef, useCallback } from "react";
import StyledButton from "../Styled/StyledButton";

const MobileExport = forwardRef(({ csvFilename, excelFilename }, ref) => {
  const onBtnExportCSV = useCallback(() => {
    const config = {
      fileName: csvFilename,
    };
    ref.current.api.exportDataAsCsv(config);
  }, [csvFilename, ref]);

  const onBtnExportExcel = useCallback(() => {
    const config = {
      fileName: excelFilename,
    };
    ref.current.api.exportDataAsExcel(config);
  }, [excelFilename, ref]);

  return (
    <>
      <StyledButton
        sx={{ mb: 1 }}
        title="CSV Export"
        variant="outlined"
        onClick={onBtnExportCSV}
        fullWidth
      />
      <StyledButton
        sx={{ mb: 2 }}
        title="Excel Export"
        variant="outlined"
        onClick={onBtnExportExcel}
        fullWidth
      />
    </>
  );
});

export default MobileExport;
