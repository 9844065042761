import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AgGrid from "../../../components/UI/AgGrid";
import ActionBar from "../../../components/UI/ActionBar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomDate from "../../../components/UI/CustomDate";
import MobileExport from "../../../components/UI/MobileExport";
import MobileLayout from "../../../components/Layout/MobileLayout";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import { getAllAP } from "../../../features/Navision/navision-actions";
import { filterParams, toLocale } from "../../../utils/data-transformer";
import { useNavbar } from "../../../hooks/use-navbar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCallback } from "react";
import { formatDate } from "../../../utils/date-converter";
import LandscapeLayout from "../../../components/Layout/LandscapeLayout";
import { navisionActions } from "../../../features/Navision/navision-slice";
import { useAuth } from "../../../hooks/use-auth";
import ShareModal from "../../../components/UI/ShareModal";
import StyledTextField from "../../../components/Styled/StyledTextField";
import StyledButton from "../../../components/Styled/StyledButton";

const APReport = () => {
  const { user } = useAuth();
  const admin = user?.role_list?.some((role) =>
    role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const [share, setShare] = useState(false);
  const gridRef = useRef();
  const dispatch = useDispatch();
  const [dateTime, setDateTime] = useState(
    new Date(moment().subtract(1, "day"))
  );
  const { mobile } = useNavbar();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  const { ap, isLoading } = useSelector((state) => state.navision);

  const createData = useCallback((count, prefix, ap) => {
    var result = [];
    for (var i = 0; i < count; i++) {
      const Balance = ap && ap.reduce((prev, curr) => prev + curr.balance, 0);
      const NotDue = ap && ap.reduce((prev, curr) => prev + curr.not_due, 0);
      const OneDays =
        ap && ap.reduce((prev, curr) => prev + curr.overdue_1_30_days, 0);
      const ThirtyDays =
        ap && ap.reduce((prev, curr) => prev + curr.overdue_31_60_days, 0);
      const SixtyDays =
        ap && ap.reduce((prev, curr) => prev + curr.overdue_61_90_days, 0);
      const NinetyDays =
        ap && ap.reduce((prev, curr) => prev + curr.overdue_91_180_days, 0);
      const HalfYear =
        ap && ap.reduce((prev, curr) => prev + curr.overdue_181_365_days, 0);
      const Year =
        ap && ap.reduce((prev, curr) => prev + curr.overdue_365_days, 0);
      result.push({
        vendor_no: "Total",
        balance: Balance,
        not_due: NotDue,
        overdue_1_30_days: OneDays,
        overdue_31_60_days: ThirtyDays,
        overdue_61_90_days: SixtyDays,
        overdue_91_180_days: NinetyDays,
        overdue_181_365_days: HalfYear,
        overdue_365_days: Year,
      });
    }
    return result;
  }, []);

  const columnDefs = [
    {
      field: "vendor_no",
      headerName: t("report.navision.vendorNo"),
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      field: "vendor_name",
      headerName: t("report.navision.vendorName"),
      enableRowGroup: true,
      enablePivot: true,
      width: 350,
      pinned: !isMobile && "left",
    },
    {
      field: "balance",
      headerName: "Balance",
      cellRenderer: toLocale,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      aggFunc: "sum",
      sort: "asc",
    },
    {
      field: "data_date",
      headerName: t("report.navision.DataDate"),
      filter: "agMultiColumnFilter",
      filterParams: filterParams("Datetime"),
      suppressToolPanel: true,
      enableRowGroup: true,
      enablePivot: true,
      hide: true,
    },
    {
      field: "load_timestamp",
      headerName: t("report.navision.loadTimestamp"),
      enableRowGroup: true,
      enablePivot: true,
      suppressToolPanel: true,
      hide: true,
    },
    {
      field: "not_due",
      headerName: "Not due",
      cellRenderer: toLocale,
      enableRowGroup: true,
      enablePivot: true,
      aggFunc: "sum",
    },
    {
      field: "overdue_1_30_days",
      headerName: "1-30 days",
      cellRenderer: toLocale,
      enableRowGroup: true,
      enablePivot: true,
      aggFunc: "sum",
    },
    {
      field: "overdue_31_60_days",
      headerName: "31-60 days",
      cellRenderer: toLocale,
      enableRowGroup: true,
      enablePivot: true,
      aggFunc: "sum",
    },
    {
      field: "overdue_61_90_days",
      headerName: "61-90 days",
      cellRenderer: toLocale,
      enableRowGroup: true,
      enablePivot: true,
      aggFunc: "sum",
    },
    {
      field: "overdue_91_180_days",
      headerName: "91-180 days",
      cellRenderer: toLocale,
      enableRowGroup: true,
      enablePivot: true,
      aggFunc: "sum",
    },
    {
      field: "overdue_181_365_days",
      headerName: "181-365 days",
      cellRenderer: toLocale,
      enableRowGroup: true,
      enablePivot: true,
      aggFunc: "sum",
    },
    {
      field: "overdue_365_days",
      headerName: "> 365 days",
      cellRenderer: toLocale,
      enableRowGroup: true,
      enablePivot: true,
      aggFunc: "sum",
    },
  ];

  const breadcrumbs = [
    {
      name: t("report.index"),
      href: "/report",
    },
    {
      name: t("report.navision.index"),
      href: "/report/navision",
    },
    {
      name: t("report.navision.ap"),
    },
  ];

  const buttons = [
    admin && {
      title: "แชร์",
      type: "button",
      variant: "contained",
      onClick: () => setShare(true),
    },
    {
      type: "menuButton",
      title: t("button.export"),
      variant: "outlined",
      csvFilename: "รายงาน AP.csv",
      excelFilename: "รายงาน AP.xlsx",
    },
  ];

  useEffect(() => {
    const date = new Date(moment().subtract(1, "day"));
    date.setUTCHours(0, 0, 0, 0);
    let formatDate = date.toISOString();
    dispatch(getAllAP({ dataDate: formatDate }));
    return () => {
      dispatch(navisionActions.resetAP());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mobile.setMobileTitle(t("report.navision.ap"));
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile, t]);

  const components = useMemo(() => {
    return {
      agDateInput: CustomDate,
    };
  }, []);

  const pinnedBottomRowData = useMemo(() => {
    return createData(1, "Bottom", ap);
  }, [ap, createData]);

  const sideBar = {
    toolPanels: [
      {
        id: "id",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressPivotMode: true,
        },
      },
      "filters",
    ],
  };

  const onFilterChanged = useCallback(
    (params) => {
      let allData = [];
      params.api.forEachNodeAfterFilter((rowNode) => {
        allData.push(rowNode.data);
      });
      if (allData.length !== 0) {
        return params.api.setPinnedBottomRowData(
          createData(1, "Bottom", allData)
        );
      }
      return params.api.setPinnedBottomRowData(createData(1, "Bottom", []));
    },
    [createData]
  );

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const externalFilterChanged = useCallback(
    (newValue) => {
      setDateTime(newValue);
      if (isValidDate(newValue)) {
        const newDate = formatDate(newValue);
        dispatch(
          getAllAP({
            dataDate: new Date(newDate).toISOString(),
          })
        );
      }
    },
    [dispatch]
  );

  const isLandscape = useMediaQuery(
    "(max-device-width: 920px) and (orientation: landscape)",
    {
      noSsr: true,
    }
  );

  const closeShare = () => {
    setShare(false);
  };

  return (
    <>
      {!isMobile && !isLandscape && (
        <DesktopLayout isSidebarOpen>
          <ActionBar
            ref={gridRef}
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isLoading={isLoading.ap}
            isSidebarOpen
          />
          <Box>
            <Box maxWidth={165} display={"flex"} gap={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="ข้อมูลของวันที่"
                  value={dateTime}
                  inputFormat="yyyy-MM-dd"
                  onChange={externalFilterChanged}
                  renderInput={(params) => <StyledTextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            {!isLoading.ap && (
              <AgGrid
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={ap}
                suppressAggFuncInHeader={true}
                onFilterChanged={onFilterChanged}
                pinnedBottomRowData={pinnedBottomRowData}
                components={components}
                sideBar={sideBar}
                searchBox
                height={620}
              />
            )}
          </Box>
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout isLoading={isLoading.ap}>
          <Box my={2}>
            <Box display={"flex"} gap={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="ข้อมูลของวันที่"
                  value={dateTime}
                  inputFormat="yyyy-MM-dd"
                  onChange={externalFilterChanged}
                  renderInput={(params) => <StyledTextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            {!isLoading.ap && (
              <Box>
                <AgGrid
                  ref={gridRef}
                  columnDefs={columnDefs}
                  rowData={ap}
                  suppressAggFuncInHeader={true}
                  onFilterChanged={onFilterChanged}
                  pinnedBottomRowData={pinnedBottomRowData}
                  components={components}
                  sideBar={sideBar}
                  height={450}
                  isMobile
                />
                <StyledButton
                  sx={{ mb: 1 }}
                  title={t("button.share")}
                  fullWidth
                  variant="contained"
                  onClick={() => setShare(true)}
                />
                <MobileExport
                  ref={gridRef}
                  csvFilename={"รายงาน AP"}
                  excelFilename={"รายงาน AP"}
                />
              </Box>
            )}
          </Box>
        </MobileLayout>
      )}
      {!isMobile && isLandscape && (
        <LandscapeLayout>
          <ActionBar
            ref={gridRef}
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isLoading={isLoading.ap}
            isLandscape
          />
          <Box maxWidth={150} display={"flex"} gap={2} my={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="ข้อมูลของวันที่"
                value={dateTime}
                inputFormat="yyyy-MM-dd"
                onChange={externalFilterChanged}
                renderInput={(params) => <StyledTextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
          {!isLoading.ap && (
            <Box>
              <AgGrid
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={ap}
                suppressAggFuncInHeader={true}
                onFilterChanged={onFilterChanged}
                pinnedBottomRowData={pinnedBottomRowData}
                components={components}
                sideBar={sideBar}
                height={450}
                isLandscape
              />
            </Box>
          )}
        </LandscapeLayout>
      )}
      <ShareModal
        name={t("report.navision.ap")}
        share={share}
        closeShare={closeShare}
        navision
      />
    </>
  );
};

export default APReport;
