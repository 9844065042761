import { Box } from "@mui/material";
import React from "react";
import FooterNavbar from "../UI/FooterNavbar";
import TopMobileNavbar from "../UI/TopMobileNavbar";

const MobileLayout = ({
  children,
  isLoading,
  template,
  headers,
  setConfirmation,
  setOpenEdit,
  setOpenCopy,
  disabled,
  isCustomer,
  updateTime,
  rbac,
  options,
  onSelect,
  pathname,
}) => {
  return (
    <>
      <Box
        sx={{
          paddingTop: "64px",
          paddingBottom: "80px",
          px: isCustomer ? 0 : "20px",
          height: "100%",
        }}
      >
        {children}
      </Box>
      <TopMobileNavbar
        updateTime={updateTime}
        isLoading={isLoading}
        template={template}
        headers={headers}
        setConfirmation={setConfirmation}
        setOpenEdit={setOpenEdit}
        setOpenCopy={setOpenCopy}
        disabled={disabled}
        rbac={rbac}
        options={options}
        onSelect={onSelect}
        pathname={pathname}
      />
      <FooterNavbar />
    </>
  );
};

export default MobileLayout;
