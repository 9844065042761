import { Box, useMediaQuery } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DesktopLayout from "../../../components/Layout/DesktopLayout";
import LandscapeLayout from "../../../components/Layout/LandscapeLayout";
import MobileLayout from "../../../components/Layout/MobileLayout";
import StyledButton from "../../../components/Styled/StyledButton";
import ActionBar from "../../../components/UI/ActionBar";
import AgGrid from "../../../components/UI/AgGrid";
import ShareModal from "../../../components/UI/ShareModal";
import { getAllCustomer } from "../../../features/Navision/navision-actions";
import { useAuth } from "../../../hooks/use-auth";
import { useNavbar } from "../../../hooks/use-navbar";
// import { toLocale } from "../../../utils/data-transformer";

const CustomerReport = () => {
  const { user } = useAuth();
  const admin = user?.role_list?.some((role) =>
    role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  );
  const [share, setShare] = useState(false);
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { mobile } = useNavbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"), {
    noSsr: true,
  });
  const { customer, isLoading } = useSelector((state) => state.navision);
  const { allCustomer } = customer;

  const breadcrumbs = [
    {
      name: t("report.index"),
      href: "/report",
    },
    {
      name: t("report.navision.index"),
      href: "/report/navision",
    },
    {
      name: t("report.navision.customer"),
    },
  ];

  const buttons = [
    admin && {
      title: "แชร์",
      type: "button",
      variant: "contained",
      onClick: () => setShare(true),
    },
    {
      title: t("button.export"),
      variant: "outlined",
    },
  ];

  useEffect(() => {
    dispatch(getAllCustomer());
  }, [dispatch]);

  useEffect(() => {
    mobile.setMobileTitle("รายงานลูกค้า");
    return () => {
      mobile.setMobileTitle("");
    };
  }, [mobile]);

  const getRowStyle = () => {
    return { cursor: "pointer" };
  };

  const onRowClicked = (event) => {
    return navigate(`${event.data.customer_no}`);
  };

  const columnDefs = [
    {
      field: "customer_no",
      headerName: "No",
      filter: "agTextColumnFilter",
    },
    {
      field: "customer_name",
      headerName: "รายชื่อลูกค้า",
      filter: "agTextColumnFilter",
      width: 300,
    },
  ];

  const isLandscape = useMediaQuery(
    "(max-device-width: 920px) and (orientation: landscape)",
    {
      noSsr: true,
    }
  );

  const closeShare = () => {
    setShare(false);
  };

  return (
    <>
      {!isMobile && !isLandscape && (
        <DesktopLayout isSidebarOpen>
          <ActionBar
            ref={gridRef}
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isLoading={isLoading.customer.allCustomer}
            isSidebarOpen
          />
          {!isLoading.customer.allCustomer && (
            <AgGrid
              ref={gridRef}
              searchBox
              rowData={allCustomer}
              getRowStyle={getRowStyle}
              columnDefs={columnDefs}
              height={700}
              onRowClicked={onRowClicked}
            />
          )}
        </DesktopLayout>
      )}
      {isMobile && (
        <MobileLayout isLoading={isLoading.customer.allCustomer}>
          <Box>
            {!isLoading.customer.allCustomer && (
              <>
                <AgGrid
                  ref={gridRef}
                  rowData={allCustomer}
                  columnDefs={columnDefs}
                  onRowClicked={onRowClicked}
                  height={640}
                  searchBox
                  isMobile
                />
              </>
            )}
            <StyledButton
              sx={{ mb: 1 }}
              title={t("button.share")}
              fullWidth
              variant="contained"
              onClick={() => setShare(true)}
            />
          </Box>
        </MobileLayout>
      )}
      {!isMobile && isLandscape && (
        <LandscapeLayout>
          <ActionBar
            ref={gridRef}
            breakcrumbs={breadcrumbs}
            buttons={buttons}
            isLoading={isLoading.customer.allCustomer}
            isLandscape
          />
          {!isLoading.customer.allCustomer && (
            <AgGrid
              ref={gridRef}
              rowData={allCustomer}
              columnDefs={columnDefs}
              onRowClicked={onRowClicked}
              height={375}
              searchBox
              isLandscape
            />
          )}
        </LandscapeLayout>
      )}
      <ShareModal
        name={t("report.navision.customer")}
        share={share}
        closeShare={closeShare}
        navision
      />
    </>
  );
};

export default CustomerReport;
