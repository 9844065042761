import { gql } from "graphql-request";

export const CREATE_NEW_USER = gql`
  mutation CreateEmployee($createEmployeeInput: CreateEmployeeInput!) {
    createEmployee(createEmployeeInput: $createEmployeeInput) {
      id
      first_name
      last_name
      email
      phone
      department
      position
      is_active
      line_uid
      img_url
      last_login_date
      role_list {
        id
        name
        permission_list
        description
      }
    }
  }
`;

export const CREATE_NEW_ROLE = gql`
  mutation CreateRole($createRoleInput: CreateRoleInput) {
    createRole(createRoleInput: $createRoleInput) {
      id
      name
      description
      permission_list
      employee_list {
        id
        first_name
        last_name
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateEmployee($updateEmployeeInput: UpdateEmployeeInput!) {
    updateEmployee(updateEmployeeInput: $updateEmployeeInput) {
      id
      first_name
      last_name
      email
      phone
      department
      position
      is_active
      line_uid
      img_url
      last_login_date
      role_list {
        id
        name
        description
        permission_list
      }
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation UpdateEmployeeRoles(
    $updateEmployeeRolesInput: UpdateEmployeeRolesInput!
  ) {
    updateEmployeeRoles(updateEmployeeRolesInput: $updateEmployeeRolesInput) {
      id
      first_name
      last_name
      email
      phone
      department
      position
      img_url
      last_login_date
      role_list {
        id
        name
        description
        permission_list
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($passwordInput: PasswordInput!) {
    updatePassword(passwordInput: $passwordInput)
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole($updateRoleInput: UpdateRoleInput) {
    updateRole(updateRoleInput: $updateRoleInput) {
      id
      description
      permission_list
      name
      employee_list {
        id
        first_name
        last_name
        email
        department
        position
      }
    }
  }
`;

export const UPDATE_USER_SETTING = gql`
  mutation UpdateEmployeeSelf(
    $updateEmployeeSelfInput: UpdateEmployeeSelfInput!
  ) {
    updateEmployeeSelf(updateEmployeeSelfInput: $updateEmployeeSelfInput) {
      id
      first_name
      last_name
      email
      phone
      department
      position
      role_list {
        id
        name
        description
        permission_list
      }
      last_login_date
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($deleteRoleId: Int!) {
    deleteRole(id: $deleteRoleId) {
      id
      name
      permission_list
      description
    }
  }
`;
